import Mutation from './mutationTypes';
import { apiGetSecitonDiffSummary, apiLoadPromptOptions, apiUpdateSummaryRating, apiCheckDiffSummaryStatus, apiSaveDiffSummaryByJobId  } from "@/components/AICompare/api"

export default {
    setItem: ({ commit }, {field, value}) => {
        commit(Mutation.SET_ITEM, {field, value});
    },
    initComparePDF: async ({ commit, dispatch  }, compareDetails) => {
        try {
            commit(Mutation.SET_ITEM, {field: 'showCompareWindow', value: true});
            commit(Mutation.SET_ITEM, {field: 'toc1Selection', value: {}});
            commit(Mutation.SET_ITEM, {field: 'toc2Selection', value: {}});
            commit(Mutation.SET_ITEM, {field: 'compareVersionsError', value: ''});
            commit(Mutation.SET_ITEM, {field: 'itemId1', value: compareDetails.itemId1});
            commit(Mutation.SET_ITEM, {field: 'itemId2', value: compareDetails.itemId2});
            commit(Mutation.SET_ITEM, {field: 'itemName1', value: compareDetails.itemName1});
            commit(Mutation.SET_ITEM, {field: 'itemName2', value: compareDetails.itemName2});
            commit(Mutation.SET_ITEM, {field: 'language', value: compareDetails.language});
            commit(Mutation.SET_ITEM, {field: 'docName', value: compareDetails.docName});
            commit(Mutation.SET_ITEM, {field: 'docId', value: compareDetails.docId});
            commit(Mutation.SET_ITEM, {field: 'diffSummaryId', value: 0});
            commit(Mutation.SET_ITEM, {field: 'selectedRating', value: -1});
            commit(Mutation.SET_ITEM, {field: 'loading', value: false});
            commit(Mutation.SET_ITEM, {field: 'initLoading', value: false});
            commit(Mutation.SET_ITEM, {field: 'prevActiveTabs', value: []});
            dispatch('setActivePage', {activeTab: 'compare-versions', tabPage: 'compare-toc'});
            commit(Mutation.SET_ITEM, {field: 'promptOptionsLoading', value: false});
        } catch(error) {
            console.log('api call failed for getting previous chat list');
            console.log(error);
        }
    },
    loadComparePromptOptions: async ({ commit, state }) => {
        try {
            let promptOptions = state.promptOptions;
            if(promptOptions.length == 0) {
                commit(Mutation.SET_ITEM, {field: 'promptOptionsLoading', value: true});
                const promptOptions =  await apiLoadPromptOptions();
                if(promptOptions.prompts) {
                    promptOptions.prompts.unshift({id: 0, title: 'Select response format...'});
                    commit(Mutation.SET_ITEM, {field: 'promptOptions', value: promptOptions.prompts});
                }
                commit(Mutation.SET_ITEM, {field: 'promptOptionsLoading', value: false});
            }
        } catch(error) {
            commit(Mutation.SET_ITEM, {field: 'promptOptionsLoading', value: false});
            console.log('failed to load prompt options');
            console.log(error);
        }
    },
    getSecitonDiffSummary: async ({ commit, getters, dispatch }, compareParams) => {
        try {
            commit(Mutation.SET_ITEM, {field: 'loading', value: true});
            commit(Mutation.SET_ITEM, {field: 'compareVersionsError', value: ''});
            let compareApiAbortController =  new AbortController();
            commit(Mutation.SET_ITEM, {field: 'abortController.compareApi', value: compareApiAbortController});
            const { jobId, limitError} =  await apiGetSecitonDiffSummary(compareParams, compareApiAbortController);
            const updateStateForError = () => {
                commit(Mutation.SET_ITEM, {field: 'loading', value: false});
                commit(Mutation.SET_ITEM, {field: 'compareVersionsError', value: 'failed_to_compare'});
            }
            if(jobId) {
                //check if diff available every 5 sec
                let count = 0;
                let checkDiffSummaryStatusCallback = async function() {
                    if(getters.getItem('processAborted')) { //if the compare has been canceled by click back or close button do nothing.
                        return;
                    }
                    count++;
                    if(count > 15) {
                        updateStateForError();
                        return ;
                    }
                    let checkDiffStatusApiAbortController =  new AbortController();
                    commit(Mutation.SET_ITEM, {field: 'abortController.checkDiffStatusApi', value: checkDiffStatusApiAbortController});
                    const response = await apiCheckDiffSummaryStatus(jobId, checkDiffStatusApiAbortController);
                    if(response.status) {
                        switch(response.status) {
                            case 'PENDING':
                            case 'PROCESSING':
                                setTimeout(checkDiffSummaryStatusCallback, 5000);
                                break;
                            case 'FINISHED': {
                                try {
                                    const response = await apiSaveDiffSummaryByJobId(jobId);
                                    if(response && response.aiMessage && response.id) {
                                        //when finished, call the api to save the response
                                        commit(Mutation.SET_ITEM, {field: 'diffSummary', value: response.aiMessage});
                                        commit(Mutation.SET_ITEM, {field: 'diffSummaryId', value: response.id});
                                        dispatch('setActivePage', {activeTab: 'compare-versions', tabPage: 'compare-summary'});
                                        commit(Mutation.SET_ITEM, {field: 'loading', value: false});
                                    } else {
                                        updateStateForError();
                                    }
                                } catch(error) {
                                    console.log(error);
                                }
                                break;
                            }
                            case 'ERROR':
                            default:
                                updateStateForError();
                            break;
                        }
                    }
                }
                setTimeout(checkDiffSummaryStatusCallback, 10000);
            } else if(limitError === 'subscription_compare_limit_exceeded')  {
                commit(Mutation.SET_ITEM, {field: 'loading', value: false});
                commit(Mutation.SET_ITEM, {field: 'compareVersionsError', value: 'query_limit_exceeded'});
            } else {
                commit(Mutation.SET_ITEM, {field: 'loading', value: false});
                commit(Mutation.SET_ITEM, {field: 'compareVersionsError', value: 'failed_to_compare'});
            }
        } catch(error) {
            commit(Mutation.SET_ITEM, {field: 'loading', value: false});
            commit(Mutation.SET_ITEM, {field: 'compareVersionsError', value: 'failed_to_compare'});
            console.log('api call failed for getting reply');
            console.log(error);
        }
    },
    updateSummaryRating: async ({ commit },{ summaryId, rating }) => {
        try {
            commit(Mutation.SET_ITEM, {field: 'compareVersionsError', value: ''});
            commit(Mutation.SET_ITEM, {field: 'updateRatingInProgress', value: true});
            await apiUpdateSummaryRating({ summaryId, rating });
            commit(Mutation.SET_ITEM, {field: 'updateRatingInProgress', value: false});
        } catch(error) {
            commit(Mutation.SET_ITEM, {field: 'updateRatingInProgress', value: false});
            commit(Mutation.SET_ITEM, {field: 'compareVersionsError', value: 'failed_to_rate'});
            console.log('api call failed for getting reply');
            console.log(error);
        }
    },
    closeCompare:  ({ commit }) => {
        commit(Mutation.SET_ITEM, {field: 'showCompareWindow', value: false});
    },
    abortApiCall: ({ getters }) => {
        let checkDiffStatusApi = getters.getItem('abortController.checkDiffStatusApi');
        if(checkDiffStatusApi) {
            checkDiffStatusApi.abort();
        }
        let compareApi = getters.getItem('abortController.compareApi');
        if(compareApi) {
            compareApi.abort();
        }
        let loadTOCApi = getters.getItem('abortController.loadTOCApi');
        if(loadTOCApi) {
            loadTOCApi.abort();
        }
    },
    setActivePage({commit, getters},{activeTab, tabPage}) {
        if( getters.getItem('activeTab') !='' && (activeTab != getters.getItem('activeTab') || tabPage != getters.getItem('tabPage'))) {
            let prevActiveTabs = getters.getItem('prevActiveTabs');
            prevActiveTabs.unshift({activeTab: getters.getItem('activeTab'), tabPage: getters.getItem('tabPage')});
            commit(Mutation.SET_ITEM, {field: 'prevActiveTabs', value: prevActiveTabs});
        }
        commit(Mutation.SET_ITEM, {field: 'activeTab', value: activeTab});
        commit(Mutation.SET_ITEM, {field: 'tabPage', value: tabPage});
        
    }
}
import axios from "@/plugins/axios";

export const apiLoadTOC = async ({itemId1, itemId2}, loadTOCApiAbortController) => {
    let options = {};
    let params = {};
    options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}, signal: loadTOCApiAbortController.signal};
    params = new URLSearchParams();
    params.append('itemIds[]', itemId1);
    params.append('itemIds[]', itemId2);
    const apiEndPoint = 'getPdfTOC';
    const response = await axios.post({
        endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
        params: params,
        options: options

    });
    if (response.data) {
        if (response.data.status === 'ok') {
            return response.data.data;
        } else {
            throw new Error('There has been a problem loading TOC for sections selection.');
        }
    }
    return [];
}

export const apiGetSecitonDiffSummary = async (compareParams, compareApiAbortController) => {    let options = {};
    let params = {};
    options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}, signal: compareApiAbortController.signal};
    params = new URLSearchParams();
    params.append('compareParams', JSON.stringify(compareParams));
    const apiEndPoint = 'getSectionDiffSummary';
    const response = await axios.post({
        endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
        params: params,
        options: options
    });
    if (response.data && response.data.data) {
        if (response.data.status === 'ok' && (typeof(response.data.data.jobId)!='undefined' || typeof(response.data.data.limitError)!='undefined')) {
            return response.data.data;
        } else {
            throw new Error('There has been a problem with you request to create diff summary job.');
        }
    }
    return false;
}



export const apiLoadPromptOptions = async () => {
    let options = {};
    let params = {};
    options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
    params = new URLSearchParams();
    const apiEndPoint = 'getPrompts';
    const response = await axios.post({
        endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
        params: params,
        options: options

    });
    if (response.data) {
        if (response.data.status === 'ok') {
            let aiResponse = response.data;
            return aiResponse.data;
        } else {
            throw new Error('There has been a problem with your fetch operation.');
        }
    }
    return [];
}


export const apiUpdateSummaryRating = async ({ summaryId, rating }) => {
 
    let options = {};
    let params = {};
    options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
    params = new URLSearchParams();
    params.append('summaryId', summaryId);
    params.append('rating', rating);
    const apiEndPoint = 'updateSummaryRating';
    const response = await axios.post({
        endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
        params: params,
        options: options
    });
    if (response.data) {
        if (response.data.status === 'ok') {
            let aiResponse = response.data;
            return aiResponse.data;
        } else {
            throw new Error('There has been a problem with your fetch operation.');
        }
    }
    return [];
}



export const apiCheckDiffSummaryStatus = async (jobId, checkDiffStatusApiAbortController) => {
    let options = {};
    let params = {};
    options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}, signal: checkDiffStatusApiAbortController.signal};
    params = new URLSearchParams();
    params.append('jobId', jobId);
    const apiEndPoint = 'checkDiffSummaryStatus';
    const response = await axios.post({
        endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
        params: params,
        options: options
    });
    if (response.data && response.data.data) {
        if (response.data.status === 'ok' && typeof(response.data.data)!='undefined') {
            return response.data.data;
        } else {
            throw new Error('There has been a problem with you request to check diff summary status.');
        }
    }
    return [];
}

export const apiSaveDiffSummaryByJobId = async (jobId) => {
    let options = {};
    let params = {};
    options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
    params = new URLSearchParams();
    params.append('jobId', jobId);
    const apiEndPoint = 'savePromptResultByJobId';
    const response = await axios.post({
        endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
        params: params,
        options: options
    });
    if (response.data && response.data.data) {
        if (response.data.status === 'ok' && typeof(response.data.data)!='undefined') {
            return response.data.data;
        } else {
            throw new Error('There has been a problem with saving the response.');
        }
    }
    return [];
}

import Mutation from '@/components/AIChat/store/mutationTypes';
const addChatSideEffects = store => {
    store.subscribe((mutation) => {
        if (mutation.type === ("aichat/" + Mutation.SET_ADD_CHAT_MESSAGE)) {
            setTimeout(function() { $(".cb-chatarea").scrollTop($(".cb-chatarea")[0].scrollHeight) ; }, 200);
        }
        if (mutation.type === ("aichat/" + Mutation.SET_ITEM) && mutation.payload.field == 'waitingReply') {
            if($(".cb-chatarea")) {
                setTimeout(function() { $(".cb-chatarea").scrollTop($(".cb-chatarea")[0].scrollHeight) ; }, 200);
            }
        }
        if (mutation.type === ("aichat/" + Mutation.SET_ITEM)) {
            if( mutation.payload.field == 'userMessage') {
                $("textarea#usermessage").height(22);
                if(mutation.payload.value!='') {
                    $("textarea#usermessage").height($("#usermessage").prop('scrollHeight')+2);
                }
            }
            if(mutation.payload.field == 'waitingReply' && mutation.payload.value==false) {
                setTimeout(function() { $("textarea#usermessage").focus();},20);
            }
            // if(mutation.payload.field == 'showChatMessageWindow' && mutation.payload.value === true) {
            //     setTimeout(function() { $(".chat-body").scrollTop($(".chat-body")[0].scrollHeight) ; }, 200);
            // }
            
        }
    });
}

export default [
    addChatSideEffects
];
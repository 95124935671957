export default {
    getItem: state => field => {
        const keys =  field.split(".");
        let currentLevel = state;
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            
            // If the key doesn't exist at the current level, create an empty object
            if (!Object.prototype.hasOwnProperty.call(currentLevel, key)) {
                return '';
            }
            
            // If it's the last key, set the value
            if (i === keys.length - 1) {
                return currentLevel[key];
            }
            
            // Move the current level deeper into the nested structure
            currentLevel = currentLevel[key];
        }
        return '';
    },
    getChatHistoryItem: state => chatId => {
        let chatItem = null;
        Object.values(state['chatHistory']).forEach(items => {
            console.log('items check previ item', items);
            if (Array.isArray(items)) {

                const foundChat = items.find(chat => chat.id.toString() === chatId.toString());
                console.log('foundChat check previ item', foundChat);
                if (foundChat) {
                    chatItem = { ...foundChat }; // Store the matched chat item
                }
            }
        });
        return chatItem;
    },
    getSelectedVersion: state => {
        return state.versionId in state.versions ? state.versions[state.versionId] : null;
    },
    showChatPrompt: state => {
        return (state.wizardStep == 'faq-selection' || state.wizardStep == 'chat-messaging');
    },
    showChatMessaging: state => {
        return state.wizardStep == 'chat-messaging';
    },
    showChatWiardStep: state => {
        return state.wizardStep != 'chat-messaging';
    }
}
export default {
    SET_ITEM: "SET_ITEM",
    ADD_TO_CHAT_MESSAGE: "ADD_TO_CHAT_MESSAGE",
    LOAD_CHAT_MESSAGES: "LOAD_CHAT_MESSAGES",
    LOAD_CHAT_HISTORY_ITEMS: "LOAD_CHAT_HISTORY_ITEMS",
    REMOVE_CHAT_ITEM: "REMOVE_CHAT_ITEM",
    UPDATE_CHAT_TITLE: "UPDATE_CHAT_TITLE",
    SET_CHAT_MESSAGE_RATING: "SET_CHAT_MESSAGE_RATING",
    SEARCH_CHAT_HSITORY: "SEARCH_CHAT_HSITORY",
    // ADD_CHAT_ITEM_TO_CHAT_HISTORY: "ADD_CHAT_ITEM_TO_CHAT_HISTORY"
    
};

const toc1 =
{
    "uid": 1440487,
    "path": "\/PDF\/AS\/S066\/F0001440487.pdf",
    "artefact_name": "AS\/NZS 3000 : 2007 : EN",
    "toc": [
        {
            "id": 0,
            "level": "1",
            "title": "AS\/NZS 3000:2007 WIRING RULES",
            "startPage": "1",
            "children": [
                {
                    "id": 1,
                    "level": "2",
                    "title": "PREFACE",
                    "startPage": "5",
                    "children": [],
                    "endPage": "12"
                },
                {
                    "id": 2,
                    "level": "2",
                    "title": "CONTENTS",
                    "startPage": "12",
                    "children": [],
                    "endPage": "15"
                },
                {
                    "id": 3,
                    "level": "2",
                    "title": "LIST OF TABLES",
                    "startPage": "15",
                    "children": [],
                    "endPage": "18"
                },
                {
                    "id": 4,
                    "level": "2",
                    "title": "LIST OF FIGURES",
                    "startPage": "18",
                    "children": [],
                    "endPage": "22"
                },
                {
                    "id": 5,
                    "level": "2",
                    "title": "FOREWORD",
                    "startPage": "22",
                    "children": [],
                    "endPage": "23"
                },
                {
                    "id": 6,
                    "level": "2",
                    "title": "Part 1: Scope, application and fundamental principles",
                    "startPage": "23",
                    "children": [
                        {
                            "id": 7,
                            "level": "3",
                            "title": "SECTION 1 SCOPE, APPLICATION AND FUNDAMENTAL PRINCIPLES",
                            "startPage": "24",
                            "children": [
                                {
                                    "id": 8,
                                    "level": "4",
                                    "title": "1.1 SCOPE",
                                    "startPage": "24",
                                    "children": [],
                                    "endPage": "24"
                                },
                                {
                                    "id": 9,
                                    "level": "4",
                                    "title": "1.2 APPLICATION",
                                    "startPage": "24",
                                    "children": [],
                                    "endPage": "25"
                                },
                                {
                                    "id": 10,
                                    "level": "4",
                                    "title": "1.3 REFERENCED DOCUMENTS",
                                    "startPage": "25",
                                    "children": [],
                                    "endPage": "25"
                                },
                                {
                                    "id": 11,
                                    "level": "4",
                                    "title": "1.4 DEFINITIONS",
                                    "startPage": "25",
                                    "children": [
                                        {
                                            "id": 12,
                                            "level": "5",
                                            "title": "1.4.1 Application of definitions",
                                            "startPage": "25",
                                            "children": [],
                                            "endPage": "25"
                                        },
                                        {
                                            "id": 13,
                                            "level": "5",
                                            "title": "1.4.2 Accessible, readily",
                                            "startPage": "25",
                                            "children": [],
                                            "endPage": "25"
                                        },
                                        {
                                            "id": 14,
                                            "level": "5",
                                            "title": "1.4.3 Accessory",
                                            "startPage": "25",
                                            "children": [],
                                            "endPage": "25"
                                        },
                                        {
                                            "id": 15,
                                            "level": "5",
                                            "title": "1.4.4 Active (or active conductor)",
                                            "startPage": "25",
                                            "children": [],
                                            "endPage": "25"
                                        },
                                        {
                                            "id": 16,
                                            "level": "5",
                                            "title": "1.4.5 Aerial conductor",
                                            "startPage": "25",
                                            "children": [],
                                            "endPage": "26"
                                        },
                                        {
                                            "id": 17,
                                            "level": "5",
                                            "title": "1.4.6 Appliance",
                                            "startPage": "26",
                                            "children": [],
                                            "endPage": "26"
                                        },
                                        {
                                            "id": 18,
                                            "level": "5",
                                            "title": "1.4.7 Appliance, fixed",
                                            "startPage": "26",
                                            "children": [],
                                            "endPage": "26"
                                        },
                                        {
                                            "id": 19,
                                            "level": "5",
                                            "title": "1.4.8 Appliance, hand-held",
                                            "startPage": "26",
                                            "children": [],
                                            "endPage": "26"
                                        },
                                        {
                                            "id": 20,
                                            "level": "5",
                                            "title": "1.4.9 Appliance, portable",
                                            "startPage": "26",
                                            "children": [],
                                            "endPage": "26"
                                        },
                                        {
                                            "id": 21,
                                            "level": "5",
                                            "title": "1.4.10 Appliance, stationary",
                                            "startPage": "26",
                                            "children": [],
                                            "endPage": "26"
                                        },
                                        {
                                            "id": 22,
                                            "level": "5",
                                            "title": "1.4.11 Area, hazardous",
                                            "startPage": "26",
                                            "children": [],
                                            "endPage": "26"
                                        },
                                        {
                                            "id": 23,
                                            "level": "5",
                                            "title": "1.4.12 Arm&#8217;s reach",
                                            "startPage": "26",
                                            "children": [],
                                            "endPage": "27"
                                        },
                                        {
                                            "id": 24,
                                            "level": "5",
                                            "title": "1.4.13 Authority, regulatory",
                                            "startPage": "27",
                                            "children": [],
                                            "endPage": "27"
                                        },
                                        {
                                            "id": 25,
                                            "level": "5",
                                            "title": "1.4.14 Authorized person",
                                            "startPage": "27",
                                            "children": [],
                                            "endPage": "27"
                                        },
                                        {
                                            "id": 26,
                                            "level": "5",
                                            "title": "1.4.15 Available, readily",
                                            "startPage": "27",
                                            "children": [],
                                            "endPage": "27"
                                        },
                                        {
                                            "id": 27,
                                            "level": "5",
                                            "title": "1.4.16 Barrier",
                                            "startPage": "27",
                                            "children": [],
                                            "endPage": "27"
                                        },
                                        {
                                            "id": 28,
                                            "level": "5",
                                            "title": "1.4.17 Cable",
                                            "startPage": "27",
                                            "children": [],
                                            "endPage": "27"
                                        },
                                        {
                                            "id": 29,
                                            "level": "5",
                                            "title": "1.4.18 Cable, armoured",
                                            "startPage": "27",
                                            "children": [],
                                            "endPage": "28"
                                        },
                                        {
                                            "id": 30,
                                            "level": "5",
                                            "title": "1.4.19 Cable core",
                                            "startPage": "28",
                                            "children": [],
                                            "endPage": "28"
                                        },
                                        {
                                            "id": 31,
                                            "level": "5",
                                            "title": "1.4.20 Cable, flexible",
                                            "startPage": "28",
                                            "children": [],
                                            "endPage": "28"
                                        },
                                        {
                                            "id": 32,
                                            "level": "5",
                                            "title": "1.4.21 Cable, mineral insulated metal sheathed (MIMS)",
                                            "startPage": "28",
                                            "children": [],
                                            "endPage": "28"
                                        },
                                        {
                                            "id": 33,
                                            "level": "5",
                                            "title": "1.4.22 Cable, neutral-screened",
                                            "startPage": "28",
                                            "children": [],
                                            "endPage": "28"
                                        },
                                        {
                                            "id": 34,
                                            "level": "5",
                                            "title": "1.4.23 Cable, sheathed",
                                            "startPage": "28",
                                            "children": [],
                                            "endPage": "28"
                                        },
                                        {
                                            "id": 35,
                                            "level": "5",
                                            "title": "1.4.24 Ceiling, suspended",
                                            "startPage": "28",
                                            "children": [],
                                            "endPage": "28"
                                        },
                                        {
                                            "id": 36,
                                            "level": "5",
                                            "title": "1.4.25 Circuit",
                                            "startPage": "28",
                                            "children": [],
                                            "endPage": "28"
                                        },
                                        {
                                            "id": 37,
                                            "level": "5",
                                            "title": "1.4.26 Circuit-breaker",
                                            "startPage": "28",
                                            "children": [],
                                            "endPage": "28"
                                        },
                                        {
                                            "id": 38,
                                            "level": "5",
                                            "title": "1.4.27 Class I equipment",
                                            "startPage": "28",
                                            "children": [],
                                            "endPage": "29"
                                        },
                                        {
                                            "id": 39,
                                            "level": "5",
                                            "title": "1.4.28 Class II equipment",
                                            "startPage": "29",
                                            "children": [],
                                            "endPage": "29"
                                        },
                                        {
                                            "id": 40,
                                            "level": "5",
                                            "title": "1.4.29 Class III equipment",
                                            "startPage": "29",
                                            "children": [],
                                            "endPage": "29"
                                        },
                                        {
                                            "id": 41,
                                            "level": "5",
                                            "title": "1.4.30 Competent person",
                                            "startPage": "29",
                                            "children": [],
                                            "endPage": "29"
                                        },
                                        {
                                            "id": 42,
                                            "level": "5",
                                            "title": "1.4.31 Conductor",
                                            "startPage": "29",
                                            "children": [],
                                            "endPage": "30"
                                        },
                                        {
                                            "id": 43,
                                            "level": "5",
                                            "title": "1.4.32 Conductor, bare",
                                            "startPage": "30",
                                            "children": [],
                                            "endPage": "30"
                                        },
                                        {
                                            "id": 44,
                                            "level": "5",
                                            "title": "1.4.33 Consumers mains",
                                            "startPage": "30",
                                            "children": [],
                                            "endPage": "30"
                                        },
                                        {
                                            "id": 45,
                                            "level": "5",
                                            "title": "1.4.34 Contact, direct",
                                            "startPage": "30",
                                            "children": [],
                                            "endPage": "30"
                                        },
                                        {
                                            "id": 46,
                                            "level": "5",
                                            "title": "1.4.35 Contact, indirect",
                                            "startPage": "30",
                                            "children": [],
                                            "endPage": "30"
                                        },
                                        {
                                            "id": 47,
                                            "level": "5",
                                            "title": "1.4.36 Cord, flexible",
                                            "startPage": "30",
                                            "children": [],
                                            "endPage": "30"
                                        },
                                        {
                                            "id": 48,
                                            "level": "5",
                                            "title": "1.4.37 Current, fault",
                                            "startPage": "30",
                                            "children": [],
                                            "endPage": "30"
                                        },
                                        {
                                            "id": 49,
                                            "level": "5",
                                            "title": "1.4.38 Current, overload",
                                            "startPage": "30",
                                            "children": [],
                                            "endPage": "31"
                                        },
                                        {
                                            "id": 50,
                                            "level": "5",
                                            "title": "1.4.39 Current, short-circuit",
                                            "startPage": "31",
                                            "children": [],
                                            "endPage": "31"
                                        },
                                        {
                                            "id": 51,
                                            "level": "5",
                                            "title": "1.4.40 Damp situation",
                                            "startPage": "31",
                                            "children": [],
                                            "endPage": "31"
                                        },
                                        {
                                            "id": 52,
                                            "level": "5",
                                            "title": "1.4.41 Distribution board",
                                            "startPage": "31",
                                            "children": [],
                                            "endPage": "31"
                                        },
                                        {
                                            "id": 53,
                                            "level": "5",
                                            "title": "1.4.42 Duct",
                                            "startPage": "31",
                                            "children": [],
                                            "endPage": "31"
                                        },
                                        {
                                            "id": 54,
                                            "level": "5",
                                            "title": "1.4.43 Earthed",
                                            "startPage": "31",
                                            "children": [],
                                            "endPage": "31"
                                        },
                                        {
                                            "id": 55,
                                            "level": "5",
                                            "title": "1.4.44 Earthed situation",
                                            "startPage": "31",
                                            "children": [],
                                            "endPage": "32"
                                        },
                                        {
                                            "id": 56,
                                            "level": "5",
                                            "title": "1.4.45 Earth fault-loop impedance",
                                            "startPage": "32",
                                            "children": [],
                                            "endPage": "32"
                                        },
                                        {
                                            "id": 57,
                                            "level": "5",
                                            "title": "1.4.46 Electrical equipment",
                                            "startPage": "32",
                                            "children": [],
                                            "endPage": "32"
                                        },
                                        {
                                            "id": 58,
                                            "level": "5",
                                            "title": "1.4.47 Electrical installation",
                                            "startPage": "32",
                                            "children": [],
                                            "endPage": "32"
                                        },
                                        {
                                            "id": 59,
                                            "level": "5",
                                            "title": "1.4.48 Electrical installation, domestic",
                                            "startPage": "32",
                                            "children": [],
                                            "endPage": "33"
                                        },
                                        {
                                            "id": 60,
                                            "level": "5",
                                            "title": "1.4.49 Electrical installation, multiple",
                                            "startPage": "33",
                                            "children": [],
                                            "endPage": "33"
                                        },
                                        {
                                            "id": 61,
                                            "level": "5",
                                            "title": "1.4.50 Electricity distributor",
                                            "startPage": "33",
                                            "children": [],
                                            "endPage": "33"
                                        },
                                        {
                                            "id": 62,
                                            "level": "5",
                                            "title": "1.4.51 Enclosure",
                                            "startPage": "33",
                                            "children": [],
                                            "endPage": "33"
                                        },
                                        {
                                            "id": 63,
                                            "level": "5",
                                            "title": "1.4.52 Equipotential bonding",
                                            "startPage": "33",
                                            "children": [],
                                            "endPage": "33"
                                        },
                                        {
                                            "id": 64,
                                            "level": "5",
                                            "title": "1.4.52A Explosive atmosphere",
                                            "startPage": "33",
                                            "children": [],
                                            "endPage": "33"
                                        },
                                        {
                                            "id": 65,
                                            "level": "5",
                                            "title": "1.4.53 Exposed conductive part",
                                            "startPage": "33",
                                            "children": [],
                                            "endPage": "34"
                                        },
                                        {
                                            "id": 66,
                                            "level": "5",
                                            "title": "1.4.54 Extraneous conductive part",
                                            "startPage": "34",
                                            "children": [],
                                            "endPage": "34"
                                        },
                                        {
                                            "id": 67,
                                            "level": "5",
                                            "title": "1.4.55 Fault-current limiter",
                                            "startPage": "34",
                                            "children": [],
                                            "endPage": "34"
                                        },
                                        {
                                            "id": 68,
                                            "level": "5",
                                            "title": "1.4.56 Functional earthing (FE)",
                                            "startPage": "34",
                                            "children": [],
                                            "endPage": "35"
                                        },
                                        {
                                            "id": 69,
                                            "level": "5",
                                            "title": "1.4.57 Fuse",
                                            "startPage": "35",
                                            "children": [],
                                            "endPage": "35"
                                        },
                                        {
                                            "id": 70,
                                            "level": "5",
                                            "title": "1.4.58 Installation coupler",
                                            "startPage": "35",
                                            "children": [],
                                            "endPage": "35"
                                        },
                                        {
                                            "id": 71,
                                            "level": "5",
                                            "title": "1.4.59 Insulated",
                                            "startPage": "35",
                                            "children": [],
                                            "endPage": "35"
                                        },
                                        {
                                            "id": 72,
                                            "level": "5",
                                            "title": "1.4.60 Insulation system",
                                            "startPage": "35",
                                            "children": [],
                                            "endPage": "36"
                                        },
                                        {
                                            "id": 73,
                                            "level": "5",
                                            "title": "1.4.61 International (ingress) protection (IP) classification",
                                            "startPage": "36",
                                            "children": [],
                                            "endPage": "36"
                                        },
                                        {
                                            "id": 74,
                                            "level": "5",
                                            "title": "1.4.62 Isolation (isolating function)",
                                            "startPage": "36",
                                            "children": [],
                                            "endPage": "36"
                                        },
                                        {
                                            "id": 75,
                                            "level": "5",
                                            "title": "1.4.63 Live part",
                                            "startPage": "36",
                                            "children": [],
                                            "endPage": "36"
                                        },
                                        {
                                            "id": 76,
                                            "level": "5",
                                            "title": "1.4.64 Luminaire (lighting fitting)",
                                            "startPage": "36",
                                            "children": [],
                                            "endPage": "36"
                                        },
                                        {
                                            "id": 77,
                                            "level": "5",
                                            "title": "1.4.65 Main earthing conductor",
                                            "startPage": "36",
                                            "children": [],
                                            "endPage": "37"
                                        },
                                        {
                                            "id": 78,
                                            "level": "5",
                                            "title": "1.4.66 Multiple earthed neutral (MEN) system",
                                            "startPage": "37",
                                            "children": [],
                                            "endPage": "37"
                                        },
                                        {
                                            "id": 79,
                                            "level": "5",
                                            "title": "1.4.67 Neutral (neutral conductor or mid-wire)",
                                            "startPage": "37",
                                            "children": [],
                                            "endPage": "37"
                                        },
                                        {
                                            "id": 80,
                                            "level": "5",
                                            "title": "1.4.68 Obstacle",
                                            "startPage": "37",
                                            "children": [],
                                            "endPage": "37"
                                        },
                                        {
                                            "id": 81,
                                            "level": "5",
                                            "title": "1.4.69 Outbuilding",
                                            "startPage": "37",
                                            "children": [],
                                            "endPage": "37"
                                        },
                                        {
                                            "id": 82,
                                            "level": "5",
                                            "title": "1.4.70 Overcurrent",
                                            "startPage": "37",
                                            "children": [],
                                            "endPage": "37"
                                        },
                                        {
                                            "id": 83,
                                            "level": "5",
                                            "title": "1.4.71 Plug",
                                            "startPage": "37",
                                            "children": [],
                                            "endPage": "37"
                                        },
                                        {
                                            "id": 84,
                                            "level": "5",
                                            "title": "1.4.72 Point (in wiring)",
                                            "startPage": "37",
                                            "children": [],
                                            "endPage": "37"
                                        },
                                        {
                                            "id": 85,
                                            "level": "5",
                                            "title": "1.4.73 Point of attachment",
                                            "startPage": "37",
                                            "children": [],
                                            "endPage": "37"
                                        },
                                        {
                                            "id": 86,
                                            "level": "5",
                                            "title": "1.4.74 Point of entry",
                                            "startPage": "37",
                                            "children": [],
                                            "endPage": "38"
                                        },
                                        {
                                            "id": 87,
                                            "level": "5",
                                            "title": "1.4.75 Point of supply",
                                            "startPage": "38",
                                            "children": [],
                                            "endPage": "38"
                                        },
                                        {
                                            "id": 88,
                                            "level": "5",
                                            "title": "1.4.76 Protected extra-low voltage (PELV)",
                                            "startPage": "38",
                                            "children": [],
                                            "endPage": "38"
                                        },
                                        {
                                            "id": 89,
                                            "level": "5",
                                            "title": "1.4.77 Protection, basic",
                                            "startPage": "38",
                                            "children": [],
                                            "endPage": "38"
                                        },
                                        {
                                            "id": 90,
                                            "level": "5",
                                            "title": "1.4.78 Protection, fault",
                                            "startPage": "38",
                                            "children": [],
                                            "endPage": "38"
                                        },
                                        {
                                            "id": 91,
                                            "level": "5",
                                            "title": "1.4.79 Protective earthing conductor",
                                            "startPage": "38",
                                            "children": [],
                                            "endPage": "38"
                                        },
                                        {
                                            "id": 92,
                                            "level": "5",
                                            "title": "1.4.80 Residual current device (RCD)",
                                            "startPage": "38",
                                            "children": [],
                                            "endPage": "38"
                                        },
                                        {
                                            "id": 93,
                                            "level": "5",
                                            "title": "1.4.81 Ripple-free d.c.",
                                            "startPage": "38",
                                            "children": [],
                                            "endPage": "39"
                                        },
                                        {
                                            "id": 94,
                                            "level": "5",
                                            "title": "1.4.82 Safety service",
                                            "startPage": "39",
                                            "children": [],
                                            "endPage": "39"
                                        },
                                        {
                                            "id": 95,
                                            "level": "5",
                                            "title": "1.4.83 Separated extra-low voltage (SELV )",
                                            "startPage": "39",
                                            "children": [],
                                            "endPage": "39"
                                        },
                                        {
                                            "id": 96,
                                            "level": "5",
                                            "title": "1.4.84 Service protective device",
                                            "startPage": "39",
                                            "children": [],
                                            "endPage": "39"
                                        },
                                        {
                                            "id": 97,
                                            "level": "5",
                                            "title": "1.4.84A Shall",
                                            "startPage": "39",
                                            "children": [],
                                            "endPage": "39"
                                        },
                                        {
                                            "id": 98,
                                            "level": "5",
                                            "title": "1.4.84B Should",
                                            "startPage": "39",
                                            "children": [],
                                            "endPage": "39"
                                        },
                                        {
                                            "id": 99,
                                            "level": "5",
                                            "title": "1.4.85 Socket, cord-extension",
                                            "startPage": "39",
                                            "children": [],
                                            "endPage": "39"
                                        },
                                        {
                                            "id": 100,
                                            "level": "5",
                                            "title": "1.4.86 Socket-outlet",
                                            "startPage": "39",
                                            "children": [],
                                            "endPage": "39"
                                        },
                                        {
                                            "id": 101,
                                            "level": "5",
                                            "title": "1.4.87 Source of supply",
                                            "startPage": "39",
                                            "children": [],
                                            "endPage": "40"
                                        },
                                        {
                                            "id": 102,
                                            "level": "5",
                                            "title": "1.4.88 Subcircuit, final",
                                            "startPage": "40",
                                            "children": [],
                                            "endPage": "40"
                                        },
                                        {
                                            "id": 103,
                                            "level": "5",
                                            "title": "1.4.89 Submains",
                                            "startPage": "40",
                                            "children": [],
                                            "endPage": "40"
                                        },
                                        {
                                            "id": 104,
                                            "level": "5",
                                            "title": "1.4.90 Substation",
                                            "startPage": "40",
                                            "children": [],
                                            "endPage": "40"
                                        },
                                        {
                                            "id": 105,
                                            "level": "5",
                                            "title": "1.4.91 Switchboard",
                                            "startPage": "40",
                                            "children": [],
                                            "endPage": "40"
                                        },
                                        {
                                            "id": 106,
                                            "level": "5",
                                            "title": "1.4.92 Switchboard, main",
                                            "startPage": "40",
                                            "children": [],
                                            "endPage": "40"
                                        },
                                        {
                                            "id": 107,
                                            "level": "5",
                                            "title": "1.4.93 Switchgear",
                                            "startPage": "40",
                                            "children": [],
                                            "endPage": "40"
                                        },
                                        {
                                            "id": 108,
                                            "level": "5",
                                            "title": "1.4.94 Touch current",
                                            "startPage": "40",
                                            "children": [],
                                            "endPage": "41"
                                        },
                                        {
                                            "id": 109,
                                            "level": "5",
                                            "title": "1.4.95 Touch voltage",
                                            "startPage": "41",
                                            "children": [],
                                            "endPage": "41"
                                        },
                                        {
                                            "id": 110,
                                            "level": "5",
                                            "title": "1.4.96 Track system",
                                            "startPage": "41",
                                            "children": [],
                                            "endPage": "41"
                                        },
                                        {
                                            "id": 111,
                                            "level": "5",
                                            "title": "1.4.97 Trunking, cable",
                                            "startPage": "41",
                                            "children": [],
                                            "endPage": "41"
                                        },
                                        {
                                            "id": 112,
                                            "level": "5",
                                            "title": "1.4.98 Voltage",
                                            "startPage": "41",
                                            "children": [],
                                            "endPage": "41"
                                        },
                                        {
                                            "id": 113,
                                            "level": "5",
                                            "title": "1.4.99 Wiring, catenary",
                                            "startPage": "41",
                                            "children": [],
                                            "endPage": "41"
                                        },
                                        {
                                            "id": 114,
                                            "level": "5",
                                            "title": "1.4.100 Wiring enclosure",
                                            "startPage": "41",
                                            "children": [],
                                            "endPage": "41"
                                        },
                                        {
                                            "id": 115,
                                            "level": "5",
                                            "title": "1.4.101 Wiring, equipment",
                                            "startPage": "41",
                                            "children": [],
                                            "endPage": "41"
                                        },
                                        {
                                            "id": 116,
                                            "level": "5",
                                            "title": "1.4.102 Wiring, installation",
                                            "startPage": "41",
                                            "children": [],
                                            "endPage": "42"
                                        },
                                        {
                                            "id": 117,
                                            "level": "5",
                                            "title": "1.4.103 Wiring, underground",
                                            "startPage": "42",
                                            "children": [],
                                            "endPage": "42"
                                        }
                                    ],
                                    "endPage": "42"
                                },
                                {
                                    "id": 118,
                                    "level": "4",
                                    "title": "1.5 FUNDAMENTAL PRINCIPLES",
                                    "startPage": "42",
                                    "children": [
                                        {
                                            "id": 119,
                                            "level": "5",
                                            "title": "1.5.1 Protection against dangers and damage",
                                            "startPage": "42",
                                            "children": [],
                                            "endPage": "42"
                                        },
                                        {
                                            "id": 120,
                                            "level": "5",
                                            "title": "1.5.2 Control and isolation",
                                            "startPage": "42",
                                            "children": [],
                                            "endPage": "43"
                                        },
                                        {
                                            "id": 121,
                                            "level": "5",
                                            "title": "1.5.3 Protection against electric shock",
                                            "startPage": "43",
                                            "children": [],
                                            "endPage": "44"
                                        },
                                        {
                                            "id": 122,
                                            "level": "5",
                                            "title": "1.5.4 Basic protection (protection against direct contact)",
                                            "startPage": "44",
                                            "children": [],
                                            "endPage": "46"
                                        },
                                        {
                                            "id": 123,
                                            "level": "5",
                                            "title": "1.5.5 Fault protection (Protection against indirect contact)",
                                            "startPage": "46",
                                            "children": [],
                                            "endPage": "49"
                                        },
                                        {
                                            "id": 124,
                                            "level": "5",
                                            "title": "1.5.6 Additional protection by the use of RCDs",
                                            "startPage": "49",
                                            "children": [],
                                            "endPage": "50"
                                        },
                                        {
                                            "id": 125,
                                            "level": "5",
                                            "title": "1.5.7 Basic and fault protection by use of extra-low voltage",
                                            "startPage": "50",
                                            "children": [],
                                            "endPage": "50"
                                        },
                                        {
                                            "id": 126,
                                            "level": "5",
                                            "title": "1.5.8 Protection against thermal effects in normal service",
                                            "startPage": "50",
                                            "children": [],
                                            "endPage": "51"
                                        },
                                        {
                                            "id": 127,
                                            "level": "5",
                                            "title": "1.5.9 Protection against overcurrent",
                                            "startPage": "51",
                                            "children": [],
                                            "endPage": "51"
                                        },
                                        {
                                            "id": 128,
                                            "level": "5",
                                            "title": "1.5.10 Protection against earth fault currents",
                                            "startPage": "51",
                                            "children": [],
                                            "endPage": "51"
                                        },
                                        {
                                            "id": 129,
                                            "level": "5",
                                            "title": "1.5.11 Protection against abnormal voltages",
                                            "startPage": "51",
                                            "children": [],
                                            "endPage": "53"
                                        },
                                        {
                                            "id": 130,
                                            "level": "5",
                                            "title": "1.5.12 Protection against the spread of fire",
                                            "startPage": "53",
                                            "children": [],
                                            "endPage": "53"
                                        },
                                        {
                                            "id": 131,
                                            "level": "5",
                                            "title": "1.5.13 Protection against injury from mechanical movement",
                                            "startPage": "53",
                                            "children": [],
                                            "endPage": "53"
                                        },
                                        {
                                            "id": 132,
                                            "level": "5",
                                            "title": "1.5.14 Protection against external influences",
                                            "startPage": "53",
                                            "children": [],
                                            "endPage": "54"
                                        }
                                    ],
                                    "endPage": "54"
                                },
                                {
                                    "id": 133,
                                    "level": "4",
                                    "title": "1.6 DESIGN OF AN ELECTRICAL INSTALLATION",
                                    "startPage": "54",
                                    "children": [
                                        {
                                            "id": 134,
                                            "level": "5",
                                            "title": "1.6.1 General",
                                            "startPage": "54",
                                            "children": [],
                                            "endPage": "54"
                                        },
                                        {
                                            "id": 135,
                                            "level": "5",
                                            "title": "1.6.2 Supply characteristics",
                                            "startPage": "54",
                                            "children": [],
                                            "endPage": "54"
                                        },
                                        {
                                            "id": 136,
                                            "level": "5",
                                            "title": "1.6.3 Maximum demand",
                                            "startPage": "54",
                                            "children": [],
                                            "endPage": "55"
                                        },
                                        {
                                            "id": 137,
                                            "level": "5",
                                            "title": "1.6.4 Utilization voltage",
                                            "startPage": "55",
                                            "children": [],
                                            "endPage": "55"
                                        },
                                        {
                                            "id": 138,
                                            "level": "5",
                                            "title": "1.6.5 Electrical installation circuit arrangement",
                                            "startPage": "55",
                                            "children": [],
                                            "endPage": "55"
                                        }
                                    ],
                                    "endPage": "55"
                                },
                                {
                                    "id": 139,
                                    "level": "4",
                                    "title": "1.7 SELECTION AND INSTALLATION OF ELECTRICAL EQUIPMENT",
                                    "startPage": "55",
                                    "children": [
                                        {
                                            "id": 140,
                                            "level": "5",
                                            "title": "1.7.1 Essential requirement",
                                            "startPage": "55",
                                            "children": [],
                                            "endPage": "55"
                                        },
                                        {
                                            "id": 141,
                                            "level": "5",
                                            "title": "1.7.2 Installation work practice",
                                            "startPage": "55",
                                            "children": [],
                                            "endPage": "56"
                                        },
                                        {
                                            "id": 142,
                                            "level": "5",
                                            "title": "1.7.3 Equipment selection",
                                            "startPage": "56",
                                            "children": [],
                                            "endPage": "57"
                                        },
                                        {
                                            "id": 143,
                                            "level": "5",
                                            "title": "1.7.4 Damp situations",
                                            "startPage": "57",
                                            "children": [],
                                            "endPage": "57"
                                        }
                                    ],
                                    "endPage": "57"
                                },
                                {
                                    "id": 144,
                                    "level": "4",
                                    "title": "1.8 VERIFICATION (INSPECTION AND TESTING)",
                                    "startPage": "57",
                                    "children": [],
                                    "endPage": "57"
                                },
                                {
                                    "id": 145,
                                    "level": "4",
                                    "title": "1.9 MEANS OF COMPLIANCE",
                                    "startPage": "57",
                                    "children": [
                                        {
                                            "id": 146,
                                            "level": "5",
                                            "title": "1.9.1 Compliance with Part 2 of this Standard",
                                            "startPage": "57",
                                            "children": [],
                                            "endPage": "57"
                                        },
                                        {
                                            "id": 147,
                                            "level": "5",
                                            "title": "1.9.2 Compliance with the requirements of other standards",
                                            "startPage": "57",
                                            "children": [],
                                            "endPage": "58"
                                        },
                                        {
                                            "id": 148,
                                            "level": "5",
                                            "title": "1.9.3 Alterations, additions and repairs",
                                            "startPage": "58",
                                            "children": [],
                                            "endPage": "58"
                                        },
                                        {
                                            "id": 149,
                                            "level": "5",
                                            "title": "1.9.4 Compliance by specific design and installation",
                                            "startPage": "58",
                                            "children": [],
                                            "endPage": "60"
                                        }
                                    ],
                                    "endPage": "60"
                                }
                            ],
                            "endPage": "60"
                        }
                    ],
                    "endPage": "60"
                },
                {
                    "id": 150,
                    "level": "2",
                    "title": "Part 2: Installation practices&#8212;Sections 2 to 8",
                    "startPage": "60",
                    "children": [
                        {
                            "id": 151,
                            "level": "3",
                            "title": "SECTION 2 GENERAL ARRANGEMENT, CONTROL AND PROTECTION",
                            "startPage": "61",
                            "children": [
                                {
                                    "id": 152,
                                    "level": "4",
                                    "title": "2.1 GENERAL",
                                    "startPage": "61",
                                    "children": [
                                        {
                                            "id": 153,
                                            "level": "5",
                                            "title": "2.1.1 Application",
                                            "startPage": "61",
                                            "children": [],
                                            "endPage": "61"
                                        },
                                        {
                                            "id": 154,
                                            "level": "5",
                                            "title": "2.1.2 Selection and installation",
                                            "startPage": "61",
                                            "children": [],
                                            "endPage": "61"
                                        }
                                    ],
                                    "endPage": "61"
                                },
                                {
                                    "id": 155,
                                    "level": "4",
                                    "title": "2.2 ARRANGEMENT OF ELECTRICAL INSTALLATION",
                                    "startPage": "61",
                                    "children": [
                                        {
                                            "id": 156,
                                            "level": "5",
                                            "title": "2.2.1 Circuits",
                                            "startPage": "61",
                                            "children": [],
                                            "endPage": "63"
                                        },
                                        {
                                            "id": 157,
                                            "level": "5",
                                            "title": "2.2.2 Maximum demand",
                                            "startPage": "63",
                                            "children": [],
                                            "endPage": "64"
                                        },
                                        {
                                            "id": 158,
                                            "level": "5",
                                            "title": "2.2.3 Selection and installation of conductors",
                                            "startPage": "64",
                                            "children": [],
                                            "endPage": "64"
                                        },
                                        {
                                            "id": 159,
                                            "level": "5",
                                            "title": "2.2.4 Operating characteristics of equipment",
                                            "startPage": "64",
                                            "children": [],
                                            "endPage": "65"
                                        }
                                    ],
                                    "endPage": "65"
                                },
                                {
                                    "id": 160,
                                    "level": "4",
                                    "title": "2.3 CONTROL OF ELECTRICAL INSTALLATION",
                                    "startPage": "65",
                                    "children": [
                                        {
                                            "id": 161,
                                            "level": "5",
                                            "title": "2.3.1 General",
                                            "startPage": "65",
                                            "children": [],
                                            "endPage": "65"
                                        },
                                        {
                                            "id": 162,
                                            "level": "5",
                                            "title": "2.3.2 Common requirements",
                                            "startPage": "65",
                                            "children": [],
                                            "endPage": "68"
                                        },
                                        {
                                            "id": 163,
                                            "level": "5",
                                            "title": "2.3.3 Main switches",
                                            "startPage": "68",
                                            "children": [],
                                            "endPage": "71"
                                        },
                                        {
                                            "id": 164,
                                            "level": "5",
                                            "title": "2.3.4 Additional isolating switches",
                                            "startPage": "71",
                                            "children": [],
                                            "endPage": "72"
                                        },
                                        {
                                            "id": 165,
                                            "level": "5",
                                            "title": "2.3.5 Emergency switching including emergency stopping",
                                            "startPage": "72",
                                            "children": [],
                                            "endPage": "74"
                                        },
                                        {
                                            "id": 166,
                                            "level": "5",
                                            "title": "2.3.6 Shutting down for mechanical maintenance",
                                            "startPage": "74",
                                            "children": [],
                                            "endPage": "76"
                                        },
                                        {
                                            "id": 167,
                                            "level": "5",
                                            "title": "2.3.7 Functional (control) switching",
                                            "startPage": "76",
                                            "children": [],
                                            "endPage": "77"
                                        }
                                    ],
                                    "endPage": "77"
                                },
                                {
                                    "id": 168,
                                    "level": "4",
                                    "title": "2.4 FAULT PROTECTION",
                                    "startPage": "77",
                                    "children": [
                                        {
                                            "id": 169,
                                            "level": "5",
                                            "title": "2.4.1 General",
                                            "startPage": "77",
                                            "children": [],
                                            "endPage": "77"
                                        },
                                        {
                                            "id": 170,
                                            "level": "5",
                                            "title": "2.4.2 Protection by automatic disconnection of supply",
                                            "startPage": "77",
                                            "children": [],
                                            "endPage": "77"
                                        },
                                        {
                                            "id": 171,
                                            "level": "5",
                                            "title": "2.4.3 Types of devices",
                                            "startPage": "77",
                                            "children": [],
                                            "endPage": "77"
                                        },
                                        {
                                            "id": 172,
                                            "level": "5",
                                            "title": "2.4.4 Auto reclose devices",
                                            "startPage": "77",
                                            "children": [],
                                            "endPage": "78"
                                        }
                                    ],
                                    "endPage": "78"
                                },
                                {
                                    "id": 173,
                                    "level": "4",
                                    "title": "2.5 PROTECTION AGAINST OVERCURRENT",
                                    "startPage": "78",
                                    "children": [
                                        {
                                            "id": 174,
                                            "level": "5",
                                            "title": "2.5.1 General",
                                            "startPage": "78",
                                            "children": [],
                                            "endPage": "80"
                                        },
                                        {
                                            "id": 175,
                                            "level": "5",
                                            "title": "2.5.2 Devices for protection against both overload and short-circuit currents",
                                            "startPage": "80",
                                            "children": [],
                                            "endPage": "81"
                                        },
                                        {
                                            "id": 176,
                                            "level": "5",
                                            "title": "2.5.3 Protection against overload current",
                                            "startPage": "81",
                                            "children": [],
                                            "endPage": "82"
                                        },
                                        {
                                            "id": 177,
                                            "level": "5",
                                            "title": "2.5.4 Protection against short-circuit current",
                                            "startPage": "82",
                                            "children": [],
                                            "endPage": "93"
                                        },
                                        {
                                            "id": 178,
                                            "level": "5",
                                            "title": "2.5.5 Protection against switchboard internal arcing fault currents",
                                            "startPage": "93",
                                            "children": [],
                                            "endPage": "95"
                                        },
                                        {
                                            "id": 179,
                                            "level": "5",
                                            "title": "2.5.6 Coordination of overload and short-circuit protective devices",
                                            "startPage": "95",
                                            "children": [],
                                            "endPage": "95"
                                        },
                                        {
                                            "id": 180,
                                            "level": "5",
                                            "title": "2.5.7 Reliability of supply",
                                            "startPage": "95",
                                            "children": [],
                                            "endPage": "101"
                                        }
                                    ],
                                    "endPage": "101"
                                },
                                {
                                    "id": 181,
                                    "level": "4",
                                    "title": "2.6 ADDITIONAL PROTECTION BY RESIDUAL CURRENT DEVICES",
                                    "startPage": "101",
                                    "children": [
                                        {
                                            "id": 182,
                                            "level": "5",
                                            "title": "2.6.1 General",
                                            "startPage": "101",
                                            "children": [],
                                            "endPage": "101"
                                        },
                                        {
                                            "id": 183,
                                            "level": "5",
                                            "title": "2.6.2 Selection and arrangement of devices",
                                            "startPage": "101",
                                            "children": [],
                                            "endPage": "105"
                                        },
                                        {
                                            "id": 184,
                                            "level": "5",
                                            "title": "2.6.3 Where additional protection is required",
                                            "startPage": "105",
                                            "children": [],
                                            "endPage": "108"
                                        }
                                    ],
                                    "endPage": "108"
                                },
                                {
                                    "id": 185,
                                    "level": "4",
                                    "title": "2.7 PROTECTION AGAINST OVERVOLTAGE",
                                    "startPage": "108",
                                    "children": [
                                        {
                                            "id": 186,
                                            "level": "5",
                                            "title": "2.7.1 General",
                                            "startPage": "108",
                                            "children": [],
                                            "endPage": "108"
                                        },
                                        {
                                            "id": 187,
                                            "level": "5",
                                            "title": "2.7.2 Protection by insulation or separation",
                                            "startPage": "108",
                                            "children": [],
                                            "endPage": "109"
                                        },
                                        {
                                            "id": 188,
                                            "level": "5",
                                            "title": "2.7.3 Protection by protective devices",
                                            "startPage": "109",
                                            "children": [],
                                            "endPage": "109"
                                        }
                                    ],
                                    "endPage": "109"
                                },
                                {
                                    "id": 189,
                                    "level": "4",
                                    "title": "2.8 PROTECTION AGAINST UNDERVOLTAGE",
                                    "startPage": "109",
                                    "children": [
                                        {
                                            "id": 190,
                                            "level": "5",
                                            "title": "2.8.1 General",
                                            "startPage": "109",
                                            "children": [],
                                            "endPage": "110"
                                        },
                                        {
                                            "id": 191,
                                            "level": "5",
                                            "title": "2.8.2 Selection of protective device",
                                            "startPage": "110",
                                            "children": [],
                                            "endPage": "110"
                                        }
                                    ],
                                    "endPage": "110"
                                },
                                {
                                    "id": 192,
                                    "level": "4",
                                    "title": "2.9 SWITCHBOARDS",
                                    "startPage": "110",
                                    "children": [
                                        {
                                            "id": 193,
                                            "level": "5",
                                            "title": "2.9.1 General",
                                            "startPage": "110",
                                            "children": [],
                                            "endPage": "111"
                                        },
                                        {
                                            "id": 194,
                                            "level": "5",
                                            "title": "2.9.2 Location of switchboards",
                                            "startPage": "111",
                                            "children": [],
                                            "endPage": "118"
                                        },
                                        {
                                            "id": 195,
                                            "level": "5",
                                            "title": "2.9.3 Construction",
                                            "startPage": "118",
                                            "children": [],
                                            "endPage": "119"
                                        },
                                        {
                                            "id": 196,
                                            "level": "5",
                                            "title": "2.9.4 Bars or links",
                                            "startPage": "119",
                                            "children": [],
                                            "endPage": "120"
                                        },
                                        {
                                            "id": 197,
                                            "level": "5",
                                            "title": "2.9.5 Equipment identification",
                                            "startPage": "120",
                                            "children": [],
                                            "endPage": "122"
                                        },
                                        {
                                            "id": 198,
                                            "level": "5",
                                            "title": "2.9.6 Wiring",
                                            "startPage": "122",
                                            "children": [],
                                            "endPage": "122"
                                        },
                                        {
                                            "id": 199,
                                            "level": "5",
                                            "title": "2.9.7 Fire-protective measures",
                                            "startPage": "122",
                                            "children": [],
                                            "endPage": "123"
                                        }
                                    ],
                                    "endPage": "123"
                                }
                            ],
                            "endPage": "123"
                        },
                        {
                            "id": 200,
                            "level": "3",
                            "title": "SECTION 3 SELECTION AND INSTALLATION OF WIRING SYSTEMS",
                            "startPage": "123",
                            "children": [
                                {
                                    "id": 201,
                                    "level": "4",
                                    "title": "3.1 GENERAL",
                                    "startPage": "123",
                                    "children": [
                                        {
                                            "id": 202,
                                            "level": "5",
                                            "title": "3.1.1 Application",
                                            "startPage": "123",
                                            "children": [],
                                            "endPage": "123"
                                        },
                                        {
                                            "id": 203,
                                            "level": "5",
                                            "title": "3.1.2 Selection and installation",
                                            "startPage": "123",
                                            "children": [],
                                            "endPage": "123"
                                        }
                                    ],
                                    "endPage": "123"
                                },
                                {
                                    "id": 204,
                                    "level": "4",
                                    "title": "3.2 TYPES OF WIRING SYSTEMS",
                                    "startPage": "123",
                                    "children": [],
                                    "endPage": "124"
                                },
                                {
                                    "id": 205,
                                    "level": "4",
                                    "title": "3.3 EXTERNAL INFLUENCES",
                                    "startPage": "124",
                                    "children": [
                                        {
                                            "id": 206,
                                            "level": "5",
                                            "title": "3.3.1 General",
                                            "startPage": "124",
                                            "children": [],
                                            "endPage": "124"
                                        },
                                        {
                                            "id": 207,
                                            "level": "5",
                                            "title": "3.3.2 Particular situations",
                                            "startPage": "124",
                                            "children": [],
                                            "endPage": "129"
                                        }
                                    ],
                                    "endPage": "129"
                                },
                                {
                                    "id": 208,
                                    "level": "4",
                                    "title": "3.4 CURRENT-CARRYING CAPACITY",
                                    "startPage": "129",
                                    "children": [
                                        {
                                            "id": 209,
                                            "level": "5",
                                            "title": "3.4.1 General",
                                            "startPage": "129",
                                            "children": [],
                                            "endPage": "130"
                                        },
                                        {
                                            "id": 210,
                                            "level": "5",
                                            "title": "3.4.2 Operating temperature limits",
                                            "startPage": "130",
                                            "children": [],
                                            "endPage": "131"
                                        },
                                        {
                                            "id": 211,
                                            "level": "5",
                                            "title": "3.4.3 Conductors in parallel",
                                            "startPage": "131",
                                            "children": [],
                                            "endPage": "132"
                                        },
                                        {
                                            "id": 212,
                                            "level": "5",
                                            "title": "3.4.4 Coordination between conductors and protective devices",
                                            "startPage": "132",
                                            "children": [],
                                            "endPage": "132"
                                        }
                                    ],
                                    "endPage": "132"
                                },
                                {
                                    "id": 213,
                                    "level": "4",
                                    "title": "3.5 CONDUCTOR SIZE",
                                    "startPage": "132",
                                    "children": [
                                        {
                                            "id": 214,
                                            "level": "5",
                                            "title": "3.5.1 General",
                                            "startPage": "132",
                                            "children": [],
                                            "endPage": "133"
                                        },
                                        {
                                            "id": 215,
                                            "level": "5",
                                            "title": "3.5.2 Neutral conductor",
                                            "startPage": "133",
                                            "children": [],
                                            "endPage": "134"
                                        },
                                        {
                                            "id": 216,
                                            "level": "5",
                                            "title": "3.5.3 Earthing conductor",
                                            "startPage": "134",
                                            "children": [],
                                            "endPage": "134"
                                        }
                                    ],
                                    "endPage": "134"
                                },
                                {
                                    "id": 217,
                                    "level": "4",
                                    "title": "3.6 VOLTAGE DROP",
                                    "startPage": "134",
                                    "children": [
                                        {
                                            "id": 218,
                                            "level": "5",
                                            "title": "3.6.1 General",
                                            "startPage": "134",
                                            "children": [],
                                            "endPage": "134"
                                        },
                                        {
                                            "id": 219,
                                            "level": "5",
                                            "title": "3.6.2 Value",
                                            "startPage": "134",
                                            "children": [],
                                            "endPage": "135"
                                        },
                                        {
                                            "id": 220,
                                            "level": "5",
                                            "title": "3.6.3 Conductors in parallel",
                                            "startPage": "135",
                                            "children": [],
                                            "endPage": "135"
                                        }
                                    ],
                                    "endPage": "135"
                                },
                                {
                                    "id": 221,
                                    "level": "4",
                                    "title": "3.7 ELECTRICAL CONNECTIONS",
                                    "startPage": "135",
                                    "children": [
                                        {
                                            "id": 222,
                                            "level": "5",
                                            "title": "3.7.1 General",
                                            "startPage": "135",
                                            "children": [],
                                            "endPage": "136"
                                        },
                                        {
                                            "id": 223,
                                            "level": "5",
                                            "title": "3.7.2 Connection methods",
                                            "startPage": "136",
                                            "children": [],
                                            "endPage": "139"
                                        },
                                        {
                                            "id": 224,
                                            "level": "5",
                                            "title": "3.7.3 Joints in cables",
                                            "startPage": "139",
                                            "children": [],
                                            "endPage": "139"
                                        },
                                        {
                                            "id": 225,
                                            "level": "5",
                                            "title": "3.7.4 Installation couplers",
                                            "startPage": "139",
                                            "children": [],
                                            "endPage": "139"
                                        }
                                    ],
                                    "endPage": "139"
                                },
                                {
                                    "id": 226,
                                    "level": "4",
                                    "title": "3.8 IDENTIFICATION",
                                    "startPage": "139",
                                    "children": [
                                        {
                                            "id": 227,
                                            "level": "5",
                                            "title": "3.8.1 General",
                                            "startPage": "139",
                                            "children": [],
                                            "endPage": "140"
                                        },
                                        {
                                            "id": 228,
                                            "level": "5",
                                            "title": "3.8.2 Colour identification by sleeving or other means",
                                            "startPage": "140",
                                            "children": [],
                                            "endPage": "141"
                                        },
                                        {
                                            "id": 229,
                                            "level": "5",
                                            "title": "3.8.3 Exceptions and special applications",
                                            "startPage": "141",
                                            "children": [],
                                            "endPage": "143"
                                        }
                                    ],
                                    "endPage": "143"
                                },
                                {
                                    "id": 230,
                                    "level": "4",
                                    "title": "3.9 INSTALLATION REQUIREMENTS",
                                    "startPage": "143",
                                    "children": [
                                        {
                                            "id": 231,
                                            "level": "5",
                                            "title": "3.9.1 General",
                                            "startPage": "143",
                                            "children": [],
                                            "endPage": "143"
                                        },
                                        {
                                            "id": 232,
                                            "level": "5",
                                            "title": "3.9.2 Methods of installation",
                                            "startPage": "143",
                                            "children": [],
                                            "endPage": "143"
                                        },
                                        {
                                            "id": 233,
                                            "level": "5",
                                            "title": "3.9.3 Support and fixing",
                                            "startPage": "143",
                                            "children": [],
                                            "endPage": "146"
                                        },
                                        {
                                            "id": 234,
                                            "level": "5",
                                            "title": "3.9.4 Protection against mechanical damage",
                                            "startPage": "146",
                                            "children": [],
                                            "endPage": "148"
                                        },
                                        {
                                            "id": 235,
                                            "level": "5",
                                            "title": "3.9.5 Wiring systems",
                                            "startPage": "148",
                                            "children": [],
                                            "endPage": "148"
                                        },
                                        {
                                            "id": 236,
                                            "level": "5",
                                            "title": "3.9.6 Change of direction",
                                            "startPage": "148",
                                            "children": [],
                                            "endPage": "149"
                                        },
                                        {
                                            "id": 237,
                                            "level": "5",
                                            "title": "3.9.7 Particular requirements",
                                            "startPage": "149",
                                            "children": [],
                                            "endPage": "152"
                                        },
                                        {
                                            "id": 238,
                                            "level": "5",
                                            "title": "3.9.8 Prevention of mutual detrimental effects between services",
                                            "startPage": "152",
                                            "children": [],
                                            "endPage": "155"
                                        },
                                        {
                                            "id": 239,
                                            "level": "5",
                                            "title": "3.9.9 Selection and installation to minimize the spread of fire",
                                            "startPage": "155",
                                            "children": [],
                                            "endPage": "157"
                                        },
                                        {
                                            "id": 240,
                                            "level": "5",
                                            "title": "3.9.10 Limitation of circulating and eddy currents",
                                            "startPage": "157",
                                            "children": [],
                                            "endPage": "158"
                                        },
                                        {
                                            "id": 241,
                                            "level": "5",
                                            "title": "3.9.11 Electromagnetic interference",
                                            "startPage": "158",
                                            "children": [],
                                            "endPage": "159"
                                        }
                                    ],
                                    "endPage": "159"
                                },
                                {
                                    "id": 242,
                                    "level": "4",
                                    "title": "3.10 ENCLOSURE OF CABLES",
                                    "startPage": "159",
                                    "children": [
                                        {
                                            "id": 243,
                                            "level": "5",
                                            "title": "3.10.1 General",
                                            "startPage": "159",
                                            "children": [],
                                            "endPage": "159"
                                        },
                                        {
                                            "id": 244,
                                            "level": "5",
                                            "title": "3.10.2 Wiring enclosures",
                                            "startPage": "159",
                                            "children": [],
                                            "endPage": "160"
                                        },
                                        {
                                            "id": 245,
                                            "level": "5",
                                            "title": "3.10.3 Installation of wiring enclosures",
                                            "startPage": "160",
                                            "children": [],
                                            "endPage": "162"
                                        }
                                    ],
                                    "endPage": "162"
                                },
                                {
                                    "id": 246,
                                    "level": "4",
                                    "title": "3.11 UNDERGROUND WIRING SYSTEMS",
                                    "startPage": "162",
                                    "children": [
                                        {
                                            "id": 247,
                                            "level": "5",
                                            "title": "3.11.1 Suitability and protection",
                                            "startPage": "162",
                                            "children": [],
                                            "endPage": "162"
                                        },
                                        {
                                            "id": 248,
                                            "level": "5",
                                            "title": "3.11.2 Classification of wiring systems",
                                            "startPage": "162",
                                            "children": [],
                                            "endPage": "163"
                                        },
                                        {
                                            "id": 249,
                                            "level": "5",
                                            "title": "3.11.3 Arrangements",
                                            "startPage": "163",
                                            "children": [],
                                            "endPage": "165"
                                        },
                                        {
                                            "id": 250,
                                            "level": "5",
                                            "title": "3.11.4 Installation requirements",
                                            "startPage": "165",
                                            "children": [],
                                            "endPage": "170"
                                        },
                                        {
                                            "id": 251,
                                            "level": "5",
                                            "title": "3.11.5 Spacing from other underground services",
                                            "startPage": "170",
                                            "children": [],
                                            "endPage": "171"
                                        }
                                    ],
                                    "endPage": "171"
                                },
                                {
                                    "id": 252,
                                    "level": "4",
                                    "title": "3.12 AERIAL WIRING SYSTEMS",
                                    "startPage": "171",
                                    "children": [
                                        {
                                            "id": 253,
                                            "level": "5",
                                            "title": "3.12.1 Types of conductor",
                                            "startPage": "171",
                                            "children": [],
                                            "endPage": "172"
                                        },
                                        {
                                            "id": 254,
                                            "level": "5",
                                            "title": "3.12.2 Arrangements",
                                            "startPage": "172",
                                            "children": [],
                                            "endPage": "172"
                                        },
                                        {
                                            "id": 255,
                                            "level": "5",
                                            "title": "3.12.3 Clearances",
                                            "startPage": "172",
                                            "children": [],
                                            "endPage": "175"
                                        },
                                        {
                                            "id": 256,
                                            "level": "5",
                                            "title": "3.12.4 Distance between supports (spans)",
                                            "startPage": "175",
                                            "children": [],
                                            "endPage": "175"
                                        },
                                        {
                                            "id": 257,
                                            "level": "5",
                                            "title": "3.12.5 Aerial conductor supports",
                                            "startPage": "175",
                                            "children": [],
                                            "endPage": "176"
                                        },
                                        {
                                            "id": 258,
                                            "level": "5",
                                            "title": "3.12.6 Poles and posts (including supports, struts and extensions to structures)",
                                            "startPage": "176",
                                            "children": [],
                                            "endPage": "176"
                                        },
                                        {
                                            "id": 259,
                                            "level": "5",
                                            "title": "3.12.7 Joints and connections",
                                            "startPage": "176",
                                            "children": [],
                                            "endPage": "177"
                                        }
                                    ],
                                    "endPage": "177"
                                },
                                {
                                    "id": 260,
                                    "level": "4",
                                    "title": "3.13 CABLES SUPPORTED BY A CATENARY",
                                    "startPage": "177",
                                    "children": [
                                        {
                                            "id": 261,
                                            "level": "5",
                                            "title": "3.13.1 Types of cables",
                                            "startPage": "177",
                                            "children": [],
                                            "endPage": "177"
                                        },
                                        {
                                            "id": 262,
                                            "level": "5",
                                            "title": "3.13.2 Catenary supports",
                                            "startPage": "177",
                                            "children": [],
                                            "endPage": "177"
                                        },
                                        {
                                            "id": 263,
                                            "level": "5",
                                            "title": "3.13.3 Clearances",
                                            "startPage": "177",
                                            "children": [],
                                            "endPage": "177"
                                        }
                                    ],
                                    "endPage": "177"
                                },
                                {
                                    "id": 264,
                                    "level": "4",
                                    "title": "3.14 SAFETY SERVICES",
                                    "startPage": "177",
                                    "children": [],
                                    "endPage": "177"
                                },
                                {
                                    "id": 265,
                                    "level": "4",
                                    "title": "3.15 BUSWAYS, INCLUDING RISING MAINS SYSTEMS",
                                    "startPage": "177",
                                    "children": [],
                                    "endPage": "178"
                                },
                                {
                                    "id": 266,
                                    "level": "4",
                                    "title": "3.16 EARTH SHEATH RETURN (ESR) SYSTEM",
                                    "startPage": "178",
                                    "children": [],
                                    "endPage": "179"
                                }
                            ],
                            "endPage": "179"
                        },
                        {
                            "id": 267,
                            "level": "3",
                            "title": "SECTION 4 SELECTION AND INSTALLATION OF APPLIANCES AND ACCESSORIES",
                            "startPage": "179",
                            "children": [
                                {
                                    "id": 268,
                                    "level": "4",
                                    "title": "4.1 GENERAL",
                                    "startPage": "179",
                                    "children": [
                                        {
                                            "id": 269,
                                            "level": "5",
                                            "title": "4.1.1 Application",
                                            "startPage": "179",
                                            "children": [],
                                            "endPage": "179"
                                        },
                                        {
                                            "id": 270,
                                            "level": "5",
                                            "title": "4.1.2 Selection and installation",
                                            "startPage": "179",
                                            "children": [],
                                            "endPage": "180"
                                        },
                                        {
                                            "id": 271,
                                            "level": "5",
                                            "title": "4.1.3 External Influences",
                                            "startPage": "180",
                                            "children": [],
                                            "endPage": "181"
                                        },
                                        {
                                            "id": 272,
                                            "level": "5",
                                            "title": "4.1.4 Adverse effects and interference",
                                            "startPage": "181",
                                            "children": [],
                                            "endPage": "181"
                                        }
                                    ],
                                    "endPage": "181"
                                },
                                {
                                    "id": 273,
                                    "level": "4",
                                    "title": "4.2 PROTECTION AGAINST THERMAL EFFECTS",
                                    "startPage": "181",
                                    "children": [
                                        {
                                            "id": 274,
                                            "level": "5",
                                            "title": "4.2.1 General",
                                            "startPage": "181",
                                            "children": [],
                                            "endPage": "181"
                                        },
                                        {
                                            "id": 275,
                                            "level": "5",
                                            "title": "4.2.2 Prevention of fire hazard",
                                            "startPage": "181",
                                            "children": [],
                                            "endPage": "183"
                                        },
                                        {
                                            "id": 276,
                                            "level": "5",
                                            "title": "4.2.3 Protection against burns",
                                            "startPage": "183",
                                            "children": [],
                                            "endPage": "184"
                                        }
                                    ],
                                    "endPage": "184"
                                },
                                {
                                    "id": 277,
                                    "level": "4",
                                    "title": "4.3 CONNECTION OF ELECTRICAL EQUIPMENT",
                                    "startPage": "184",
                                    "children": [
                                        {
                                            "id": 278,
                                            "level": "5",
                                            "title": "4.3.1 General",
                                            "startPage": "184",
                                            "children": [],
                                            "endPage": "184"
                                        },
                                        {
                                            "id": 279,
                                            "level": "5",
                                            "title": "4.3.2 Direct connection",
                                            "startPage": "184",
                                            "children": [],
                                            "endPage": "185"
                                        },
                                        {
                                            "id": 280,
                                            "level": "5",
                                            "title": "4.3.3 Socket-outlet",
                                            "startPage": "185",
                                            "children": [],
                                            "endPage": "186"
                                        },
                                        {
                                            "id": 281,
                                            "level": "5",
                                            "title": "4.3.4 Other connecting devices",
                                            "startPage": "186",
                                            "children": [],
                                            "endPage": "186"
                                        },
                                        {
                                            "id": 282,
                                            "level": "5",
                                            "title": "4.3.5 Equipment wiring",
                                            "startPage": "186",
                                            "children": [],
                                            "endPage": "187"
                                        }
                                    ],
                                    "endPage": "187"
                                },
                                {
                                    "id": 283,
                                    "level": "4",
                                    "title": "4.4 SOCKET-OUTLETS",
                                    "startPage": "187",
                                    "children": [
                                        {
                                            "id": 284,
                                            "level": "5",
                                            "title": "4.4.1 Types",
                                            "startPage": "187",
                                            "children": [],
                                            "endPage": "187"
                                        },
                                        {
                                            "id": 285,
                                            "level": "5",
                                            "title": "4.4.2 Location",
                                            "startPage": "187",
                                            "children": [],
                                            "endPage": "189"
                                        },
                                        {
                                            "id": 286,
                                            "level": "5",
                                            "title": "4.4.3 Earthing contacts",
                                            "startPage": "189",
                                            "children": [],
                                            "endPage": "189"
                                        },
                                        {
                                            "id": 287,
                                            "level": "5",
                                            "title": "4.4.4 Switching device",
                                            "startPage": "189",
                                            "children": [],
                                            "endPage": "190"
                                        },
                                        {
                                            "id": 288,
                                            "level": "5",
                                            "title": "4.4.5 Polarization and phase sequence",
                                            "startPage": "190",
                                            "children": [],
                                            "endPage": "190"
                                        }
                                    ],
                                    "endPage": "190"
                                },
                                {
                                    "id": 289,
                                    "level": "4",
                                    "title": "4.5 LIGHTING EQUIPMENT AND ACCESSORIES",
                                    "startPage": "190",
                                    "children": [
                                        {
                                            "id": 290,
                                            "level": "5",
                                            "title": "4.5.1 Lampholders",
                                            "startPage": "190",
                                            "children": [],
                                            "endPage": "191"
                                        },
                                        {
                                            "id": 291,
                                            "level": "5",
                                            "title": "4.5.2 Lamps and luminaires",
                                            "startPage": "191",
                                            "children": [],
                                            "endPage": "198"
                                        }
                                    ],
                                    "endPage": "198"
                                },
                                {
                                    "id": 292,
                                    "level": "4",
                                    "title": "4.6 SMOKE AND FIRE DETECTORS",
                                    "startPage": "198",
                                    "children": [],
                                    "endPage": "198"
                                },
                                {
                                    "id": 293,
                                    "level": "4",
                                    "title": "4.7 COOKING APPLIANCES",
                                    "startPage": "198",
                                    "children": [
                                        {
                                            "id": 294,
                                            "level": "5",
                                            "title": "4.7.1 Switching device",
                                            "startPage": "198",
                                            "children": [],
                                            "endPage": "199"
                                        },
                                        {
                                            "id": 295,
                                            "level": "5",
                                            "title": "4.7.2 Connection",
                                            "startPage": "199",
                                            "children": [],
                                            "endPage": "199"
                                        }
                                    ],
                                    "endPage": "199"
                                },
                                {
                                    "id": 296,
                                    "level": "4",
                                    "title": "4.8 APPLIANCES PRODUCING HOT WATER OR STEAM",
                                    "startPage": "199",
                                    "children": [
                                        {
                                            "id": 297,
                                            "level": "5",
                                            "title": "4.8.1 General",
                                            "startPage": "199",
                                            "children": [],
                                            "endPage": "199"
                                        },
                                        {
                                            "id": 298,
                                            "level": "5",
                                            "title": "4.8.2 Water heaters",
                                            "startPage": "199",
                                            "children": [],
                                            "endPage": "200"
                                        }
                                    ],
                                    "endPage": "200"
                                },
                                {
                                    "id": 299,
                                    "level": "4",
                                    "title": "4.9 ROOM HEATERS",
                                    "startPage": "200",
                                    "children": [
                                        {
                                            "id": 300,
                                            "level": "5",
                                            "title": "4.9.1 General",
                                            "startPage": "200",
                                            "children": [],
                                            "endPage": "200"
                                        },
                                        {
                                            "id": 301,
                                            "level": "5",
                                            "title": "4.9.2 Isolating switches",
                                            "startPage": "200",
                                            "children": [],
                                            "endPage": "200"
                                        },
                                        {
                                            "id": 302,
                                            "level": "5",
                                            "title": "4.9.3 Functional switches",
                                            "startPage": "200",
                                            "children": [],
                                            "endPage": "200"
                                        }
                                    ],
                                    "endPage": "200"
                                },
                                {
                                    "id": 303,
                                    "level": "4",
                                    "title": "4.10 ELECTRIC HEATING CABLES FOR FLOORS AND CEILINGS AND TRACE HEATING APPLICATIONS",
                                    "startPage": "200",
                                    "children": [
                                        {
                                            "id": 304,
                                            "level": "5",
                                            "title": "4.10.1 General",
                                            "startPage": "200",
                                            "children": [],
                                            "endPage": "201"
                                        },
                                        {
                                            "id": 305,
                                            "level": "5",
                                            "title": "4.10.2 Heating cables",
                                            "startPage": "201",
                                            "children": [],
                                            "endPage": "201"
                                        },
                                        {
                                            "id": 306,
                                            "level": "5",
                                            "title": "4.10.3 Isolating switches",
                                            "startPage": "201",
                                            "children": [],
                                            "endPage": "201"
                                        },
                                        {
                                            "id": 307,
                                            "level": "5",
                                            "title": "4.10.4 Functional switches",
                                            "startPage": "201",
                                            "children": [],
                                            "endPage": "202"
                                        },
                                        {
                                            "id": 308,
                                            "level": "5",
                                            "title": "4.10.5 Additional protection",
                                            "startPage": "202",
                                            "children": [],
                                            "endPage": "202"
                                        },
                                        {
                                            "id": 309,
                                            "level": "5",
                                            "title": "4.10.6 Signs",
                                            "startPage": "202",
                                            "children": [],
                                            "endPage": "202"
                                        }
                                    ],
                                    "endPage": "202"
                                },
                                {
                                    "id": 310,
                                    "level": "4",
                                    "title": "4.11 ELECTRIC DUCT HEATERS",
                                    "startPage": "202",
                                    "children": [],
                                    "endPage": "202"
                                },
                                {
                                    "id": 311,
                                    "level": "4",
                                    "title": "4.12 ELECTRICITY CONVERTERS",
                                    "startPage": "202",
                                    "children": [
                                        {
                                            "id": 312,
                                            "level": "5",
                                            "title": "4.12.1 General",
                                            "startPage": "202",
                                            "children": [],
                                            "endPage": "203"
                                        },
                                        {
                                            "id": 313,
                                            "level": "5",
                                            "title": "4.12.2 Selection and installation",
                                            "startPage": "203",
                                            "children": [],
                                            "endPage": "203"
                                        },
                                        {
                                            "id": 314,
                                            "level": "5",
                                            "title": "4.12.3 Control",
                                            "startPage": "203",
                                            "children": [],
                                            "endPage": "203"
                                        },
                                        {
                                            "id": 315,
                                            "level": "5",
                                            "title": "4.12.4 Isolation",
                                            "startPage": "203",
                                            "children": [],
                                            "endPage": "204"
                                        },
                                        {
                                            "id": 316,
                                            "level": "5",
                                            "title": "4.12.5 Overcurrent protection",
                                            "startPage": "204",
                                            "children": [],
                                            "endPage": "205"
                                        },
                                        {
                                            "id": 317,
                                            "level": "5",
                                            "title": "4.12.6 Earthing",
                                            "startPage": "205",
                                            "children": [],
                                            "endPage": "205"
                                        },
                                        {
                                            "id": 318,
                                            "level": "5",
                                            "title": "4.12.7 Neutral continuity",
                                            "startPage": "205",
                                            "children": [],
                                            "endPage": "205"
                                        },
                                        {
                                            "id": 319,
                                            "level": "5",
                                            "title": "4.12.8 Electrical equipment connected to output",
                                            "startPage": "205",
                                            "children": [],
                                            "endPage": "206"
                                        }
                                    ],
                                    "endPage": "206"
                                },
                                {
                                    "id": 320,
                                    "level": "4",
                                    "title": "4.13 MOTORS",
                                    "startPage": "206",
                                    "children": [
                                        {
                                            "id": 321,
                                            "level": "5",
                                            "title": "4.13.1 Protection against injury from mechanical movement",
                                            "startPage": "206",
                                            "children": [],
                                            "endPage": "207"
                                        },
                                        {
                                            "id": 322,
                                            "level": "5",
                                            "title": "4.13.2 Protection against overload",
                                            "startPage": "207",
                                            "children": [],
                                            "endPage": "208"
                                        },
                                        {
                                            "id": 323,
                                            "level": "5",
                                            "title": "4.13.3 Protection against overtemperature",
                                            "startPage": "208",
                                            "children": [],
                                            "endPage": "209"
                                        }
                                    ],
                                    "endPage": "209"
                                },
                                {
                                    "id": 324,
                                    "level": "4",
                                    "title": "4.14 TRANSFORMERS",
                                    "startPage": "209",
                                    "children": [
                                        {
                                            "id": 325,
                                            "level": "5",
                                            "title": "4.14.1 General",
                                            "startPage": "209",
                                            "children": [],
                                            "endPage": "209"
                                        },
                                        {
                                            "id": 326,
                                            "level": "5",
                                            "title": "4.14.2 Secondary circuit",
                                            "startPage": "209",
                                            "children": [],
                                            "endPage": "210"
                                        },
                                        {
                                            "id": 327,
                                            "level": "5",
                                            "title": "4.14.3 Low voltage transformer supply",
                                            "startPage": "210",
                                            "children": [],
                                            "endPage": "210"
                                        },
                                        {
                                            "id": 328,
                                            "level": "5",
                                            "title": "4.14.4 Autotransformers",
                                            "startPage": "210",
                                            "children": [],
                                            "endPage": "210"
                                        },
                                        {
                                            "id": 329,
                                            "level": "5",
                                            "title": "4.14.5 Step-up transformers",
                                            "startPage": "210",
                                            "children": [],
                                            "endPage": "210"
                                        }
                                    ],
                                    "endPage": "210"
                                },
                                {
                                    "id": 330,
                                    "level": "4",
                                    "title": "4.15 CAPACITORS",
                                    "startPage": "210",
                                    "children": [
                                        {
                                            "id": 331,
                                            "level": "5",
                                            "title": "4.15.1 General",
                                            "startPage": "210",
                                            "children": [],
                                            "endPage": "210"
                                        },
                                        {
                                            "id": 332,
                                            "level": "5",
                                            "title": "4.15.2 Electrical equipment",
                                            "startPage": "210",
                                            "children": [],
                                            "endPage": "211"
                                        },
                                        {
                                            "id": 333,
                                            "level": "5",
                                            "title": "4.15.3 Provision for discharge and control",
                                            "startPage": "211",
                                            "children": [],
                                            "endPage": "212"
                                        }
                                    ],
                                    "endPage": "212"
                                },
                                {
                                    "id": 334,
                                    "level": "4",
                                    "title": "4.16 ELECTRICAL EQUIPMENT CONTAINING LIQUID DIELECTRICS",
                                    "startPage": "212",
                                    "children": [
                                        {
                                            "id": 335,
                                            "level": "5",
                                            "title": "4.16.1 General",
                                            "startPage": "212",
                                            "children": [],
                                            "endPage": "212"
                                        },
                                        {
                                            "id": 336,
                                            "level": "5",
                                            "title": "4.16.2 Liquid dielectrics having a flashpoint not exceeding 250&#176;C",
                                            "startPage": "212",
                                            "children": [],
                                            "endPage": "213"
                                        }
                                    ],
                                    "endPage": "213"
                                },
                                {
                                    "id": 337,
                                    "level": "4",
                                    "title": "4.17 BATTERIES",
                                    "startPage": "213",
                                    "children": [],
                                    "endPage": "213"
                                },
                                {
                                    "id": 338,
                                    "level": "4",
                                    "title": "4.18 GAS APPLIANCES",
                                    "startPage": "213",
                                    "children": [
                                        {
                                            "id": 339,
                                            "level": "5",
                                            "title": "4.18.1 General",
                                            "startPage": "213",
                                            "children": [],
                                            "endPage": "213"
                                        },
                                        {
                                            "id": 340,
                                            "level": "5",
                                            "title": "4.18.2 Gas cylinders containing heavier-than-air gases&#8212;outdoors",
                                            "startPage": "213",
                                            "children": [],
                                            "endPage": "214"
                                        },
                                        {
                                            "id": 341,
                                            "level": "5",
                                            "title": "4.18.3 Reticulated lighter-than-air gas system, metering system and regulators",
                                            "startPage": "214",
                                            "children": [],
                                            "endPage": "215"
                                        }
                                    ],
                                    "endPage": "215"
                                },
                                {
                                    "id": 342,
                                    "level": "4",
                                    "title": "4.19 AIRCONDITIONING AND HEAT PUMP SYSTEMS",
                                    "startPage": "215",
                                    "children": [],
                                    "endPage": "216"
                                }
                            ],
                            "endPage": "216"
                        },
                        {
                            "id": 343,
                            "level": "3",
                            "title": "SECTION 5 EARTHING ARRANGEMENTS AND EARTHING CONDUCTORS",
                            "startPage": "216",
                            "children": [
                                {
                                    "id": 344,
                                    "level": "4",
                                    "title": "5.1 GENERAL",
                                    "startPage": "216",
                                    "children": [
                                        {
                                            "id": 345,
                                            "level": "5",
                                            "title": "5.1.1 Application",
                                            "startPage": "216",
                                            "children": [],
                                            "endPage": "216"
                                        },
                                        {
                                            "id": 346,
                                            "level": "5",
                                            "title": "5.1.2 Selection and installation",
                                            "startPage": "216",
                                            "children": [],
                                            "endPage": "216"
                                        },
                                        {
                                            "id": 347,
                                            "level": "5",
                                            "title": "5.1.3 MEN earthing system",
                                            "startPage": "216",
                                            "children": [],
                                            "endPage": "219"
                                        },
                                        {
                                            "id": 348,
                                            "level": "5",
                                            "title": "5.1.4 Other earthing systems",
                                            "startPage": "219",
                                            "children": [],
                                            "endPage": "220"
                                        }
                                    ],
                                    "endPage": "220"
                                },
                                {
                                    "id": 349,
                                    "level": "4",
                                    "title": "5.2 EARTHING FUNCTIONS",
                                    "startPage": "220",
                                    "children": [
                                        {
                                            "id": 350,
                                            "level": "5",
                                            "title": "5.2.1 Protective earthing",
                                            "startPage": "220",
                                            "children": [],
                                            "endPage": "220"
                                        },
                                        {
                                            "id": 351,
                                            "level": "5",
                                            "title": "5.2.2 Functional earthing (FE)",
                                            "startPage": "220",
                                            "children": [],
                                            "endPage": "221"
                                        },
                                        {
                                            "id": 352,
                                            "level": "5",
                                            "title": "5.2.3 For combined protective and functional purposes",
                                            "startPage": "221",
                                            "children": [],
                                            "endPage": "221"
                                        }
                                    ],
                                    "endPage": "221"
                                },
                                {
                                    "id": 353,
                                    "level": "4",
                                    "title": "5.3 EARTHING SYSTEM PARTS",
                                    "startPage": "221",
                                    "children": [
                                        {
                                            "id": 354,
                                            "level": "5",
                                            "title": "5.3.1 General",
                                            "startPage": "221",
                                            "children": [],
                                            "endPage": "221"
                                        },
                                        {
                                            "id": 355,
                                            "level": "5",
                                            "title": "5.3.2 Earthing conductor material and type",
                                            "startPage": "221",
                                            "children": [],
                                            "endPage": "225"
                                        },
                                        {
                                            "id": 356,
                                            "level": "5",
                                            "title": "5.3.3 Earthing conductor size (cross-sectional area)",
                                            "startPage": "225",
                                            "children": [],
                                            "endPage": "229"
                                        },
                                        {
                                            "id": 357,
                                            "level": "5",
                                            "title": "5.3.4 Main earthing terminal\/connection or bar",
                                            "startPage": "229",
                                            "children": [],
                                            "endPage": "229"
                                        },
                                        {
                                            "id": 358,
                                            "level": "5",
                                            "title": "5.3.5 MEN connection",
                                            "startPage": "229",
                                            "children": [],
                                            "endPage": "230"
                                        },
                                        {
                                            "id": 359,
                                            "level": "5",
                                            "title": "5.3.6 Earth electrodes",
                                            "startPage": "230",
                                            "children": [],
                                            "endPage": "232"
                                        },
                                        {
                                            "id": 360,
                                            "level": "5",
                                            "title": "5.3.7 Functional earthing conductors",
                                            "startPage": "232",
                                            "children": [],
                                            "endPage": "233"
                                        }
                                    ],
                                    "endPage": "233"
                                },
                                {
                                    "id": 361,
                                    "level": "4",
                                    "title": "5.4 EARTHING OF EQUIPMENT",
                                    "startPage": "233",
                                    "children": [
                                        {
                                            "id": 362,
                                            "level": "5",
                                            "title": "5.4.1 General",
                                            "startPage": "233",
                                            "children": [],
                                            "endPage": "234"
                                        },
                                        {
                                            "id": 363,
                                            "level": "5",
                                            "title": "5.4.2 Socket-outlets",
                                            "startPage": "234",
                                            "children": [],
                                            "endPage": "234"
                                        },
                                        {
                                            "id": 364,
                                            "level": "5",
                                            "title": "5.4.3 Lighting points",
                                            "startPage": "234",
                                            "children": [],
                                            "endPage": "235"
                                        },
                                        {
                                            "id": 365,
                                            "level": "5",
                                            "title": "5.4.4 Luminaires",
                                            "startPage": "235",
                                            "children": [],
                                            "endPage": "235"
                                        },
                                        {
                                            "id": 366,
                                            "level": "5",
                                            "title": "5.4.5 Conductive supports for aerial conductors",
                                            "startPage": "235",
                                            "children": [],
                                            "endPage": "235"
                                        },
                                        {
                                            "id": 367,
                                            "level": "5",
                                            "title": "5.4.6 Structural metalwork including conductive building materials",
                                            "startPage": "235",
                                            "children": [],
                                            "endPage": "236"
                                        },
                                        {
                                            "id": 368,
                                            "level": "5",
                                            "title": "5.4.7 Submersible pumps",
                                            "startPage": "236",
                                            "children": [],
                                            "endPage": "236"
                                        },
                                        {
                                            "id": 369,
                                            "level": "5",
                                            "title": "5.4.8 Variable frequency devices",
                                            "startPage": "236",
                                            "children": [],
                                            "endPage": "237"
                                        }
                                    ],
                                    "endPage": "237"
                                },
                                {
                                    "id": 370,
                                    "level": "4",
                                    "title": "5.5 EARTHING ARRANGEMENTS",
                                    "startPage": "237",
                                    "children": [
                                        {
                                            "id": 371,
                                            "level": "5",
                                            "title": "5.5.1 Main earthing conductor",
                                            "startPage": "237",
                                            "children": [],
                                            "endPage": "238"
                                        },
                                        {
                                            "id": 372,
                                            "level": "5",
                                            "title": "5.5.2 Protective earthing conductors",
                                            "startPage": "238",
                                            "children": [],
                                            "endPage": "240"
                                        },
                                        {
                                            "id": 373,
                                            "level": "5",
                                            "title": "5.5.3 Particular methods of earthing",
                                            "startPage": "240",
                                            "children": [],
                                            "endPage": "243"
                                        },
                                        {
                                            "id": 374,
                                            "level": "5",
                                            "title": "5.5.4 Continuity",
                                            "startPage": "243",
                                            "children": [],
                                            "endPage": "243"
                                        },
                                        {
                                            "id": 375,
                                            "level": "5",
                                            "title": "5.5.5 Installation",
                                            "startPage": "243",
                                            "children": [],
                                            "endPage": "244"
                                        },
                                        {
                                            "id": 376,
                                            "level": "5",
                                            "title": "5.5.6 Connections",
                                            "startPage": "244",
                                            "children": [],
                                            "endPage": "245"
                                        }
                                    ],
                                    "endPage": "245"
                                },
                                {
                                    "id": 377,
                                    "level": "4",
                                    "title": "5.6 EQUIPOTENTIAL BONDING",
                                    "startPage": "245",
                                    "children": [
                                        {
                                            "id": 378,
                                            "level": "5",
                                            "title": "5.6.1 General",
                                            "startPage": "245",
                                            "children": [],
                                            "endPage": "246"
                                        },
                                        {
                                            "id": 379,
                                            "level": "5",
                                            "title": "5.6.2 Arrangement",
                                            "startPage": "246",
                                            "children": [],
                                            "endPage": "252"
                                        },
                                        {
                                            "id": 380,
                                            "level": "5",
                                            "title": "5.6.3 Bonding conductors",
                                            "startPage": "252",
                                            "children": [],
                                            "endPage": "253"
                                        }
                                    ],
                                    "endPage": "253"
                                },
                                {
                                    "id": 381,
                                    "level": "4",
                                    "title": "5.7 EARTH FAULT-LOOP IMPEDANCE",
                                    "startPage": "253",
                                    "children": [
                                        {
                                            "id": 382,
                                            "level": "5",
                                            "title": "5.7.1 General",
                                            "startPage": "253",
                                            "children": [],
                                            "endPage": "253"
                                        },
                                        {
                                            "id": 383,
                                            "level": "5",
                                            "title": "5.7.2 Disconnection times",
                                            "startPage": "253",
                                            "children": [],
                                            "endPage": "253"
                                        },
                                        {
                                            "id": 384,
                                            "level": "5",
                                            "title": "5.7.3 Earth fault-loop",
                                            "startPage": "253",
                                            "children": [],
                                            "endPage": "254"
                                        },
                                        {
                                            "id": 385,
                                            "level": "5",
                                            "title": "5.7.4 Impedance",
                                            "startPage": "254",
                                            "children": [],
                                            "endPage": "254"
                                        },
                                        {
                                            "id": 386,
                                            "level": "5",
                                            "title": "5.7.5 Supplementary equipotential bonding",
                                            "startPage": "254",
                                            "children": [],
                                            "endPage": "255"
                                        }
                                    ],
                                    "endPage": "255"
                                },
                                {
                                    "id": 387,
                                    "level": "4",
                                    "title": "5.8 OTHER EARTHING ARRANGEMENTS",
                                    "startPage": "255",
                                    "children": [],
                                    "endPage": "256"
                                }
                            ],
                            "endPage": "256"
                        },
                        {
                            "id": 388,
                            "level": "3",
                            "title": "SECTION 6 DAMP SITUATIONS",
                            "startPage": "256",
                            "children": [
                                {
                                    "id": 389,
                                    "level": "4",
                                    "title": "6.1 GENERAL",
                                    "startPage": "256",
                                    "children": [
                                        {
                                            "id": 390,
                                            "level": "5",
                                            "title": "6.1.1 Application",
                                            "startPage": "256",
                                            "children": [],
                                            "endPage": "256"
                                        },
                                        {
                                            "id": 391,
                                            "level": "5",
                                            "title": "6.1.2 Selection and installation",
                                            "startPage": "256",
                                            "children": [],
                                            "endPage": "257"
                                        }
                                    ],
                                    "endPage": "257"
                                },
                                {
                                    "id": 392,
                                    "level": "4",
                                    "title": "6.2 BATHS, SHOWERS AND OTHER FIXED WATER CONTAINERS",
                                    "startPage": "257",
                                    "children": [
                                        {
                                            "id": 393,
                                            "level": "5",
                                            "title": "6.2.1 Scope",
                                            "startPage": "257",
                                            "children": [],
                                            "endPage": "257"
                                        },
                                        {
                                            "id": 394,
                                            "level": "5",
                                            "title": "6.2.2 Classification of zones",
                                            "startPage": "257",
                                            "children": [],
                                            "endPage": "259"
                                        },
                                        {
                                            "id": 395,
                                            "level": "5",
                                            "title": "6.2.3 Protection against electric shock&#8212;Prohibited measures",
                                            "startPage": "259",
                                            "children": [],
                                            "endPage": "259"
                                        },
                                        {
                                            "id": 396,
                                            "level": "5",
                                            "title": "6.2.4 Selection and installation of electrical equipment",
                                            "startPage": "259",
                                            "children": [],
                                            "endPage": "273"
                                        }
                                    ],
                                    "endPage": "273"
                                },
                                {
                                    "id": 397,
                                    "level": "4",
                                    "title": "6.3 SWIMMING POOLS, PADDLING POOLS AND SPA POOLS OR TUBS",
                                    "startPage": "273",
                                    "children": [
                                        {
                                            "id": 398,
                                            "level": "5",
                                            "title": "6.3.1 Scope",
                                            "startPage": "273",
                                            "children": [],
                                            "endPage": "273"
                                        },
                                        {
                                            "id": 399,
                                            "level": "5",
                                            "title": "6.3.2 Classification of zones",
                                            "startPage": "273",
                                            "children": [],
                                            "endPage": "274"
                                        },
                                        {
                                            "id": 400,
                                            "level": "5",
                                            "title": "6.3.3 Protection against electric shock",
                                            "startPage": "274",
                                            "children": [],
                                            "endPage": "275"
                                        },
                                        {
                                            "id": 401,
                                            "level": "5",
                                            "title": "6.3.4 Selection and installation of electrical equipment",
                                            "startPage": "275",
                                            "children": [],
                                            "endPage": "283"
                                        }
                                    ],
                                    "endPage": "283"
                                },
                                {
                                    "id": 402,
                                    "level": "4",
                                    "title": "6.4 FOUNTAINS AND WATER FEATURES",
                                    "startPage": "283",
                                    "children": [
                                        {
                                            "id": 403,
                                            "level": "5",
                                            "title": "6.4.1 Scope",
                                            "startPage": "283",
                                            "children": [],
                                            "endPage": "284"
                                        },
                                        {
                                            "id": 404,
                                            "level": "5",
                                            "title": "6.4.2 Classification of zones",
                                            "startPage": "284",
                                            "children": [],
                                            "endPage": "284"
                                        },
                                        {
                                            "id": 405,
                                            "level": "5",
                                            "title": "6.4.3 Protection against electric shock",
                                            "startPage": "284",
                                            "children": [],
                                            "endPage": "285"
                                        },
                                        {
                                            "id": 406,
                                            "level": "5",
                                            "title": "6.4.4 Selection and installation of electrical equipment",
                                            "startPage": "285",
                                            "children": [],
                                            "endPage": "288"
                                        }
                                    ],
                                    "endPage": "288"
                                },
                                {
                                    "id": 407,
                                    "level": "4",
                                    "title": "6.5 SAUNAS",
                                    "startPage": "288",
                                    "children": [
                                        {
                                            "id": 408,
                                            "level": "5",
                                            "title": "6.5.1 Scope",
                                            "startPage": "288",
                                            "children": [],
                                            "endPage": "288"
                                        },
                                        {
                                            "id": 409,
                                            "level": "5",
                                            "title": "6.5.2 Classification of zones",
                                            "startPage": "288",
                                            "children": [],
                                            "endPage": "289"
                                        },
                                        {
                                            "id": 410,
                                            "level": "5",
                                            "title": "6.5.3 Protection against electric shock",
                                            "startPage": "289",
                                            "children": [],
                                            "endPage": "289"
                                        },
                                        {
                                            "id": 411,
                                            "level": "5",
                                            "title": "6.5.4 Selection and installation of electrical equipment",
                                            "startPage": "289",
                                            "children": [],
                                            "endPage": "292"
                                        }
                                    ],
                                    "endPage": "292"
                                },
                                {
                                    "id": 412,
                                    "level": "4",
                                    "title": "6.6 REFRIGERATION ROOMS",
                                    "startPage": "292",
                                    "children": [
                                        {
                                            "id": 413,
                                            "level": "5",
                                            "title": "6.6.1 Scope",
                                            "startPage": "292",
                                            "children": [],
                                            "endPage": "292"
                                        },
                                        {
                                            "id": 414,
                                            "level": "5",
                                            "title": "6.6.2 Classification of zone",
                                            "startPage": "292",
                                            "children": [],
                                            "endPage": "292"
                                        },
                                        {
                                            "id": 415,
                                            "level": "5",
                                            "title": "6.6.3 Protection against electric shock",
                                            "startPage": "292",
                                            "children": [],
                                            "endPage": "292"
                                        },
                                        {
                                            "id": 416,
                                            "level": "5",
                                            "title": "6.6.4 Selection and installation of electrical equipment",
                                            "startPage": "292",
                                            "children": [],
                                            "endPage": "294"
                                        }
                                    ],
                                    "endPage": "294"
                                },
                                {
                                    "id": 417,
                                    "level": "4",
                                    "title": "6.7 SANITIZATION AND GENERAL HOSING-DOWN OPERATIONS",
                                    "startPage": "294",
                                    "children": [
                                        {
                                            "id": 418,
                                            "level": "5",
                                            "title": "6.7.1 Scope",
                                            "startPage": "294",
                                            "children": [],
                                            "endPage": "294"
                                        },
                                        {
                                            "id": 419,
                                            "level": "5",
                                            "title": "6.7.2 Classification of zone",
                                            "startPage": "294",
                                            "children": [],
                                            "endPage": "295"
                                        },
                                        {
                                            "id": 420,
                                            "level": "5",
                                            "title": "6.7.3 Protection against electric shock",
                                            "startPage": "295",
                                            "children": [],
                                            "endPage": "295"
                                        },
                                        {
                                            "id": 421,
                                            "level": "5",
                                            "title": "6.7.4 Selection and installation of electrical equipment",
                                            "startPage": "295",
                                            "children": [],
                                            "endPage": "296"
                                        }
                                    ],
                                    "endPage": "296"
                                }
                            ],
                            "endPage": "296"
                        },
                        {
                            "id": 422,
                            "level": "3",
                            "title": "SECTION 7 SPECIAL ELECTRICAL INSTALLATIONS",
                            "startPage": "296",
                            "children": [
                                {
                                    "id": 423,
                                    "level": "4",
                                    "title": "7.1 GENERAL",
                                    "startPage": "296",
                                    "children": [
                                        {
                                            "id": 424,
                                            "level": "5",
                                            "title": "7.1.1 Application",
                                            "startPage": "296",
                                            "children": [],
                                            "endPage": "296"
                                        },
                                        {
                                            "id": 425,
                                            "level": "5",
                                            "title": "7.1.2 Selection and installation",
                                            "startPage": "296",
                                            "children": [],
                                            "endPage": "297"
                                        }
                                    ],
                                    "endPage": "297"
                                },
                                {
                                    "id": 426,
                                    "level": "4",
                                    "title": "7.2 SAFETY SERVICES",
                                    "startPage": "297",
                                    "children": [
                                        {
                                            "id": 427,
                                            "level": "5",
                                            "title": "7.2.1 Scope",
                                            "startPage": "297",
                                            "children": [],
                                            "endPage": "299"
                                        },
                                        {
                                            "id": 428,
                                            "level": "5",
                                            "title": "7.2.2 Supply systems",
                                            "startPage": "299",
                                            "children": [],
                                            "endPage": "300"
                                        },
                                        {
                                            "id": 429,
                                            "level": "5",
                                            "title": "7.2.3 Main switches",
                                            "startPage": "300",
                                            "children": [],
                                            "endPage": "301"
                                        },
                                        {
                                            "id": 430,
                                            "level": "5",
                                            "title": "7.2.4 Arrangement",
                                            "startPage": "301",
                                            "children": [],
                                            "endPage": "302"
                                        },
                                        {
                                            "id": 431,
                                            "level": "5",
                                            "title": "7.2.5 Mechanical protection",
                                            "startPage": "302",
                                            "children": [],
                                            "endPage": "302"
                                        },
                                        {
                                            "id": 432,
                                            "level": "5",
                                            "title": "7.2.6 Identification",
                                            "startPage": "302",
                                            "children": [],
                                            "endPage": "304"
                                        },
                                        {
                                            "id": 433,
                                            "level": "5",
                                            "title": "7.2.7 Wiring systems",
                                            "startPage": "304",
                                            "children": [],
                                            "endPage": "305"
                                        },
                                        {
                                            "id": 434,
                                            "level": "5",
                                            "title": "7.2.8 Segregation",
                                            "startPage": "305",
                                            "children": [],
                                            "endPage": "306"
                                        },
                                        {
                                            "id": 435,
                                            "level": "5",
                                            "title": "7.2.9 Fire-pump motors",
                                            "startPage": "306",
                                            "children": [],
                                            "endPage": "307"
                                        }
                                    ],
                                    "endPage": "307"
                                },
                                {
                                    "id": 436,
                                    "level": "4",
                                    "title": "7.3 ELECTRICITY GENERATION SYSTEMS",
                                    "startPage": "307",
                                    "children": [
                                        {
                                            "id": 437,
                                            "level": "5",
                                            "title": "7.3.1 General",
                                            "startPage": "307",
                                            "children": [],
                                            "endPage": "308"
                                        },
                                        {
                                            "id": 438,
                                            "level": "5",
                                            "title": "7.3.2 Selection and installation of system",
                                            "startPage": "308",
                                            "children": [],
                                            "endPage": "308"
                                        },
                                        {
                                            "id": 439,
                                            "level": "5",
                                            "title": "7.3.3 Control",
                                            "startPage": "308",
                                            "children": [],
                                            "endPage": "309"
                                        },
                                        {
                                            "id": 440,
                                            "level": "5",
                                            "title": "7.3.4 Isolation",
                                            "startPage": "309",
                                            "children": [],
                                            "endPage": "309"
                                        },
                                        {
                                            "id": 441,
                                            "level": "5",
                                            "title": "7.3.5 Overcurrent protection",
                                            "startPage": "309",
                                            "children": [],
                                            "endPage": "311"
                                        },
                                        {
                                            "id": 442,
                                            "level": "5",
                                            "title": "7.3.6 Earthing",
                                            "startPage": "311",
                                            "children": [],
                                            "endPage": "311"
                                        },
                                        {
                                            "id": 443,
                                            "level": "5",
                                            "title": "7.3.7 Connected electrical equipment",
                                            "startPage": "311",
                                            "children": [],
                                            "endPage": "311"
                                        },
                                        {
                                            "id": 444,
                                            "level": "5",
                                            "title": "7.3.8 Connection to electrical installation",
                                            "startPage": "311",
                                            "children": [],
                                            "endPage": "318"
                                        }
                                    ],
                                    "endPage": "318"
                                },
                                {
                                    "id": 445,
                                    "level": "4",
                                    "title": "7.4 ELECTRICAL SEPARATION (ISOLATED SUPPLY)",
                                    "startPage": "318",
                                    "children": [
                                        {
                                            "id": 446,
                                            "level": "5",
                                            "title": "7.4.1 Scope",
                                            "startPage": "318",
                                            "children": [],
                                            "endPage": "319"
                                        },
                                        {
                                            "id": 447,
                                            "level": "5",
                                            "title": "7.4.2 Source of supply",
                                            "startPage": "319",
                                            "children": [],
                                            "endPage": "319"
                                        },
                                        {
                                            "id": 448,
                                            "level": "5",
                                            "title": "7.4.3 Arrangement of circuits",
                                            "startPage": "319",
                                            "children": [],
                                            "endPage": "319"
                                        },
                                        {
                                            "id": 449,
                                            "level": "5",
                                            "title": "7.4.4 Switching devices",
                                            "startPage": "319",
                                            "children": [],
                                            "endPage": "320"
                                        },
                                        {
                                            "id": 450,
                                            "level": "5",
                                            "title": "7.4.5 Single item of electrical equipment",
                                            "startPage": "320",
                                            "children": [],
                                            "endPage": "320"
                                        },
                                        {
                                            "id": 451,
                                            "level": "5",
                                            "title": "7.4.6 Multiple items of electrical equipment",
                                            "startPage": "320",
                                            "children": [],
                                            "endPage": "320"
                                        },
                                        {
                                            "id": 452,
                                            "level": "5",
                                            "title": "7.4.7 Testing",
                                            "startPage": "320",
                                            "children": [],
                                            "endPage": "323"
                                        }
                                    ],
                                    "endPage": "323"
                                },
                                {
                                    "id": 453,
                                    "level": "4",
                                    "title": "7.5 EXTRA-LOW VOLTAGE ELECTRICAL INSTALLATIONS",
                                    "startPage": "323",
                                    "children": [
                                        {
                                            "id": 454,
                                            "level": "5",
                                            "title": "7.5.1 Scope",
                                            "startPage": "323",
                                            "children": [],
                                            "endPage": "323"
                                        },
                                        {
                                            "id": 455,
                                            "level": "5",
                                            "title": "7.5.2 Compliance with other Sections",
                                            "startPage": "323",
                                            "children": [],
                                            "endPage": "323"
                                        },
                                        {
                                            "id": 456,
                                            "level": "5",
                                            "title": "7.5.3 Sources of supply by SELV and PELV",
                                            "startPage": "323",
                                            "children": [],
                                            "endPage": "324"
                                        },
                                        {
                                            "id": 457,
                                            "level": "5",
                                            "title": "7.5.4 Separation requirements for SELV and PELV circuits",
                                            "startPage": "324",
                                            "children": [],
                                            "endPage": "324"
                                        },
                                        {
                                            "id": 458,
                                            "level": "5",
                                            "title": "7.5.5 Arrangement of SELV circuits",
                                            "startPage": "324",
                                            "children": [],
                                            "endPage": "325"
                                        },
                                        {
                                            "id": 459,
                                            "level": "5",
                                            "title": "7.5.6 Arrangement of PELV circuits",
                                            "startPage": "325",
                                            "children": [],
                                            "endPage": "325"
                                        },
                                        {
                                            "id": 460,
                                            "level": "5",
                                            "title": "7.5.7 Voltage drop in conductors",
                                            "startPage": "325",
                                            "children": [],
                                            "endPage": "325"
                                        },
                                        {
                                            "id": 461,
                                            "level": "5",
                                            "title": "7.5.8 Control of an electrical installation",
                                            "startPage": "325",
                                            "children": [],
                                            "endPage": "326"
                                        },
                                        {
                                            "id": 462,
                                            "level": "5",
                                            "title": "7.5.9 Overcurrent protection",
                                            "startPage": "326",
                                            "children": [],
                                            "endPage": "326"
                                        },
                                        {
                                            "id": 463,
                                            "level": "5",
                                            "title": "7.5.10 Connecting devices",
                                            "startPage": "326",
                                            "children": [],
                                            "endPage": "327"
                                        },
                                        {
                                            "id": 464,
                                            "level": "5",
                                            "title": "7.5.11 Wiring systems",
                                            "startPage": "327",
                                            "children": [],
                                            "endPage": "327"
                                        },
                                        {
                                            "id": 465,
                                            "level": "5",
                                            "title": "7.5.12 Testing",
                                            "startPage": "327",
                                            "children": [],
                                            "endPage": "327"
                                        }
                                    ],
                                    "endPage": "327"
                                },
                                {
                                    "id": 466,
                                    "level": "4",
                                    "title": "7.6 HIGH VOLTAGE ELECTRICAL INSTALLATIONS",
                                    "startPage": "327",
                                    "children": [
                                        {
                                            "id": 467,
                                            "level": "5",
                                            "title": "7.6.1 Scope",
                                            "startPage": "327",
                                            "children": [],
                                            "endPage": "328"
                                        },
                                        {
                                            "id": 468,
                                            "level": "5",
                                            "title": "7.6.2 Application",
                                            "startPage": "328",
                                            "children": [],
                                            "endPage": "328"
                                        },
                                        {
                                            "id": 469,
                                            "level": "5",
                                            "title": "7.6.3 Issues relevant to high voltage installations",
                                            "startPage": "328",
                                            "children": [],
                                            "endPage": "329"
                                        }
                                    ],
                                    "endPage": "329"
                                },
                                {
                                    "id": 470,
                                    "level": "4",
                                    "title": "7.7 HAZARDOUS AREAS (EXPLOSIVE GAS OR COMBUSTIBLE DUSTS)",
                                    "startPage": "329",
                                    "children": [
                                        {
                                            "id": 471,
                                            "level": "5",
                                            "title": "7.7.1 Scope",
                                            "startPage": "329",
                                            "children": [],
                                            "endPage": "329"
                                        },
                                        {
                                            "id": 472,
                                            "level": "5",
                                            "title": "7.7.2 Classification of hazardous areas",
                                            "startPage": "329",
                                            "children": [],
                                            "endPage": "330"
                                        }
                                    ],
                                    "endPage": "330"
                                },
                                {
                                    "id": 473,
                                    "level": "4",
                                    "title": "7.8 SPECIFIC ELECTRICAL INSTALLATION STANDARDS",
                                    "startPage": "330",
                                    "children": [
                                        {
                                            "id": 474,
                                            "level": "5",
                                            "title": "7.8.1 Scope",
                                            "startPage": "330",
                                            "children": [],
                                            "endPage": "330"
                                        },
                                        {
                                            "id": 475,
                                            "level": "5",
                                            "title": "7.8.2 Additional requirements of this Standard",
                                            "startPage": "330",
                                            "children": [],
                                            "endPage": "331"
                                        },
                                        {
                                            "id": 476,
                                            "level": "5",
                                            "title": "7.8.3 Deemed to comply Standards",
                                            "startPage": "331",
                                            "children": [],
                                            "endPage": "331"
                                        },
                                        {
                                            "id": 477,
                                            "level": "5",
                                            "title": "7.8.4 Specific electrical installation guidance",
                                            "startPage": "331",
                                            "children": [],
                                            "endPage": "333"
                                        }
                                    ],
                                    "endPage": "333"
                                }
                            ],
                            "endPage": "333"
                        },
                        {
                            "id": 478,
                            "level": "3",
                            "title": "SECTION 8 VERIFICATION",
                            "startPage": "333",
                            "children": [
                                {
                                    "id": 479,
                                    "level": "4",
                                    "title": "8.1 GENERAL",
                                    "startPage": "333",
                                    "children": [
                                        {
                                            "id": 480,
                                            "level": "5",
                                            "title": "8.1.1 Application",
                                            "startPage": "333",
                                            "children": [],
                                            "endPage": "333"
                                        },
                                        {
                                            "id": 481,
                                            "level": "5",
                                            "title": "8.1.2 Requirements",
                                            "startPage": "333",
                                            "children": [],
                                            "endPage": "334"
                                        }
                                    ],
                                    "endPage": "334"
                                },
                                {
                                    "id": 482,
                                    "level": "4",
                                    "title": "8.2 VISUAL INSPECTION",
                                    "startPage": "334",
                                    "children": [
                                        {
                                            "id": 483,
                                            "level": "5",
                                            "title": "8.2.1 General",
                                            "startPage": "334",
                                            "children": [],
                                            "endPage": "334"
                                        },
                                        {
                                            "id": 484,
                                            "level": "5",
                                            "title": "8.2.2 Checklist",
                                            "startPage": "334",
                                            "children": [],
                                            "endPage": "336"
                                        }
                                    ],
                                    "endPage": "336"
                                },
                                {
                                    "id": 485,
                                    "level": "4",
                                    "title": "8.3 TESTING",
                                    "startPage": "336",
                                    "children": [
                                        {
                                            "id": 486,
                                            "level": "5",
                                            "title": "8.3.1 General",
                                            "startPage": "336",
                                            "children": [],
                                            "endPage": "336"
                                        },
                                        {
                                            "id": 487,
                                            "level": "5",
                                            "title": "8.3.2 Application of AS\/NZS 3017",
                                            "startPage": "336",
                                            "children": [],
                                            "endPage": "336"
                                        },
                                        {
                                            "id": 488,
                                            "level": "5",
                                            "title": "8.3.3 Mandatory tests",
                                            "startPage": "336",
                                            "children": [],
                                            "endPage": "337"
                                        },
                                        {
                                            "id": 489,
                                            "level": "5",
                                            "title": "8.3.4 Sequence of tests",
                                            "startPage": "337",
                                            "children": [],
                                            "endPage": "337"
                                        },
                                        {
                                            "id": 490,
                                            "level": "5",
                                            "title": "8.3.5 Continuity of the earthing system",
                                            "startPage": "337",
                                            "children": [],
                                            "endPage": "338"
                                        },
                                        {
                                            "id": 491,
                                            "level": "5",
                                            "title": "8.3.6 Insulation resistance",
                                            "startPage": "338",
                                            "children": [],
                                            "endPage": "339"
                                        },
                                        {
                                            "id": 492,
                                            "level": "5",
                                            "title": "8.3.7 Polarity",
                                            "startPage": "339",
                                            "children": [],
                                            "endPage": "340"
                                        },
                                        {
                                            "id": 493,
                                            "level": "5",
                                            "title": "8.3.8 Correct circuit connections",
                                            "startPage": "340",
                                            "children": [],
                                            "endPage": "341"
                                        },
                                        {
                                            "id": 494,
                                            "level": "5",
                                            "title": "8.3.9 Verification of impedance (earth fault-loop impedance)",
                                            "startPage": "341",
                                            "children": [],
                                            "endPage": "344"
                                        },
                                        {
                                            "id": 495,
                                            "level": "5",
                                            "title": "8.3.10 Operation of RCDs",
                                            "startPage": "344",
                                            "children": [],
                                            "endPage": "345"
                                        }
                                    ],
                                    "endPage": "345"
                                },
                                {
                                    "id": 496,
                                    "level": "4",
                                    "title": "8.4 DATE OF INITIAL ENERGIZATION OF AN INSTALLATION",
                                    "startPage": "345",
                                    "children": [],
                                    "endPage": "346"
                                }
                            ],
                            "endPage": "346"
                        }
                    ],
                    "endPage": "346"
                },
                {
                    "id": 497,
                    "level": "2",
                    "title": "APPENDIX A - REFERENCED DOCUMENTS",
                    "startPage": "346",
                    "children": [
                        {
                            "id": 498,
                            "level": "3",
                            "title": "A1 NORMATIVE REFERENCES",
                            "startPage": "346",
                            "children": [],
                            "endPage": "350"
                        },
                        {
                            "id": 499,
                            "level": "3",
                            "title": "A2 INFORMATIVE REFERENCES",
                            "startPage": "350",
                            "children": [],
                            "endPage": "354"
                        }
                    ],
                    "endPage": "354"
                },
                {
                    "id": 500,
                    "level": "2",
                    "title": "APPENDIX B - CIRCUIT PROTECTION GUIDE",
                    "startPage": "354",
                    "children": [
                        {
                            "id": 501,
                            "level": "3",
                            "title": "B1 SCOPE",
                            "startPage": "354",
                            "children": [],
                            "endPage": "354"
                        },
                        {
                            "id": 502,
                            "level": "3",
                            "title": "B2 CIRCUIT ARRANGEMENTS",
                            "startPage": "354",
                            "children": [],
                            "endPage": "356"
                        },
                        {
                            "id": 503,
                            "level": "3",
                            "title": "B3 PROTECTION AGAINST OVERCURRENT",
                            "startPage": "356",
                            "children": [
                                {
                                    "id": 504,
                                    "level": "4",
                                    "title": "B3.1 General",
                                    "startPage": "356",
                                    "children": [],
                                    "endPage": "356"
                                },
                                {
                                    "id": 505,
                                    "level": "4",
                                    "title": "B3.2 Coordination between conductors and overload protective devices",
                                    "startPage": "356",
                                    "children": [],
                                    "endPage": "358"
                                }
                            ],
                            "endPage": "358"
                        },
                        {
                            "id": 506,
                            "level": "3",
                            "title": "B4 PROTECTION BY AUTOMATIC DISCONNECTION OF SUPPLY",
                            "startPage": "358",
                            "children": [
                                {
                                    "id": 507,
                                    "level": "4",
                                    "title": "B4.1 Application",
                                    "startPage": "358",
                                    "children": [],
                                    "endPage": "358"
                                },
                                {
                                    "id": 508,
                                    "level": "4",
                                    "title": "B4.2 Principle",
                                    "startPage": "358",
                                    "children": [],
                                    "endPage": "359"
                                },
                                {
                                    "id": 509,
                                    "level": "4",
                                    "title": "B4.3 Disconnection times",
                                    "startPage": "359",
                                    "children": [],
                                    "endPage": "362"
                                },
                                {
                                    "id": 510,
                                    "level": "4",
                                    "title": "B4.4 The earth fault-loop",
                                    "startPage": "362",
                                    "children": [],
                                    "endPage": "363"
                                },
                                {
                                    "id": 511,
                                    "level": "4",
                                    "title": "B4.5 Calculation of earth fault-loop impedance",
                                    "startPage": "363",
                                    "children": [],
                                    "endPage": "364"
                                },
                                {
                                    "id": 512,
                                    "level": "4",
                                    "title": "B4.6 Earth fault-loop impedance measurement",
                                    "startPage": "364",
                                    "children": [],
                                    "endPage": "365"
                                }
                            ],
                            "endPage": "365"
                        },
                        {
                            "id": 513,
                            "level": "3",
                            "title": "B5 MAXIMUM CIRCUIT LENGTHS",
                            "startPage": "365",
                            "children": [
                                {
                                    "id": 514,
                                    "level": "4",
                                    "title": "B5.1 General",
                                    "startPage": "365",
                                    "children": [],
                                    "endPage": "365"
                                },
                                {
                                    "id": 515,
                                    "level": "4",
                                    "title": "B5.2 Calculation of maximum length of circuit",
                                    "startPage": "365",
                                    "children": [],
                                    "endPage": "369"
                                }
                            ],
                            "endPage": "369"
                        }
                    ],
                    "endPage": "369"
                },
                {
                    "id": 516,
                    "level": "2",
                    "title": "APPENDIX C - CIRCUIT ARRANGEMENTS",
                    "startPage": "369",
                    "children": [
                        {
                            "id": 517,
                            "level": "3",
                            "title": "C1 SCOPE",
                            "startPage": "369",
                            "children": [],
                            "endPage": "369"
                        },
                        {
                            "id": 518,
                            "level": "3",
                            "title": "C2 MAXIMUM DEMAND",
                            "startPage": "369",
                            "children": [
                                {
                                    "id": 519,
                                    "level": "4",
                                    "title": "C2.1 After diversity maximum demand",
                                    "startPage": "369",
                                    "children": [],
                                    "endPage": "370"
                                },
                                {
                                    "id": 520,
                                    "level": "4",
                                    "title": "C2.2 Calculation of maximum demand in consumers mains and submains",
                                    "startPage": "370",
                                    "children": [],
                                    "endPage": "370"
                                },
                                {
                                    "id": 521,
                                    "level": "4",
                                    "title": "C2.3 Domestic installations",
                                    "startPage": "370",
                                    "children": [],
                                    "endPage": "379"
                                },
                                {
                                    "id": 522,
                                    "level": "4",
                                    "title": "C2.4 Non-domestic Installations",
                                    "startPage": "379",
                                    "children": [],
                                    "endPage": "387"
                                },
                                {
                                    "id": 523,
                                    "level": "4",
                                    "title": "C2.5 Maximum demand in final subcircuits",
                                    "startPage": "387",
                                    "children": [],
                                    "endPage": "390"
                                }
                            ],
                            "endPage": "390"
                        },
                        {
                            "id": 524,
                            "level": "3",
                            "title": "C3 SIMPLIFIED PROTECTIVE DEVICE SELECTION",
                            "startPage": "390",
                            "children": [
                                {
                                    "id": 525,
                                    "level": "4",
                                    "title": "C3.1 Background",
                                    "startPage": "390",
                                    "children": [],
                                    "endPage": "393"
                                }
                            ],
                            "endPage": "393"
                        },
                        {
                            "id": 526,
                            "level": "3",
                            "title": "C4 SIMPLIFIED VOLTAGE DROP",
                            "startPage": "393",
                            "children": [
                                {
                                    "id": 527,
                                    "level": "4",
                                    "title": "C4.1 Background",
                                    "startPage": "393",
                                    "children": [],
                                    "endPage": "395"
                                },
                                {
                                    "id": 528,
                                    "level": "4",
                                    "title": "C4.2 Examples",
                                    "startPage": "395",
                                    "children": [],
                                    "endPage": "395"
                                }
                            ],
                            "endPage": "395"
                        },
                        {
                            "id": 529,
                            "level": "3",
                            "title": "C5 NUMBER OF POINTS CONNECTED TO CIRCUITS",
                            "startPage": "395",
                            "children": [
                                {
                                    "id": 530,
                                    "level": "4",
                                    "title": "C5.1 Number of Circuits",
                                    "startPage": "395",
                                    "children": [],
                                    "endPage": "396"
                                },
                                {
                                    "id": 531,
                                    "level": "4",
                                    "title": "C5.2 Final Subcircuits",
                                    "startPage": "396",
                                    "children": [],
                                    "endPage": "400"
                                }
                            ],
                            "endPage": "400"
                        },
                        {
                            "id": 532,
                            "level": "3",
                            "title": "C6 GUIDE TO NUMBER OF CABLES INSTALLED IN CONDUITS",
                            "startPage": "400",
                            "children": [
                                {
                                    "id": 533,
                                    "level": "4",
                                    "title": "C6.1 Basis of calculations",
                                    "startPage": "400",
                                    "children": [],
                                    "endPage": "400"
                                },
                                {
                                    "id": 534,
                                    "level": "4",
                                    "title": "C6.2 Application notes",
                                    "startPage": "400",
                                    "children": [],
                                    "endPage": "404"
                                }
                            ],
                            "endPage": "404"
                        }
                    ],
                    "endPage": "404"
                },
                {
                    "id": 535,
                    "level": "2",
                    "title": "APPENDIX D - MINIMUM SIZES OF POSTS, POLES AND STRUTS FOR AERIAL LINE CONDUCTORS",
                    "startPage": "404",
                    "children": [
                        {
                            "id": 536,
                            "level": "3",
                            "title": "D1 GENERAL",
                            "startPage": "404",
                            "children": [],
                            "endPage": "405"
                        },
                        {
                            "id": 537,
                            "level": "3",
                            "title": "D2 GENERAL REQUIREMENTS FOR POSTS AND POLES",
                            "startPage": "405",
                            "children": [
                                {
                                    "id": 538,
                                    "level": "4",
                                    "title": "D2.1 Timber posts\/poles",
                                    "startPage": "406",
                                    "children": [],
                                    "endPage": "406"
                                },
                                {
                                    "id": 539,
                                    "level": "4",
                                    "title": "D2.2 Steel-pipe posts\/poles",
                                    "startPage": "406",
                                    "children": [],
                                    "endPage": "407"
                                }
                            ],
                            "endPage": "407"
                        },
                        {
                            "id": 540,
                            "level": "3",
                            "title": "D3 ATTACHMENTS TO BUILDINGS OR STRUCTURES",
                            "startPage": "407",
                            "children": [
                                {
                                    "id": 541,
                                    "level": "4",
                                    "title": "D3.1 Responsibility for design",
                                    "startPage": "407",
                                    "children": [],
                                    "endPage": "407"
                                },
                                {
                                    "id": 542,
                                    "level": "4",
                                    "title": "D3.2 Horizontal and vertical struts",
                                    "startPage": "407",
                                    "children": [],
                                    "endPage": "408"
                                }
                            ],
                            "endPage": "408"
                        },
                        {
                            "id": 543,
                            "level": "3",
                            "title": "D4 EXAMPLE USING TABLES IN APPENDIX D TO DETERMINE POLE\/POST OR STRUT SIZE",
                            "startPage": "408",
                            "children": [
                                {
                                    "id": 544,
                                    "level": "4",
                                    "title": "D4.1 Problem",
                                    "startPage": "408",
                                    "children": [],
                                    "endPage": "408"
                                },
                                {
                                    "id": 545,
                                    "level": "4",
                                    "title": "D4.2 Solution",
                                    "startPage": "408",
                                    "children": [],
                                    "endPage": "423"
                                }
                            ],
                            "endPage": "423"
                        }
                    ],
                    "endPage": "423"
                },
                {
                    "id": 546,
                    "level": "2",
                    "title": "APPENDIX E - ELECTRICAL INSTALLATION REQUIREMENTS IN NATIONAL BUILDING CODES",
                    "startPage": "423",
                    "children": [
                        {
                            "id": 547,
                            "level": "3",
                            "title": "E1 SCOPE",
                            "startPage": "423",
                            "children": [],
                            "endPage": "423"
                        },
                        {
                            "id": 548,
                            "level": "3",
                            "title": "E2 AUSTRALIA",
                            "startPage": "423",
                            "children": [
                                {
                                    "id": 549,
                                    "level": "4",
                                    "title": "E2.1 General",
                                    "startPage": "423",
                                    "children": [],
                                    "endPage": "424"
                                },
                                {
                                    "id": 550,
                                    "level": "4",
                                    "title": "E2.2 Provisions in the NCC",
                                    "startPage": "424",
                                    "children": [],
                                    "endPage": "425"
                                }
                            ],
                            "endPage": "425"
                        },
                        {
                            "id": 551,
                            "level": "3",
                            "title": "E3 NEW ZEALAND",
                            "startPage": "425",
                            "children": [
                                {
                                    "id": 552,
                                    "level": "4",
                                    "title": "E3.1 General",
                                    "startPage": "425",
                                    "children": [],
                                    "endPage": "426"
                                },
                                {
                                    "id": 553,
                                    "level": "4",
                                    "title": "E3.2 Extent of electrical installations in the NZBC",
                                    "startPage": "426",
                                    "children": [],
                                    "endPage": "427"
                                },
                                {
                                    "id": 554,
                                    "level": "4",
                                    "title": "E3.3 Building consents",
                                    "startPage": "427",
                                    "children": [],
                                    "endPage": "428"
                                }
                            ],
                            "endPage": "428"
                        }
                    ],
                    "endPage": "428"
                },
                {
                    "id": 555,
                    "level": "2",
                    "title": "APPENDIX F - INSTALLATION OF SURGE PROTECTIVE DEVICES",
                    "startPage": "428",
                    "children": [
                        {
                            "id": 556,
                            "level": "3",
                            "title": "F1 SURGE PROTECTIVE DEVICES (SPDs)",
                            "startPage": "428",
                            "children": [
                                {
                                    "id": 557,
                                    "level": "4",
                                    "title": "F1.1 General",
                                    "startPage": "428",
                                    "children": [],
                                    "endPage": "428"
                                },
                                {
                                    "id": 558,
                                    "level": "4",
                                    "title": "F1.2 Selection and installation of SPDs",
                                    "startPage": "428",
                                    "children": [],
                                    "endPage": "430"
                                }
                            ],
                            "endPage": "430"
                        },
                        {
                            "id": 559,
                            "level": "3",
                            "title": "F2 SURGE PROTECTIVE EARTHING AND BONDING",
                            "startPage": "430",
                            "children": [],
                            "endPage": "433"
                        }
                    ],
                    "endPage": "433"
                },
                {
                    "id": 560,
                    "level": "2",
                    "title": "APPENDIX G - DEGREES OF PROTECTION OF ENCLOSED EQUIPMENT",
                    "startPage": "433",
                    "children": [
                        {
                            "id": 561,
                            "level": "3",
                            "title": "G1 INTRODUCTION",
                            "startPage": "433",
                            "children": [],
                            "endPage": "438"
                        }
                    ],
                    "endPage": "438"
                },
                {
                    "id": 562,
                    "level": "2",
                    "title": "APPENDIX H - WS CLASSIFICATION OF WIRING SYSTEMS",
                    "startPage": "438",
                    "children": [
                        {
                            "id": 563,
                            "level": "3",
                            "title": "H1 GENERAL",
                            "startPage": "438",
                            "children": [
                                {
                                    "id": 564,
                                    "level": "4",
                                    "title": "H1.1 Designation",
                                    "startPage": "438",
                                    "children": [],
                                    "endPage": "439"
                                },
                                {
                                    "id": 565,
                                    "level": "4",
                                    "title": "H1.2 First characteristic numeral",
                                    "startPage": "439",
                                    "children": [],
                                    "endPage": "439"
                                },
                                {
                                    "id": 566,
                                    "level": "4",
                                    "title": "H1.3 Second characteristic numeral",
                                    "startPage": "439",
                                    "children": [],
                                    "endPage": "440"
                                },
                                {
                                    "id": 567,
                                    "level": "4",
                                    "title": "H1.4 Supplementary letter W",
                                    "startPage": "440",
                                    "children": [],
                                    "endPage": "440"
                                },
                                {
                                    "id": 568,
                                    "level": "4",
                                    "title": "H1.5 Supply and installation",
                                    "startPage": "440",
                                    "children": [],
                                    "endPage": "440"
                                }
                            ],
                            "endPage": "440"
                        },
                        {
                            "id": 569,
                            "level": "3",
                            "title": "H2 PARTICULAR WIRING SYSTEM APPLICATIONS",
                            "startPage": "440",
                            "children": [
                                {
                                    "id": 570,
                                    "level": "4",
                                    "title": "H2.1 General",
                                    "startPage": "440",
                                    "children": [],
                                    "endPage": "441"
                                },
                                {
                                    "id": 571,
                                    "level": "4",
                                    "title": "H2.2 Pumpset systems",
                                    "startPage": "441",
                                    "children": [],
                                    "endPage": "441"
                                },
                                {
                                    "id": 572,
                                    "level": "4",
                                    "title": "H2.3 Smoke-venting equipment",
                                    "startPage": "441",
                                    "children": [],
                                    "endPage": "441"
                                },
                                {
                                    "id": 573,
                                    "level": "4",
                                    "title": "H2.4 Lift circuits",
                                    "startPage": "441",
                                    "children": [],
                                    "endPage": "441"
                                },
                                {
                                    "id": 574,
                                    "level": "4",
                                    "title": "H2.5 Central emergency lighting circuits",
                                    "startPage": "441",
                                    "children": [],
                                    "endPage": "441"
                                },
                                {
                                    "id": 575,
                                    "level": "4",
                                    "title": "H2.6 Substation or main switchboard",
                                    "startPage": "441",
                                    "children": [],
                                    "endPage": "442"
                                }
                            ],
                            "endPage": "442"
                        },
                        {
                            "id": 576,
                            "level": "3",
                            "title": "H3 FIRE-PROTECTED WIRING SYSTEMS",
                            "startPage": "442",
                            "children": [],
                            "endPage": "442"
                        },
                        {
                            "id": 577,
                            "level": "3",
                            "title": "H4 MECHANICALLY PROTECTED WIRING SYSTEMS",
                            "startPage": "442",
                            "children": [
                                {
                                    "id": 578,
                                    "level": "4",
                                    "title": "H4.1 General",
                                    "startPage": "442",
                                    "children": [],
                                    "endPage": "442"
                                },
                                {
                                    "id": 579,
                                    "level": "4",
                                    "title": "H4.2 Application of wiring systems",
                                    "startPage": "442",
                                    "children": [],
                                    "endPage": "443"
                                },
                                {
                                    "id": 580,
                                    "level": "4",
                                    "title": "H4.3 Unenclosed wiring systems",
                                    "startPage": "443",
                                    "children": [],
                                    "endPage": "443"
                                },
                                {
                                    "id": 581,
                                    "level": "4",
                                    "title": "H4.4 Enclosed wiring systems",
                                    "startPage": "443",
                                    "children": [],
                                    "endPage": "444"
                                }
                            ],
                            "endPage": "444"
                        },
                        {
                            "id": 582,
                            "level": "3",
                            "title": "H5 GUIDE TO MECHANICAL PROTECTION CLASSIFICATION",
                            "startPage": "444",
                            "children": [
                                {
                                    "id": 583,
                                    "level": "4",
                                    "title": "H5.1 WSX1 mechanical protection systems",
                                    "startPage": "444",
                                    "children": [],
                                    "endPage": "444"
                                },
                                {
                                    "id": 584,
                                    "level": "4",
                                    "title": "H5.2 WSX2 mechanical protection systems",
                                    "startPage": "444",
                                    "children": [],
                                    "endPage": "445"
                                },
                                {
                                    "id": 585,
                                    "level": "4",
                                    "title": "H5.3 WSX3 mechanical protection systems",
                                    "startPage": "445",
                                    "children": [],
                                    "endPage": "446"
                                }
                            ],
                            "endPage": "446"
                        }
                    ],
                    "endPage": "446"
                },
                {
                    "id": 586,
                    "level": "2",
                    "title": "APPENDIX I - PROTECTIVE DEVICE RATINGS AND METRIC EQUIVALENT SIZES FOR IMPERIAL CABLES USED IN ALTERATIONS ADDITIONS AND REPAIRS",
                    "startPage": "446",
                    "children": [
                        {
                            "id": 587,
                            "level": "3",
                            "title": "I1 SCOPE",
                            "startPage": "446",
                            "children": [],
                            "endPage": "446"
                        },
                        {
                            "id": 588,
                            "level": "3",
                            "title": "I2 PROTECTIVE DEVICE RATINGS",
                            "startPage": "446",
                            "children": [],
                            "endPage": "447"
                        },
                        {
                            "id": 589,
                            "level": "3",
                            "title": "I3 METRIC EQUIVALENT SIZES FOR IMPERIAL CONDUCTORS",
                            "startPage": "447",
                            "children": [],
                            "endPage": "449"
                        }
                    ],
                    "endPage": "449"
                },
                {
                    "id": 590,
                    "level": "2",
                    "title": "APPENDIX J - SYMBOLS USED IN THIS STANDARD",
                    "startPage": "449",
                    "children": [
                        {
                            "id": 591,
                            "level": "3",
                            "title": "J1 INTRODUCTION",
                            "startPage": "449",
                            "children": [],
                            "endPage": "451"
                        }
                    ],
                    "endPage": "451"
                },
                {
                    "id": 592,
                    "level": "2",
                    "title": "APPENDIX K - (deleted)",
                    "startPage": "451",
                    "children": [],
                    "endPage": "452"
                },
                {
                    "id": 593,
                    "level": "2",
                    "title": "APPENDIX L - ELECTRIC SHOCK SURVIVAL&#8212;Australia",
                    "startPage": "452",
                    "children": [],
                    "endPage": "454"
                },
                {
                    "id": 594,
                    "level": "2",
                    "title": "APPENDIX M - ELECTRIC SHOCK SURVIVAL&#8212;New Zealand",
                    "startPage": "454",
                    "children": [],
                    "endPage": "456"
                },
                {
                    "id": 595,
                    "level": "2",
                    "title": "INDEX",
                    "startPage": "456",
                    "children": [],
                    "endPage": "470"
                }
            ],
            "endPage": "470"
        }
    ]
};
const toc2 = {
    "uid": 1440495,
    "path": "\/PDF\/AS\/S056\/F0001440495.pdf",
    "artefact_name": "AS\/NZS 3000 : 2018 : EN",
    "toc": [
        {
            "id": 0,
            "level": "1",
            "title": "AS\/NZS 3000:2018 ELECTRICAL INSTALLATIONS (KNOWN AS THE AUSTRALIAN\/NEW ZEALAND WIRING RULES)",
            "startPage": "1",
            "children": [
                {
                    "id": 1,
                    "level": "2",
                    "title": "PREFACE",
                    "startPage": "5",
                    "children": [],
                    "endPage": "12"
                },
                {
                    "id": 2,
                    "level": "2",
                    "title": "CONTENTS",
                    "startPage": "12",
                    "children": [],
                    "endPage": "23"
                },
                {
                    "id": 3,
                    "level": "2",
                    "title": "LIST OF TABLES",
                    "startPage": "23",
                    "children": [],
                    "endPage": "26"
                },
                {
                    "id": 4,
                    "level": "2",
                    "title": "LIST OF FIGURES",
                    "startPage": "26",
                    "children": [],
                    "endPage": "33"
                },
                {
                    "id": 5,
                    "level": "2",
                    "title": "FOREWORD",
                    "startPage": "33",
                    "children": [],
                    "endPage": "36"
                },
                {
                    "id": 6,
                    "level": "2",
                    "title": "SECTION 1 SCOPE, APPLICATION AND FUNDAMENTAL PRINCIPLES",
                    "startPage": "36",
                    "children": [
                        {
                            "id": 7,
                            "level": "3",
                            "title": "1.1 SCOPE",
                            "startPage": "36",
                            "children": [],
                            "endPage": "36"
                        },
                        {
                            "id": 8,
                            "level": "3",
                            "title": "1.2 APPLICATION",
                            "startPage": "36",
                            "children": [],
                            "endPage": "37"
                        },
                        {
                            "id": 9,
                            "level": "3",
                            "title": "1.3 REFERENCED DOCUMENTS",
                            "startPage": "37",
                            "children": [],
                            "endPage": "37"
                        },
                        {
                            "id": 10,
                            "level": "3",
                            "title": "1.4 DEFINITIONS",
                            "startPage": "37",
                            "children": [
                                {
                                    "id": 11,
                                    "level": "4",
                                    "title": "1.4.1 Application of definitions",
                                    "startPage": "37",
                                    "children": [],
                                    "endPage": "37"
                                },
                                {
                                    "id": 12,
                                    "level": "4",
                                    "title": "1.4.2 Accessible",
                                    "startPage": "37",
                                    "children": [],
                                    "endPage": "37"
                                },
                                {
                                    "id": 13,
                                    "level": "4",
                                    "title": "1.4.3 Accessible, readily",
                                    "startPage": "37",
                                    "children": [],
                                    "endPage": "37"
                                },
                                {
                                    "id": 14,
                                    "level": "4",
                                    "title": "1.4.4 Accessory",
                                    "startPage": "37",
                                    "children": [],
                                    "endPage": "37"
                                },
                                {
                                    "id": 15,
                                    "level": "4",
                                    "title": "1.4.5 Active (or active conductor)",
                                    "startPage": "37",
                                    "children": [],
                                    "endPage": "37"
                                },
                                {
                                    "id": 16,
                                    "level": "4",
                                    "title": "1.4.6 Adjacent",
                                    "startPage": "37",
                                    "children": [],
                                    "endPage": "38"
                                },
                                {
                                    "id": 17,
                                    "level": "4",
                                    "title": "1.4.7 Aerial conductor",
                                    "startPage": "38",
                                    "children": [],
                                    "endPage": "38"
                                },
                                {
                                    "id": 18,
                                    "level": "4",
                                    "title": "1.4.8 Alteration",
                                    "startPage": "38",
                                    "children": [],
                                    "endPage": "38"
                                },
                                {
                                    "id": 19,
                                    "level": "4",
                                    "title": "1.4.9 Appliance",
                                    "startPage": "38",
                                    "children": [],
                                    "endPage": "38"
                                },
                                {
                                    "id": 20,
                                    "level": "4",
                                    "title": "1.4.10 Appliance, fixed",
                                    "startPage": "38",
                                    "children": [],
                                    "endPage": "38"
                                },
                                {
                                    "id": 21,
                                    "level": "4",
                                    "title": "1.4.11 Appliance, hand-held",
                                    "startPage": "38",
                                    "children": [],
                                    "endPage": "38"
                                },
                                {
                                    "id": 22,
                                    "level": "4",
                                    "title": "1.4.12 Appliance, portable",
                                    "startPage": "38",
                                    "children": [],
                                    "endPage": "38"
                                },
                                {
                                    "id": 23,
                                    "level": "4",
                                    "title": "1.4.13 Appliance, stationary",
                                    "startPage": "38",
                                    "children": [],
                                    "endPage": "38"
                                },
                                {
                                    "id": 24,
                                    "level": "4",
                                    "title": "1.4.14 Arc fault detection device (AFDD)",
                                    "startPage": "38",
                                    "children": [],
                                    "endPage": "38"
                                },
                                {
                                    "id": 25,
                                    "level": "4",
                                    "title": "1.4.15 Area, hazardous",
                                    "startPage": "38",
                                    "children": [],
                                    "endPage": "39"
                                },
                                {
                                    "id": 26,
                                    "level": "4",
                                    "title": "1.4.16 Arm&#8482;s reach",
                                    "startPage": "39",
                                    "children": [],
                                    "endPage": "39"
                                },
                                {
                                    "id": 27,
                                    "level": "4",
                                    "title": "1.4.17 Authority, regulatory",
                                    "startPage": "39",
                                    "children": [],
                                    "endPage": "39"
                                },
                                {
                                    "id": 28,
                                    "level": "4",
                                    "title": "1.4.18 Authorized person",
                                    "startPage": "39",
                                    "children": [],
                                    "endPage": "39"
                                },
                                {
                                    "id": 29,
                                    "level": "4",
                                    "title": "1.4.19 Available, readily",
                                    "startPage": "39",
                                    "children": [],
                                    "endPage": "39"
                                },
                                {
                                    "id": 30,
                                    "level": "4",
                                    "title": "1.4.20 Barrier",
                                    "startPage": "39",
                                    "children": [],
                                    "endPage": "40"
                                },
                                {
                                    "id": 31,
                                    "level": "4",
                                    "title": "1.4.21 Cable",
                                    "startPage": "40",
                                    "children": [],
                                    "endPage": "40"
                                },
                                {
                                    "id": 32,
                                    "level": "4",
                                    "title": "1.4.22 Cable, armoured",
                                    "startPage": "40",
                                    "children": [],
                                    "endPage": "40"
                                },
                                {
                                    "id": 33,
                                    "level": "4",
                                    "title": "1.4.23 Cable core",
                                    "startPage": "40",
                                    "children": [],
                                    "endPage": "40"
                                },
                                {
                                    "id": 34,
                                    "level": "4",
                                    "title": "1.4.24 Cable, flexible",
                                    "startPage": "40",
                                    "children": [],
                                    "endPage": "40"
                                },
                                {
                                    "id": 35,
                                    "level": "4",
                                    "title": "1.4.25 Cable, mineral insulated metal sheathed (MIMS)",
                                    "startPage": "40",
                                    "children": [],
                                    "endPage": "40"
                                },
                                {
                                    "id": 36,
                                    "level": "4",
                                    "title": "1.4.26 Cable, neutral-screened",
                                    "startPage": "40",
                                    "children": [],
                                    "endPage": "40"
                                },
                                {
                                    "id": 37,
                                    "level": "4",
                                    "title": "1.4.27 Cable, sheathed",
                                    "startPage": "40",
                                    "children": [],
                                    "endPage": "40"
                                },
                                {
                                    "id": 38,
                                    "level": "4",
                                    "title": "1.4.28 Ceiling, suspended",
                                    "startPage": "40",
                                    "children": [],
                                    "endPage": "40"
                                },
                                {
                                    "id": 39,
                                    "level": "4",
                                    "title": "1.4.29 Circuit",
                                    "startPage": "40",
                                    "children": [],
                                    "endPage": "40"
                                },
                                {
                                    "id": 40,
                                    "level": "4",
                                    "title": "1.4.30 Circuit-breaker",
                                    "startPage": "40",
                                    "children": [],
                                    "endPage": "41"
                                },
                                {
                                    "id": 41,
                                    "level": "4",
                                    "title": "1.4.31 Class I equipment",
                                    "startPage": "41",
                                    "children": [],
                                    "endPage": "41"
                                },
                                {
                                    "id": 42,
                                    "level": "4",
                                    "title": "1.4.32 Class II equipment",
                                    "startPage": "41",
                                    "children": [],
                                    "endPage": "42"
                                },
                                {
                                    "id": 43,
                                    "level": "4",
                                    "title": "1.4.33 Class III equipment",
                                    "startPage": "42",
                                    "children": [],
                                    "endPage": "42"
                                },
                                {
                                    "id": 44,
                                    "level": "4",
                                    "title": "1.4.34 Competent person",
                                    "startPage": "42",
                                    "children": [],
                                    "endPage": "42"
                                },
                                {
                                    "id": 45,
                                    "level": "4",
                                    "title": "1.4.35 Conductor",
                                    "startPage": "42",
                                    "children": [],
                                    "endPage": "42"
                                },
                                {
                                    "id": 46,
                                    "level": "4",
                                    "title": "1.4.36 Conductor, bare",
                                    "startPage": "42",
                                    "children": [],
                                    "endPage": "42"
                                },
                                {
                                    "id": 47,
                                    "level": "4",
                                    "title": "1.4.37 Consumer mains",
                                    "startPage": "42",
                                    "children": [],
                                    "endPage": "42"
                                },
                                {
                                    "id": 48,
                                    "level": "4",
                                    "title": "1.4.38 Contact, direct",
                                    "startPage": "42",
                                    "children": [],
                                    "endPage": "42"
                                },
                                {
                                    "id": 49,
                                    "level": "4",
                                    "title": "1.4.39 Contact, indirect",
                                    "startPage": "42",
                                    "children": [],
                                    "endPage": "43"
                                },
                                {
                                    "id": 50,
                                    "level": "4",
                                    "title": "1.4.40 Cord, flexible",
                                    "startPage": "43",
                                    "children": [],
                                    "endPage": "43"
                                },
                                {
                                    "id": 51,
                                    "level": "4",
                                    "title": "1.4.41 Current, fault",
                                    "startPage": "43",
                                    "children": [],
                                    "endPage": "43"
                                },
                                {
                                    "id": 52,
                                    "level": "4",
                                    "title": "1.4.42 Current, overload",
                                    "startPage": "43",
                                    "children": [],
                                    "endPage": "43"
                                },
                                {
                                    "id": 53,
                                    "level": "4",
                                    "title": "1.4.43 Current, short-circuit",
                                    "startPage": "43",
                                    "children": [],
                                    "endPage": "43"
                                },
                                {
                                    "id": 54,
                                    "level": "4",
                                    "title": "1.4.44 Damp situation",
                                    "startPage": "43",
                                    "children": [],
                                    "endPage": "43"
                                },
                                {
                                    "id": 55,
                                    "level": "4",
                                    "title": "1.4.45 De-energized",
                                    "startPage": "43",
                                    "children": [],
                                    "endPage": "43"
                                },
                                {
                                    "id": 56,
                                    "level": "4",
                                    "title": "1.4.46 Distribution board",
                                    "startPage": "43",
                                    "children": [],
                                    "endPage": "43"
                                },
                                {
                                    "id": 57,
                                    "level": "4",
                                    "title": "1.4.47 Earthed",
                                    "startPage": "43",
                                    "children": [],
                                    "endPage": "44"
                                },
                                {
                                    "id": 58,
                                    "level": "4",
                                    "title": "1.4.48 Earthed situation",
                                    "startPage": "44",
                                    "children": [],
                                    "endPage": "44"
                                },
                                {
                                    "id": 59,
                                    "level": "4",
                                    "title": "1.4.49 Earth fault-loop impedance",
                                    "startPage": "44",
                                    "children": [],
                                    "endPage": "44"
                                },
                                {
                                    "id": 60,
                                    "level": "4",
                                    "title": "1.4.50 Electrical equipment",
                                    "startPage": "44",
                                    "children": [],
                                    "endPage": "44"
                                },
                                {
                                    "id": 61,
                                    "level": "4",
                                    "title": "1.4.51 Electrical fault",
                                    "startPage": "44",
                                    "children": [],
                                    "endPage": "45"
                                },
                                {
                                    "id": 62,
                                    "level": "4",
                                    "title": "1.4.52 Electrical installation",
                                    "startPage": "45",
                                    "children": [],
                                    "endPage": "45"
                                },
                                {
                                    "id": 63,
                                    "level": "4",
                                    "title": "1.4.53 Electrical installation, domestic",
                                    "startPage": "45",
                                    "children": [],
                                    "endPage": "45"
                                },
                                {
                                    "id": 64,
                                    "level": "4",
                                    "title": "1.4.54 Electrical installation, multiple",
                                    "startPage": "45",
                                    "children": [],
                                    "endPage": "45"
                                },
                                {
                                    "id": 65,
                                    "level": "4",
                                    "title": "1.4.55 Electrical installation, residential",
                                    "startPage": "45",
                                    "children": [],
                                    "endPage": "45"
                                },
                                {
                                    "id": 66,
                                    "level": "4",
                                    "title": "1.4.56 Electric vehicle (EV)",
                                    "startPage": "45",
                                    "children": [],
                                    "endPage": "45"
                                },
                                {
                                    "id": 67,
                                    "level": "4",
                                    "title": "1.4.57 Electricity distributor",
                                    "startPage": "45",
                                    "children": [],
                                    "endPage": "46"
                                },
                                {
                                    "id": 68,
                                    "level": "4",
                                    "title": "1.4.58 Enclosure",
                                    "startPage": "46",
                                    "children": [],
                                    "endPage": "46"
                                },
                                {
                                    "id": 69,
                                    "level": "4",
                                    "title": "1.4.59 Energized",
                                    "startPage": "46",
                                    "children": [],
                                    "endPage": "46"
                                },
                                {
                                    "id": 70,
                                    "level": "4",
                                    "title": "1.4.60 Equipotential bonding",
                                    "startPage": "46",
                                    "children": [],
                                    "endPage": "46"
                                },
                                {
                                    "id": 71,
                                    "level": "4",
                                    "title": "1.4.61 Explosive atmosphere",
                                    "startPage": "46",
                                    "children": [],
                                    "endPage": "46"
                                },
                                {
                                    "id": 72,
                                    "level": "4",
                                    "title": "1.4.62 Exposed conductive part",
                                    "startPage": "46",
                                    "children": [],
                                    "endPage": "47"
                                },
                                {
                                    "id": 73,
                                    "level": "4",
                                    "title": "1.4.63 Extraneous conductive part",
                                    "startPage": "47",
                                    "children": [],
                                    "endPage": "47"
                                },
                                {
                                    "id": 74,
                                    "level": "4",
                                    "title": "1.4.64 Fault-current limiter",
                                    "startPage": "47",
                                    "children": [],
                                    "endPage": "47"
                                },
                                {
                                    "id": 75,
                                    "level": "4",
                                    "title": "1.4.65 Fire mode",
                                    "startPage": "47",
                                    "children": [],
                                    "endPage": "47"
                                },
                                {
                                    "id": 76,
                                    "level": "4",
                                    "title": "1.4.66 Functional earthing (FE)",
                                    "startPage": "47",
                                    "children": [],
                                    "endPage": "48"
                                },
                                {
                                    "id": 77,
                                    "level": "4",
                                    "title": "1.4.67 Functional unit",
                                    "startPage": "48",
                                    "children": [],
                                    "endPage": "48"
                                },
                                {
                                    "id": 78,
                                    "level": "4",
                                    "title": "1.4.68 Fuse",
                                    "startPage": "48",
                                    "children": [],
                                    "endPage": "48"
                                },
                                {
                                    "id": 79,
                                    "level": "4",
                                    "title": "1.4.69 Informative",
                                    "startPage": "48",
                                    "children": [],
                                    "endPage": "48"
                                },
                                {
                                    "id": 80,
                                    "level": "4",
                                    "title": "1.4.70 Ingress protection (IP) classification",
                                    "startPage": "48",
                                    "children": [],
                                    "endPage": "48"
                                },
                                {
                                    "id": 81,
                                    "level": "4",
                                    "title": "1.4.71 Installation coupler",
                                    "startPage": "48",
                                    "children": [],
                                    "endPage": "48"
                                },
                                {
                                    "id": 82,
                                    "level": "4",
                                    "title": "1.4.72 Insulated",
                                    "startPage": "48",
                                    "children": [],
                                    "endPage": "48"
                                },
                                {
                                    "id": 83,
                                    "level": "4",
                                    "title": "1.4.73 Insulation system",
                                    "startPage": "48",
                                    "children": [],
                                    "endPage": "49"
                                },
                                {
                                    "id": 84,
                                    "level": "4",
                                    "title": "1.4.74 Isolated",
                                    "startPage": "49",
                                    "children": [],
                                    "endPage": "49"
                                },
                                {
                                    "id": 85,
                                    "level": "4",
                                    "title": "1.4.75 Isolation (Isolating function)",
                                    "startPage": "49",
                                    "children": [],
                                    "endPage": "49"
                                },
                                {
                                    "id": 86,
                                    "level": "4",
                                    "title": "1.4.76 Lamp",
                                    "startPage": "49",
                                    "children": [],
                                    "endPage": "49"
                                },
                                {
                                    "id": 87,
                                    "level": "4",
                                    "title": "1.4.77 Lift",
                                    "startPage": "49",
                                    "children": [],
                                    "endPage": "50"
                                },
                                {
                                    "id": 88,
                                    "level": "4",
                                    "title": "1.4.78 Live",
                                    "startPage": "50",
                                    "children": [],
                                    "endPage": "50"
                                },
                                {
                                    "id": 89,
                                    "level": "4",
                                    "title": "1.4.79 Live part",
                                    "startPage": "50",
                                    "children": [],
                                    "endPage": "50"
                                },
                                {
                                    "id": 90,
                                    "level": "4",
                                    "title": "1.4.80 Luminaire (Light fitting)",
                                    "startPage": "50",
                                    "children": [],
                                    "endPage": "50"
                                },
                                {
                                    "id": 91,
                                    "level": "4",
                                    "title": "1.4.81 Main earthing conductor",
                                    "startPage": "50",
                                    "children": [],
                                    "endPage": "50"
                                },
                                {
                                    "id": 92,
                                    "level": "4",
                                    "title": "1.4.82 Main switch",
                                    "startPage": "50",
                                    "children": [],
                                    "endPage": "51"
                                },
                                {
                                    "id": 93,
                                    "level": "4",
                                    "title": "1.4.83 Multiple earthed neutral (MEN) system",
                                    "startPage": "51",
                                    "children": [],
                                    "endPage": "51"
                                },
                                {
                                    "id": 94,
                                    "level": "4",
                                    "title": "1.4.84 Neutral (Neutral conductor or mid-wire)",
                                    "startPage": "51",
                                    "children": [],
                                    "endPage": "51"
                                },
                                {
                                    "id": 95,
                                    "level": "4",
                                    "title": "1.4.85 Neutral earthed system",
                                    "startPage": "51",
                                    "children": [],
                                    "endPage": "51"
                                },
                                {
                                    "id": 96,
                                    "level": "4",
                                    "title": "1.4.86 Normative",
                                    "startPage": "51",
                                    "children": [],
                                    "endPage": "51"
                                },
                                {
                                    "id": 97,
                                    "level": "4",
                                    "title": "1.4.87 Obstacle",
                                    "startPage": "51",
                                    "children": [],
                                    "endPage": "51"
                                },
                                {
                                    "id": 98,
                                    "level": "4",
                                    "title": "1.4.88 Outbuilding-Individual",
                                    "startPage": "51",
                                    "children": [],
                                    "endPage": "51"
                                },
                                {
                                    "id": 99,
                                    "level": "4",
                                    "title": "1.4.89 Outbuilding-Combined",
                                    "startPage": "51",
                                    "children": [],
                                    "endPage": "52"
                                },
                                {
                                    "id": 100,
                                    "level": "4",
                                    "title": "1.4.90 Overcurrent",
                                    "startPage": "52",
                                    "children": [],
                                    "endPage": "52"
                                },
                                {
                                    "id": 101,
                                    "level": "4",
                                    "title": "1.4.91 Plug",
                                    "startPage": "52",
                                    "children": [],
                                    "endPage": "52"
                                },
                                {
                                    "id": 102,
                                    "level": "4",
                                    "title": "1.4.92 Point (in wiring)",
                                    "startPage": "52",
                                    "children": [],
                                    "endPage": "52"
                                },
                                {
                                    "id": 103,
                                    "level": "4",
                                    "title": "1.4.93 Point of attachment",
                                    "startPage": "52",
                                    "children": [],
                                    "endPage": "52"
                                },
                                {
                                    "id": 104,
                                    "level": "4",
                                    "title": "1.4.94 Point of entry",
                                    "startPage": "52",
                                    "children": [],
                                    "endPage": "52"
                                },
                                {
                                    "id": 105,
                                    "level": "4",
                                    "title": "1.4.95 Point of supply",
                                    "startPage": "52",
                                    "children": [],
                                    "endPage": "52"
                                },
                                {
                                    "id": 106,
                                    "level": "4",
                                    "title": "1.4.96 Protected extra-low voltage (PELV)",
                                    "startPage": "52",
                                    "children": [],
                                    "endPage": "52"
                                },
                                {
                                    "id": 107,
                                    "level": "4",
                                    "title": "1.4.97 Protection, basic",
                                    "startPage": "52",
                                    "children": [],
                                    "endPage": "52"
                                },
                                {
                                    "id": 108,
                                    "level": "4",
                                    "title": "1.4.98 Protection, fault",
                                    "startPage": "52",
                                    "children": [],
                                    "endPage": "52"
                                },
                                {
                                    "id": 109,
                                    "level": "4",
                                    "title": "1.4.99 Protective earth neutral (PEN)",
                                    "startPage": "52",
                                    "children": [],
                                    "endPage": "53"
                                },
                                {
                                    "id": 110,
                                    "level": "4",
                                    "title": "1.4.100 Protective earthing conductor",
                                    "startPage": "53",
                                    "children": [],
                                    "endPage": "53"
                                },
                                {
                                    "id": 111,
                                    "level": "4",
                                    "title": "1.4.101 Repair",
                                    "startPage": "53",
                                    "children": [],
                                    "endPage": "53"
                                },
                                {
                                    "id": 112,
                                    "level": "4",
                                    "title": "1.4.102 Residual current device (RCD)",
                                    "startPage": "53",
                                    "children": [],
                                    "endPage": "53"
                                },
                                {
                                    "id": 113,
                                    "level": "4",
                                    "title": "1.4.103 Ripple-free d.c.",
                                    "startPage": "53",
                                    "children": [],
                                    "endPage": "53"
                                },
                                {
                                    "id": 114,
                                    "level": "4",
                                    "title": "1.4.104 Safety service",
                                    "startPage": "53",
                                    "children": [],
                                    "endPage": "54"
                                },
                                {
                                    "id": 115,
                                    "level": "4",
                                    "title": "1.4.105 Separated extra-low voltage (SELV)",
                                    "startPage": "54",
                                    "children": [],
                                    "endPage": "54"
                                },
                                {
                                    "id": 116,
                                    "level": "4",
                                    "title": "1.4.106 Service protective device",
                                    "startPage": "54",
                                    "children": [],
                                    "endPage": "54"
                                },
                                {
                                    "id": 117,
                                    "level": "4",
                                    "title": "1.4.107 Shall",
                                    "startPage": "54",
                                    "children": [],
                                    "endPage": "54"
                                },
                                {
                                    "id": 118,
                                    "level": "4",
                                    "title": "1.4.108 Should",
                                    "startPage": "54",
                                    "children": [],
                                    "endPage": "54"
                                },
                                {
                                    "id": 119,
                                    "level": "4",
                                    "title": "1.4.109 Socket, cord-extension",
                                    "startPage": "54",
                                    "children": [],
                                    "endPage": "54"
                                },
                                {
                                    "id": 120,
                                    "level": "4",
                                    "title": "1.4.110 Socket-outlet",
                                    "startPage": "54",
                                    "children": [],
                                    "endPage": "54"
                                },
                                {
                                    "id": 121,
                                    "level": "4",
                                    "title": "1.4.111 Socket-outlet-Multiple combination",
                                    "startPage": "54",
                                    "children": [],
                                    "endPage": "54"
                                },
                                {
                                    "id": 122,
                                    "level": "4",
                                    "title": "1.4.112 Socket residual current device (SRCD)",
                                    "startPage": "54",
                                    "children": [],
                                    "endPage": "54"
                                },
                                {
                                    "id": 123,
                                    "level": "4",
                                    "title": "1.4.113 Soft wiring",
                                    "startPage": "54",
                                    "children": [],
                                    "endPage": "55"
                                },
                                {
                                    "id": 124,
                                    "level": "4",
                                    "title": "1.4.114 Source of supply",
                                    "startPage": "55",
                                    "children": [],
                                    "endPage": "55"
                                },
                                {
                                    "id": 125,
                                    "level": "4",
                                    "title": "1.4.115 Subcircuit, final",
                                    "startPage": "55",
                                    "children": [],
                                    "endPage": "55"
                                },
                                {
                                    "id": 126,
                                    "level": "4",
                                    "title": "1.4.116 Submains",
                                    "startPage": "55",
                                    "children": [],
                                    "endPage": "55"
                                },
                                {
                                    "id": 127,
                                    "level": "4",
                                    "title": "1.4.117 Substation",
                                    "startPage": "55",
                                    "children": [],
                                    "endPage": "55"
                                },
                                {
                                    "id": 128,
                                    "level": "4",
                                    "title": "1.4.118 Supply, alternative",
                                    "startPage": "55",
                                    "children": [],
                                    "endPage": "55"
                                },
                                {
                                    "id": 129,
                                    "level": "4",
                                    "title": "1.4.119 Supply, normal",
                                    "startPage": "55",
                                    "children": [],
                                    "endPage": "55"
                                },
                                {
                                    "id": 130,
                                    "level": "4",
                                    "title": "1.4.120 Supply, supplementary",
                                    "startPage": "55",
                                    "children": [],
                                    "endPage": "56"
                                },
                                {
                                    "id": 131,
                                    "level": "4",
                                    "title": "1.4.121 Switchboard",
                                    "startPage": "56",
                                    "children": [],
                                    "endPage": "56"
                                },
                                {
                                    "id": 132,
                                    "level": "4",
                                    "title": "1.4.122 Switchboard, main",
                                    "startPage": "56",
                                    "children": [],
                                    "endPage": "56"
                                },
                                {
                                    "id": 133,
                                    "level": "4",
                                    "title": "1.4.123 Switchgear",
                                    "startPage": "56",
                                    "children": [],
                                    "endPage": "56"
                                },
                                {
                                    "id": 134,
                                    "level": "4",
                                    "title": "1.4.124 Touch current",
                                    "startPage": "56",
                                    "children": [],
                                    "endPage": "56"
                                },
                                {
                                    "id": 135,
                                    "level": "4",
                                    "title": "1.4.125 Touch voltage",
                                    "startPage": "56",
                                    "children": [],
                                    "endPage": "56"
                                },
                                {
                                    "id": 136,
                                    "level": "4",
                                    "title": "1.4.126 Track system",
                                    "startPage": "56",
                                    "children": [],
                                    "endPage": "56"
                                },
                                {
                                    "id": 137,
                                    "level": "4",
                                    "title": "1.4.127 Trunking, cable",
                                    "startPage": "56",
                                    "children": [],
                                    "endPage": "56"
                                },
                                {
                                    "id": 138,
                                    "level": "4",
                                    "title": "1.4.128 Voltage",
                                    "startPage": "56",
                                    "children": [],
                                    "endPage": "57"
                                },
                                {
                                    "id": 139,
                                    "level": "4",
                                    "title": "1.4.129 Wiring, catenary",
                                    "startPage": "57",
                                    "children": [],
                                    "endPage": "57"
                                },
                                {
                                    "id": 140,
                                    "level": "4",
                                    "title": "1.4.130 Wiring enclosure",
                                    "startPage": "57",
                                    "children": [],
                                    "endPage": "57"
                                },
                                {
                                    "id": 141,
                                    "level": "4",
                                    "title": "1.4.131 Wiring, equipment",
                                    "startPage": "57",
                                    "children": [],
                                    "endPage": "57"
                                },
                                {
                                    "id": 142,
                                    "level": "4",
                                    "title": "1.4.132 Wiring, installation",
                                    "startPage": "57",
                                    "children": [],
                                    "endPage": "57"
                                },
                                {
                                    "id": 143,
                                    "level": "4",
                                    "title": "1.4.133 Wiring systems",
                                    "startPage": "57",
                                    "children": [],
                                    "endPage": "57"
                                },
                                {
                                    "id": 144,
                                    "level": "4",
                                    "title": "1.4.134 Wiring, underground",
                                    "startPage": "57",
                                    "children": [],
                                    "endPage": "57"
                                }
                            ],
                            "endPage": "57"
                        },
                        {
                            "id": 145,
                            "level": "3",
                            "title": "1.5 FUNDAMENTAL PRINCIPLES",
                            "startPage": "57",
                            "children": [
                                {
                                    "id": 146,
                                    "level": "4",
                                    "title": "1.5.1 Protection against dangers and damage",
                                    "startPage": "57",
                                    "children": [],
                                    "endPage": "58"
                                },
                                {
                                    "id": 147,
                                    "level": "4",
                                    "title": "1.5.2 Control and isolation",
                                    "startPage": "58",
                                    "children": [],
                                    "endPage": "58"
                                },
                                {
                                    "id": 148,
                                    "level": "4",
                                    "title": "1.5.3 Protection against electric shock",
                                    "startPage": "58",
                                    "children": [],
                                    "endPage": "59"
                                },
                                {
                                    "id": 149,
                                    "level": "4",
                                    "title": "1.5.4 Basic protection",
                                    "startPage": "59",
                                    "children": [],
                                    "endPage": "62"
                                },
                                {
                                    "id": 150,
                                    "level": "4",
                                    "title": "1.5.5 Fault protection",
                                    "startPage": "62",
                                    "children": [],
                                    "endPage": "65"
                                },
                                {
                                    "id": 151,
                                    "level": "4",
                                    "title": "1.5.6 Additional protection by the use of RCDs",
                                    "startPage": "65",
                                    "children": [],
                                    "endPage": "66"
                                },
                                {
                                    "id": 152,
                                    "level": "4",
                                    "title": "1.5.7 Basic and fault protection by use of extra-low voltage",
                                    "startPage": "66",
                                    "children": [],
                                    "endPage": "66"
                                },
                                {
                                    "id": 153,
                                    "level": "4",
                                    "title": "1.5.8 Protection against thermal effects in normal service",
                                    "startPage": "66",
                                    "children": [],
                                    "endPage": "67"
                                },
                                {
                                    "id": 154,
                                    "level": "4",
                                    "title": "1.5.9 Protection against overcurrent",
                                    "startPage": "67",
                                    "children": [],
                                    "endPage": "67"
                                },
                                {
                                    "id": 155,
                                    "level": "4",
                                    "title": "1.5.10 Protection against earth fault currents",
                                    "startPage": "67",
                                    "children": [],
                                    "endPage": "67"
                                },
                                {
                                    "id": 156,
                                    "level": "4",
                                    "title": "1.5.11 Protection against abnormal voltages",
                                    "startPage": "67",
                                    "children": [],
                                    "endPage": "69"
                                },
                                {
                                    "id": 157,
                                    "level": "4",
                                    "title": "1.5.12 Protection against the spread of fire",
                                    "startPage": "69",
                                    "children": [],
                                    "endPage": "69"
                                },
                                {
                                    "id": 158,
                                    "level": "4",
                                    "title": "1.5.13 Protection against injury from mechanical movement",
                                    "startPage": "69",
                                    "children": [],
                                    "endPage": "69"
                                },
                                {
                                    "id": 159,
                                    "level": "4",
                                    "title": "1.5.14 Protection against external influences",
                                    "startPage": "69",
                                    "children": [],
                                    "endPage": "70"
                                }
                            ],
                            "endPage": "70"
                        },
                        {
                            "id": 160,
                            "level": "3",
                            "title": "1.6 DESIGN OF AN ELECTRICAL INSTALLATION",
                            "startPage": "70",
                            "children": [
                                {
                                    "id": 161,
                                    "level": "4",
                                    "title": "1.6.1 General",
                                    "startPage": "70",
                                    "children": [],
                                    "endPage": "70"
                                },
                                {
                                    "id": 162,
                                    "level": "4",
                                    "title": "1.6.2 Supply characteristics",
                                    "startPage": "70",
                                    "children": [],
                                    "endPage": "70"
                                },
                                {
                                    "id": 163,
                                    "level": "4",
                                    "title": "1.6.3 Maximum demand",
                                    "startPage": "70",
                                    "children": [],
                                    "endPage": "71"
                                },
                                {
                                    "id": 164,
                                    "level": "4",
                                    "title": "1.6.4 Utilization voltage",
                                    "startPage": "71",
                                    "children": [],
                                    "endPage": "71"
                                },
                                {
                                    "id": 165,
                                    "level": "4",
                                    "title": "1.6.5 Electrical installation circuit arrangement",
                                    "startPage": "71",
                                    "children": [],
                                    "endPage": "71"
                                }
                            ],
                            "endPage": "71"
                        },
                        {
                            "id": 166,
                            "level": "3",
                            "title": "1.7 SELECTION AND INSTALLATION OF ELECTRICAL EQUIPMENT",
                            "startPage": "71",
                            "children": [
                                {
                                    "id": 167,
                                    "level": "4",
                                    "title": "1.7.1 General",
                                    "startPage": "71",
                                    "children": [],
                                    "endPage": "71"
                                },
                                {
                                    "id": 168,
                                    "level": "4",
                                    "title": "1.7.2 Installation work practice",
                                    "startPage": "71",
                                    "children": [],
                                    "endPage": "72"
                                },
                                {
                                    "id": 169,
                                    "level": "4",
                                    "title": "1.7.3 Equipment selection",
                                    "startPage": "72",
                                    "children": [],
                                    "endPage": "73"
                                },
                                {
                                    "id": 170,
                                    "level": "4",
                                    "title": "1.7.4 Damp situations",
                                    "startPage": "73",
                                    "children": [],
                                    "endPage": "73"
                                }
                            ],
                            "endPage": "73"
                        },
                        {
                            "id": 171,
                            "level": "3",
                            "title": "1.8 VERIFICATION (INSPECTION AND TESTING)",
                            "startPage": "73",
                            "children": [],
                            "endPage": "74"
                        },
                        {
                            "id": 172,
                            "level": "3",
                            "title": "1.9 MEANS OF COMPLIANCE",
                            "startPage": "74",
                            "children": [
                                {
                                    "id": 173,
                                    "level": "4",
                                    "title": "1.9.1 Compliance with Part 2 of this Standard",
                                    "startPage": "74",
                                    "children": [],
                                    "endPage": "74"
                                },
                                {
                                    "id": 174,
                                    "level": "4",
                                    "title": "1.9.2 Compliance with the requirements of other standards",
                                    "startPage": "74",
                                    "children": [],
                                    "endPage": "74"
                                },
                                {
                                    "id": 175,
                                    "level": "4",
                                    "title": "1.9.3 Alterations and repairs",
                                    "startPage": "74",
                                    "children": [],
                                    "endPage": "74"
                                },
                                {
                                    "id": 176,
                                    "level": "4",
                                    "title": "1.9.4 Compliance by specific design and installation",
                                    "startPage": "74",
                                    "children": [],
                                    "endPage": "78"
                                }
                            ],
                            "endPage": "78"
                        }
                    ],
                    "endPage": "78"
                },
                {
                    "id": 177,
                    "level": "2",
                    "title": "SECTION 2 GENERAL ARRANGEMENT, CONTROL AND PROTECTION",
                    "startPage": "78",
                    "children": [
                        {
                            "id": 178,
                            "level": "3",
                            "title": "2.1 GENERAL",
                            "startPage": "78",
                            "children": [
                                {
                                    "id": 179,
                                    "level": "4",
                                    "title": "2.1.1 Application",
                                    "startPage": "78",
                                    "children": [],
                                    "endPage": "78"
                                },
                                {
                                    "id": 180,
                                    "level": "4",
                                    "title": "2.1.2 Selection and installation",
                                    "startPage": "78",
                                    "children": [],
                                    "endPage": "78"
                                }
                            ],
                            "endPage": "78"
                        },
                        {
                            "id": 181,
                            "level": "3",
                            "title": "2.2 ARRANGEMENT OF ELECTRICAL INSTALLATION",
                            "startPage": "78",
                            "children": [
                                {
                                    "id": 182,
                                    "level": "4",
                                    "title": "2.2.1 Circuits",
                                    "startPage": "78",
                                    "children": [],
                                    "endPage": "80"
                                },
                                {
                                    "id": 183,
                                    "level": "4",
                                    "title": "2.2.2 Maximum demand",
                                    "startPage": "80",
                                    "children": [],
                                    "endPage": "81"
                                },
                                {
                                    "id": 184,
                                    "level": "4",
                                    "title": "2.2.3 Selection and installation of conductors",
                                    "startPage": "81",
                                    "children": [],
                                    "endPage": "81"
                                },
                                {
                                    "id": 185,
                                    "level": "4",
                                    "title": "2.2.4 Operating characteristics of equipment",
                                    "startPage": "81",
                                    "children": [],
                                    "endPage": "82"
                                }
                            ],
                            "endPage": "82"
                        },
                        {
                            "id": 186,
                            "level": "3",
                            "title": "2.3 CONTROL OF ELECTRICAL INSTALLATION",
                            "startPage": "82",
                            "children": [
                                {
                                    "id": 187,
                                    "level": "4",
                                    "title": "2.3.1 General",
                                    "startPage": "82",
                                    "children": [],
                                    "endPage": "83"
                                },
                                {
                                    "id": 188,
                                    "level": "4",
                                    "title": "2.3.2 Common control requirements",
                                    "startPage": "83",
                                    "children": [],
                                    "endPage": "86"
                                },
                                {
                                    "id": 189,
                                    "level": "4",
                                    "title": "2.3.3 Main switches",
                                    "startPage": "86",
                                    "children": [],
                                    "endPage": "89"
                                },
                                {
                                    "id": 190,
                                    "level": "4",
                                    "title": "2.3.4 Additional isolating switches",
                                    "startPage": "89",
                                    "children": [],
                                    "endPage": "91"
                                },
                                {
                                    "id": 191,
                                    "level": "4",
                                    "title": "2.3.5 Emergency switching including emergency stopping",
                                    "startPage": "91",
                                    "children": [],
                                    "endPage": "93"
                                },
                                {
                                    "id": 192,
                                    "level": "4",
                                    "title": "2.3.6 Shutting down for mechanical maintenance",
                                    "startPage": "93",
                                    "children": [],
                                    "endPage": "94"
                                },
                                {
                                    "id": 193,
                                    "level": "4",
                                    "title": "2.3.7 Functional (control) switching",
                                    "startPage": "94",
                                    "children": [],
                                    "endPage": "95"
                                }
                            ],
                            "endPage": "95"
                        },
                        {
                            "id": 194,
                            "level": "3",
                            "title": "2.4 FAULT PROTECTION",
                            "startPage": "95",
                            "children": [
                                {
                                    "id": 195,
                                    "level": "4",
                                    "title": "2.4.1 General",
                                    "startPage": "95",
                                    "children": [],
                                    "endPage": "95"
                                },
                                {
                                    "id": 196,
                                    "level": "4",
                                    "title": "2.4.2 Protection by automatic disconnection of supply",
                                    "startPage": "95",
                                    "children": [],
                                    "endPage": "96"
                                },
                                {
                                    "id": 197,
                                    "level": "4",
                                    "title": "2.4.3 Types of devices",
                                    "startPage": "96",
                                    "children": [],
                                    "endPage": "96"
                                },
                                {
                                    "id": 198,
                                    "level": "4",
                                    "title": "2.4.4 Auto-reclose devices",
                                    "startPage": "96",
                                    "children": [],
                                    "endPage": "96"
                                }
                            ],
                            "endPage": "96"
                        },
                        {
                            "id": 199,
                            "level": "3",
                            "title": "2.5 PROTECTION AGAINST OVERCURRENT",
                            "startPage": "96",
                            "children": [
                                {
                                    "id": 200,
                                    "level": "4",
                                    "title": "2.5.1 General",
                                    "startPage": "96",
                                    "children": [],
                                    "endPage": "99"
                                },
                                {
                                    "id": 201,
                                    "level": "4",
                                    "title": "2.5.2 Devices for protection against both overload and short-circuit currents",
                                    "startPage": "99",
                                    "children": [],
                                    "endPage": "100"
                                },
                                {
                                    "id": 202,
                                    "level": "4",
                                    "title": "2.5.3 Protection against overload current",
                                    "startPage": "100",
                                    "children": [],
                                    "endPage": "102"
                                },
                                {
                                    "id": 203,
                                    "level": "4",
                                    "title": "2.5.4 Protection against short-circuit current",
                                    "startPage": "102",
                                    "children": [],
                                    "endPage": "111"
                                },
                                {
                                    "id": 204,
                                    "level": "4",
                                    "title": "2.5.5 Protection against switchboard internal arcing fault currents",
                                    "startPage": "111",
                                    "children": [],
                                    "endPage": "114"
                                },
                                {
                                    "id": 205,
                                    "level": "4",
                                    "title": "2.5.6 Coordination of overload and short-circuit protective devices",
                                    "startPage": "114",
                                    "children": [],
                                    "endPage": "115"
                                },
                                {
                                    "id": 206,
                                    "level": "4",
                                    "title": "2.5.7 Reliability of supply",
                                    "startPage": "115",
                                    "children": [],
                                    "endPage": "122"
                                }
                            ],
                            "endPage": "122"
                        },
                        {
                            "id": 207,
                            "level": "3",
                            "title": "2.6 ADDITIONAL PROTECTION BY RESIDUAL CURRENT DEVICES",
                            "startPage": "122",
                            "children": [
                                {
                                    "id": 208,
                                    "level": "4",
                                    "title": "2.6.1 General",
                                    "startPage": "122",
                                    "children": [],
                                    "endPage": "123"
                                },
                                {
                                    "id": 209,
                                    "level": "4",
                                    "title": "2.6.2 Selection and arrangement of devices",
                                    "startPage": "123",
                                    "children": [],
                                    "endPage": "126"
                                },
                                {
                                    "id": 210,
                                    "level": "4",
                                    "title": "2.6.3 Additional protection by residual current devices",
                                    "startPage": "126",
                                    "children": [],
                                    "endPage": "134"
                                }
                            ],
                            "endPage": "134"
                        },
                        {
                            "id": 211,
                            "level": "3",
                            "title": "2.7 PROTECTION AGAINST OVERVOLTAGE",
                            "startPage": "134",
                            "children": [
                                {
                                    "id": 212,
                                    "level": "4",
                                    "title": "2.7.1 General",
                                    "startPage": "134",
                                    "children": [],
                                    "endPage": "134"
                                },
                                {
                                    "id": 213,
                                    "level": "4",
                                    "title": "2.7.2 Protection by insulation or separation",
                                    "startPage": "134",
                                    "children": [],
                                    "endPage": "135"
                                },
                                {
                                    "id": 214,
                                    "level": "4",
                                    "title": "2.7.3 Protection by protective devices",
                                    "startPage": "135",
                                    "children": [],
                                    "endPage": "135"
                                }
                            ],
                            "endPage": "135"
                        },
                        {
                            "id": 215,
                            "level": "3",
                            "title": "2.8 PROTECTION AGAINST UNDERVOLTAGE",
                            "startPage": "135",
                            "children": [
                                {
                                    "id": 216,
                                    "level": "4",
                                    "title": "2.8.1 General",
                                    "startPage": "135",
                                    "children": [],
                                    "endPage": "136"
                                },
                                {
                                    "id": 217,
                                    "level": "4",
                                    "title": "2.8.2 Selection of protective device",
                                    "startPage": "136",
                                    "children": [],
                                    "endPage": "136"
                                }
                            ],
                            "endPage": "136"
                        },
                        {
                            "id": 218,
                            "level": "3",
                            "title": "2.9 PROTECTION AGAINST FIRE HAZARD DUE TO ARCING FAULTS",
                            "startPage": "136",
                            "children": [
                                {
                                    "id": 219,
                                    "level": "4",
                                    "title": "2.9.1 General",
                                    "startPage": "136",
                                    "children": [],
                                    "endPage": "136"
                                },
                                {
                                    "id": 220,
                                    "level": "4",
                                    "title": "2.9.2 Type",
                                    "startPage": "136",
                                    "children": [],
                                    "endPage": "137"
                                },
                                {
                                    "id": 221,
                                    "level": "4",
                                    "title": "2.9.3 Rating",
                                    "startPage": "137",
                                    "children": [],
                                    "endPage": "137"
                                },
                                {
                                    "id": 222,
                                    "level": "4",
                                    "title": "2.9.4 Arrangement",
                                    "startPage": "137",
                                    "children": [],
                                    "endPage": "137"
                                },
                                {
                                    "id": 223,
                                    "level": "4",
                                    "title": "2.9.5 Alterations",
                                    "startPage": "137",
                                    "children": [],
                                    "endPage": "137"
                                },
                                {
                                    "id": 224,
                                    "level": "4",
                                    "title": "2.9.6 Final subcircuits in Australia",
                                    "startPage": "137",
                                    "children": [],
                                    "endPage": "137"
                                },
                                {
                                    "id": 225,
                                    "level": "4",
                                    "title": "2.9.7 Final subcircuits in New Zealand",
                                    "startPage": "137",
                                    "children": [],
                                    "endPage": "137"
                                }
                            ],
                            "endPage": "137"
                        },
                        {
                            "id": 226,
                            "level": "3",
                            "title": "2.10 SWITCHBOARDS",
                            "startPage": "137",
                            "children": [
                                {
                                    "id": 227,
                                    "level": "4",
                                    "title": "2.10.1 General",
                                    "startPage": "137",
                                    "children": [],
                                    "endPage": "138"
                                },
                                {
                                    "id": 228,
                                    "level": "4",
                                    "title": "2.10.2 Location of switchboards",
                                    "startPage": "138",
                                    "children": [],
                                    "endPage": "146"
                                },
                                {
                                    "id": 229,
                                    "level": "4",
                                    "title": "2.10.3 Construction",
                                    "startPage": "146",
                                    "children": [],
                                    "endPage": "148"
                                },
                                {
                                    "id": 230,
                                    "level": "4",
                                    "title": "2.10.4 Bars",
                                    "startPage": "148",
                                    "children": [],
                                    "endPage": "150"
                                },
                                {
                                    "id": 231,
                                    "level": "4",
                                    "title": "2.10.5 Equipment identification",
                                    "startPage": "150",
                                    "children": [],
                                    "endPage": "151"
                                },
                                {
                                    "id": 232,
                                    "level": "4",
                                    "title": "2.10.6 Wiring",
                                    "startPage": "151",
                                    "children": [],
                                    "endPage": "151"
                                },
                                {
                                    "id": 233,
                                    "level": "4",
                                    "title": "2.10.7 Fire-protective measures",
                                    "startPage": "151",
                                    "children": [],
                                    "endPage": "153"
                                }
                            ],
                            "endPage": "153"
                        }
                    ],
                    "endPage": "153"
                },
                {
                    "id": 234,
                    "level": "2",
                    "title": "SECTION 3 SELECTION AND INSTALLATION OF WIRING SYSTEMS",
                    "startPage": "153",
                    "children": [
                        {
                            "id": 235,
                            "level": "3",
                            "title": "3.1 GENERAL",
                            "startPage": "153",
                            "children": [
                                {
                                    "id": 236,
                                    "level": "4",
                                    "title": "3.1.1 Application",
                                    "startPage": "153",
                                    "children": [],
                                    "endPage": "153"
                                },
                                {
                                    "id": 237,
                                    "level": "4",
                                    "title": "3.1.2 Selection and installation",
                                    "startPage": "153",
                                    "children": [],
                                    "endPage": "153"
                                }
                            ],
                            "endPage": "153"
                        },
                        {
                            "id": 238,
                            "level": "3",
                            "title": "3.2 TYPES OF WIRING SYSTEMS",
                            "startPage": "153",
                            "children": [],
                            "endPage": "154"
                        },
                        {
                            "id": 239,
                            "level": "3",
                            "title": "3.3 EXTERNAL INFLUENCES",
                            "startPage": "154",
                            "children": [
                                {
                                    "id": 240,
                                    "level": "4",
                                    "title": "3.3.1 General",
                                    "startPage": "154",
                                    "children": [],
                                    "endPage": "154"
                                },
                                {
                                    "id": 241,
                                    "level": "4",
                                    "title": "3.3.2 Particular influences",
                                    "startPage": "154",
                                    "children": [],
                                    "endPage": "159"
                                }
                            ],
                            "endPage": "159"
                        },
                        {
                            "id": 242,
                            "level": "3",
                            "title": "3.4 CURRENT-CARRYING CAPACITY",
                            "startPage": "159",
                            "children": [
                                {
                                    "id": 243,
                                    "level": "4",
                                    "title": "3.4.1 General",
                                    "startPage": "159",
                                    "children": [],
                                    "endPage": "160"
                                },
                                {
                                    "id": 244,
                                    "level": "4",
                                    "title": "3.4.2 Operating temperature limits",
                                    "startPage": "160",
                                    "children": [],
                                    "endPage": "162"
                                },
                                {
                                    "id": 245,
                                    "level": "4",
                                    "title": "3.4.3 Conductors in parallel",
                                    "startPage": "162",
                                    "children": [],
                                    "endPage": "163"
                                },
                                {
                                    "id": 246,
                                    "level": "4",
                                    "title": "3.4.4 Coordination between conductors and protective devices",
                                    "startPage": "163",
                                    "children": [],
                                    "endPage": "163"
                                }
                            ],
                            "endPage": "163"
                        },
                        {
                            "id": 247,
                            "level": "3",
                            "title": "3.5 CONDUCTOR SIZE",
                            "startPage": "163",
                            "children": [
                                {
                                    "id": 248,
                                    "level": "4",
                                    "title": "3.5.1 General",
                                    "startPage": "163",
                                    "children": [],
                                    "endPage": "164"
                                },
                                {
                                    "id": 249,
                                    "level": "4",
                                    "title": "3.5.2 Neutral conductor",
                                    "startPage": "164",
                                    "children": [],
                                    "endPage": "166"
                                },
                                {
                                    "id": 250,
                                    "level": "4",
                                    "title": "3.5.3 Earthing conductor",
                                    "startPage": "166",
                                    "children": [],
                                    "endPage": "166"
                                }
                            ],
                            "endPage": "166"
                        },
                        {
                            "id": 251,
                            "level": "3",
                            "title": "3.6 VOLTAGE DROP",
                            "startPage": "166",
                            "children": [
                                {
                                    "id": 252,
                                    "level": "4",
                                    "title": "3.6.1 General",
                                    "startPage": "166",
                                    "children": [],
                                    "endPage": "166"
                                },
                                {
                                    "id": 253,
                                    "level": "4",
                                    "title": "3.6.2 Value",
                                    "startPage": "166",
                                    "children": [],
                                    "endPage": "167"
                                },
                                {
                                    "id": 254,
                                    "level": "4",
                                    "title": "3.6.3 Conductors in parallel",
                                    "startPage": "167",
                                    "children": [],
                                    "endPage": "167"
                                }
                            ],
                            "endPage": "167"
                        },
                        {
                            "id": 255,
                            "level": "3",
                            "title": "3.7 ELECTRICAL CONNECTIONS",
                            "startPage": "167",
                            "children": [
                                {
                                    "id": 256,
                                    "level": "4",
                                    "title": "3.7.1 General",
                                    "startPage": "167",
                                    "children": [],
                                    "endPage": "167"
                                },
                                {
                                    "id": 257,
                                    "level": "4",
                                    "title": "3.7.2 Connection methods",
                                    "startPage": "167",
                                    "children": [],
                                    "endPage": "171"
                                },
                                {
                                    "id": 258,
                                    "level": "4",
                                    "title": "3.7.3 Joints in cables",
                                    "startPage": "171",
                                    "children": [],
                                    "endPage": "171"
                                },
                                {
                                    "id": 259,
                                    "level": "4",
                                    "title": "3.7.4 Installation couplers",
                                    "startPage": "171",
                                    "children": [],
                                    "endPage": "171"
                                }
                            ],
                            "endPage": "171"
                        },
                        {
                            "id": 260,
                            "level": "3",
                            "title": "3.8 IDENTIFICATION",
                            "startPage": "171",
                            "children": [
                                {
                                    "id": 261,
                                    "level": "4",
                                    "title": "3.8.1 General",
                                    "startPage": "171",
                                    "children": [],
                                    "endPage": "172"
                                },
                                {
                                    "id": 262,
                                    "level": "4",
                                    "title": "3.8.2 Colour identification",
                                    "startPage": "172",
                                    "children": [],
                                    "endPage": "173"
                                },
                                {
                                    "id": 263,
                                    "level": "4",
                                    "title": "3.8.3 Exceptions and special applications",
                                    "startPage": "173",
                                    "children": [],
                                    "endPage": "175"
                                }
                            ],
                            "endPage": "175"
                        },
                        {
                            "id": 264,
                            "level": "3",
                            "title": "3.9 INSTALLATION REQUIREMENTS",
                            "startPage": "175",
                            "children": [
                                {
                                    "id": 265,
                                    "level": "4",
                                    "title": "3.9.1 General",
                                    "startPage": "175",
                                    "children": [],
                                    "endPage": "176"
                                },
                                {
                                    "id": 266,
                                    "level": "4",
                                    "title": "3.9.2 Methods of installation",
                                    "startPage": "176",
                                    "children": [],
                                    "endPage": "176"
                                },
                                {
                                    "id": 267,
                                    "level": "4",
                                    "title": "3.9.3 Support and fixing",
                                    "startPage": "176",
                                    "children": [],
                                    "endPage": "177"
                                },
                                {
                                    "id": 268,
                                    "level": "4",
                                    "title": "3.9.4 Protection against mechanical damage",
                                    "startPage": "177",
                                    "children": [],
                                    "endPage": "181"
                                },
                                {
                                    "id": 269,
                                    "level": "4",
                                    "title": "3.9.5 Wiring systems installed vertically",
                                    "startPage": "181",
                                    "children": [],
                                    "endPage": "181"
                                },
                                {
                                    "id": 270,
                                    "level": "4",
                                    "title": "3.9.6 Change of direction",
                                    "startPage": "181",
                                    "children": [],
                                    "endPage": "181"
                                },
                                {
                                    "id": 271,
                                    "level": "4",
                                    "title": "3.9.7 Particular installation requirements",
                                    "startPage": "181",
                                    "children": [],
                                    "endPage": "185"
                                },
                                {
                                    "id": 272,
                                    "level": "4",
                                    "title": "3.9.8 Prevention of mutual detrimental effects between services",
                                    "startPage": "185",
                                    "children": [],
                                    "endPage": "188"
                                },
                                {
                                    "id": 273,
                                    "level": "4",
                                    "title": "3.9.9 Selection and installation to minimize the spread of fire",
                                    "startPage": "188",
                                    "children": [],
                                    "endPage": "191"
                                },
                                {
                                    "id": 274,
                                    "level": "4",
                                    "title": "3.9.10 Limitation of circulating and eddy currents",
                                    "startPage": "191",
                                    "children": [],
                                    "endPage": "192"
                                },
                                {
                                    "id": 275,
                                    "level": "4",
                                    "title": "3.9.11 Minimization of electromagnetic interference",
                                    "startPage": "192",
                                    "children": [],
                                    "endPage": "193"
                                }
                            ],
                            "endPage": "193"
                        },
                        {
                            "id": 276,
                            "level": "3",
                            "title": "3.10 ENCLOSURE OF CABLES",
                            "startPage": "193",
                            "children": [
                                {
                                    "id": 277,
                                    "level": "4",
                                    "title": "3.10.1 General",
                                    "startPage": "193",
                                    "children": [],
                                    "endPage": "193"
                                },
                                {
                                    "id": 278,
                                    "level": "4",
                                    "title": "3.10.2 Wiring enclosures",
                                    "startPage": "193",
                                    "children": [],
                                    "endPage": "194"
                                },
                                {
                                    "id": 279,
                                    "level": "4",
                                    "title": "3.10.3 Installation of wiring enclosures",
                                    "startPage": "194",
                                    "children": [],
                                    "endPage": "196"
                                }
                            ],
                            "endPage": "196"
                        },
                        {
                            "id": 280,
                            "level": "3",
                            "title": "3.11 UNDERGROUND WIRING SYSTEMS",
                            "startPage": "196",
                            "children": [
                                {
                                    "id": 281,
                                    "level": "4",
                                    "title": "3.11.1 Suitability and protection",
                                    "startPage": "196",
                                    "children": [],
                                    "endPage": "196"
                                },
                                {
                                    "id": 282,
                                    "level": "4",
                                    "title": "3.11.2 Classification of wiring systems",
                                    "startPage": "196",
                                    "children": [],
                                    "endPage": "197"
                                },
                                {
                                    "id": 283,
                                    "level": "4",
                                    "title": "3.11.3 Arrangements",
                                    "startPage": "197",
                                    "children": [],
                                    "endPage": "200"
                                },
                                {
                                    "id": 284,
                                    "level": "4",
                                    "title": "3.11.4 Installation requirements",
                                    "startPage": "200",
                                    "children": [],
                                    "endPage": "206"
                                },
                                {
                                    "id": 285,
                                    "level": "4",
                                    "title": "3.11.5 Spacing from other underground services",
                                    "startPage": "206",
                                    "children": [],
                                    "endPage": "207"
                                }
                            ],
                            "endPage": "207"
                        },
                        {
                            "id": 286,
                            "level": "3",
                            "title": "3.12 AERIAL WIRING SYSTEMS",
                            "startPage": "207",
                            "children": [
                                {
                                    "id": 287,
                                    "level": "4",
                                    "title": "3.12.1 Types of conductor",
                                    "startPage": "207",
                                    "children": [],
                                    "endPage": "207"
                                },
                                {
                                    "id": 288,
                                    "level": "4",
                                    "title": "3.12.2 Arrangements",
                                    "startPage": "207",
                                    "children": [],
                                    "endPage": "208"
                                },
                                {
                                    "id": 289,
                                    "level": "4",
                                    "title": "3.12.3 Clearances",
                                    "startPage": "208",
                                    "children": [],
                                    "endPage": "210"
                                },
                                {
                                    "id": 290,
                                    "level": "4",
                                    "title": "3.12.4 Distance between supports (spans)",
                                    "startPage": "210",
                                    "children": [],
                                    "endPage": "210"
                                },
                                {
                                    "id": 291,
                                    "level": "4",
                                    "title": "3.12.5 Aerial conductor supports",
                                    "startPage": "210",
                                    "children": [],
                                    "endPage": "211"
                                },
                                {
                                    "id": 292,
                                    "level": "4",
                                    "title": "3.12.6 Poles and posts (including supports, struts and extensions to structures)",
                                    "startPage": "211",
                                    "children": [],
                                    "endPage": "212"
                                },
                                {
                                    "id": 293,
                                    "level": "4",
                                    "title": "3.12.7 Joints and connections",
                                    "startPage": "212",
                                    "children": [],
                                    "endPage": "212"
                                }
                            ],
                            "endPage": "212"
                        },
                        {
                            "id": 294,
                            "level": "3",
                            "title": "3.13 CABLES SUPPORTED BY A CATENARY",
                            "startPage": "212",
                            "children": [
                                {
                                    "id": 295,
                                    "level": "4",
                                    "title": "3.13.1 Types of cables",
                                    "startPage": "212",
                                    "children": [],
                                    "endPage": "212"
                                },
                                {
                                    "id": 296,
                                    "level": "4",
                                    "title": "3.13.2 Catenary supports",
                                    "startPage": "212",
                                    "children": [],
                                    "endPage": "212"
                                },
                                {
                                    "id": 297,
                                    "level": "4",
                                    "title": "3.13.3 Clearances",
                                    "startPage": "212",
                                    "children": [],
                                    "endPage": "212"
                                }
                            ],
                            "endPage": "212"
                        },
                        {
                            "id": 298,
                            "level": "3",
                            "title": "3.14 SAFETY SERVICES",
                            "startPage": "212",
                            "children": [],
                            "endPage": "213"
                        },
                        {
                            "id": 299,
                            "level": "3",
                            "title": "3.15 BUSWAYS, INCLUDING RISING MAINS SYSTEMS",
                            "startPage": "213",
                            "children": [],
                            "endPage": "213"
                        },
                        {
                            "id": 300,
                            "level": "3",
                            "title": "3.16 EARTH SHEATH RETURN (ESR) SYSTEM",
                            "startPage": "213",
                            "children": [],
                            "endPage": "215"
                        }
                    ],
                    "endPage": "215"
                },
                {
                    "id": 301,
                    "level": "2",
                    "title": "SECTION 4 SELECTION AND INSTALLATION OF ELECTRICAL EQUIPMENT",
                    "startPage": "215",
                    "children": [
                        {
                            "id": 302,
                            "level": "3",
                            "title": "4.1 GENERAL",
                            "startPage": "215",
                            "children": [
                                {
                                    "id": 303,
                                    "level": "4",
                                    "title": "4.1.1 Application",
                                    "startPage": "215",
                                    "children": [],
                                    "endPage": "215"
                                },
                                {
                                    "id": 304,
                                    "level": "4",
                                    "title": "4.1.2 Selection and installation",
                                    "startPage": "215",
                                    "children": [],
                                    "endPage": "216"
                                },
                                {
                                    "id": 305,
                                    "level": "4",
                                    "title": "4.1.3 External influences",
                                    "startPage": "216",
                                    "children": [],
                                    "endPage": "218"
                                },
                                {
                                    "id": 306,
                                    "level": "4",
                                    "title": "4.1.4 Adverse effects and interference",
                                    "startPage": "218",
                                    "children": [],
                                    "endPage": "219"
                                },
                                {
                                    "id": 307,
                                    "level": "4",
                                    "title": "4.1.5 Air extraction systems",
                                    "startPage": "219",
                                    "children": [],
                                    "endPage": "219"
                                }
                            ],
                            "endPage": "219"
                        },
                        {
                            "id": 308,
                            "level": "3",
                            "title": "4.2 PROTECTION AGAINST THERMAL EFFECTS",
                            "startPage": "219",
                            "children": [
                                {
                                    "id": 309,
                                    "level": "4",
                                    "title": "4.2.1 General",
                                    "startPage": "219",
                                    "children": [],
                                    "endPage": "219"
                                },
                                {
                                    "id": 310,
                                    "level": "4",
                                    "title": "4.2.2 Prevention of fire hazard",
                                    "startPage": "219",
                                    "children": [],
                                    "endPage": "221"
                                },
                                {
                                    "id": 311,
                                    "level": "4",
                                    "title": "4.2.3 Protection against burns",
                                    "startPage": "221",
                                    "children": [],
                                    "endPage": "222"
                                }
                            ],
                            "endPage": "222"
                        },
                        {
                            "id": 312,
                            "level": "3",
                            "title": "4.3 CONNECTION OF ELECTRICAL EQUIPMENT",
                            "startPage": "222",
                            "children": [
                                {
                                    "id": 313,
                                    "level": "4",
                                    "title": "4.3.1 General",
                                    "startPage": "222",
                                    "children": [],
                                    "endPage": "222"
                                },
                                {
                                    "id": 314,
                                    "level": "4",
                                    "title": "4.3.2 Direct connection",
                                    "startPage": "222",
                                    "children": [],
                                    "endPage": "223"
                                },
                                {
                                    "id": 315,
                                    "level": "4",
                                    "title": "4.3.3 Installation wiring connected by an installation coupler(s)",
                                    "startPage": "223",
                                    "children": [],
                                    "endPage": "224"
                                },
                                {
                                    "id": 316,
                                    "level": "4",
                                    "title": "4.3.4 Socket-outlets in installation wiring",
                                    "startPage": "224",
                                    "children": [],
                                    "endPage": "224"
                                },
                                {
                                    "id": 317,
                                    "level": "4",
                                    "title": "4.3.5 Other connecting devices",
                                    "startPage": "224",
                                    "children": [],
                                    "endPage": "225"
                                },
                                {
                                    "id": 318,
                                    "level": "4",
                                    "title": "4.3.6 Equipment wiring",
                                    "startPage": "225",
                                    "children": [],
                                    "endPage": "226"
                                }
                            ],
                            "endPage": "226"
                        },
                        {
                            "id": 319,
                            "level": "3",
                            "title": "4.4 SOCKET-OUTLETS",
                            "startPage": "226",
                            "children": [
                                {
                                    "id": 320,
                                    "level": "4",
                                    "title": "4.4.1 Types",
                                    "startPage": "226",
                                    "children": [],
                                    "endPage": "228"
                                },
                                {
                                    "id": 321,
                                    "level": "4",
                                    "title": "4.4.2 Location",
                                    "startPage": "228",
                                    "children": [],
                                    "endPage": "229"
                                },
                                {
                                    "id": 322,
                                    "level": "4",
                                    "title": "4.4.3 Earthing contacts",
                                    "startPage": "229",
                                    "children": [],
                                    "endPage": "229"
                                },
                                {
                                    "id": 323,
                                    "level": "4",
                                    "title": "4.4.4 Switching device",
                                    "startPage": "229",
                                    "children": [],
                                    "endPage": "231"
                                },
                                {
                                    "id": 324,
                                    "level": "4",
                                    "title": "4.4.5 Polarization and phase sequence",
                                    "startPage": "231",
                                    "children": [],
                                    "endPage": "231"
                                }
                            ],
                            "endPage": "231"
                        },
                        {
                            "id": 325,
                            "level": "3",
                            "title": "4.5 LIGHTING EQUIPMENT AND ACCESSORIES",
                            "startPage": "231",
                            "children": [
                                {
                                    "id": 326,
                                    "level": "4",
                                    "title": "4.5.1 Lampholders, including lampholders incorporated in a luminaire",
                                    "startPage": "231",
                                    "children": [],
                                    "endPage": "232"
                                },
                                {
                                    "id": 327,
                                    "level": "4",
                                    "title": "4.5.2 Lamps and luminaires",
                                    "startPage": "232",
                                    "children": [],
                                    "endPage": "246"
                                }
                            ],
                            "endPage": "246"
                        },
                        {
                            "id": 328,
                            "level": "3",
                            "title": "4.6 SMOKE ALARMS",
                            "startPage": "246",
                            "children": [],
                            "endPage": "246"
                        },
                        {
                            "id": 329,
                            "level": "3",
                            "title": "4.7 COOKING APPLIANCES",
                            "startPage": "246",
                            "children": [
                                {
                                    "id": 330,
                                    "level": "4",
                                    "title": "4.7.1 Switching device",
                                    "startPage": "246",
                                    "children": [],
                                    "endPage": "247"
                                },
                                {
                                    "id": 331,
                                    "level": "4",
                                    "title": "4.7.2 Connection-New Zealand only",
                                    "startPage": "247",
                                    "children": [],
                                    "endPage": "247"
                                },
                                {
                                    "id": 332,
                                    "level": "4",
                                    "title": "4.7.3 Clearance from open cooking surfaces",
                                    "startPage": "247",
                                    "children": [],
                                    "endPage": "248"
                                }
                            ],
                            "endPage": "248"
                        },
                        {
                            "id": 333,
                            "level": "3",
                            "title": "4.8 APPLIANCES PRODUCING HOT WATER OR STEAM",
                            "startPage": "248",
                            "children": [
                                {
                                    "id": 334,
                                    "level": "4",
                                    "title": "4.8.1 General",
                                    "startPage": "248",
                                    "children": [],
                                    "endPage": "249"
                                },
                                {
                                    "id": 335,
                                    "level": "4",
                                    "title": "4.8.2 Water heaters",
                                    "startPage": "249",
                                    "children": [],
                                    "endPage": "249"
                                }
                            ],
                            "endPage": "249"
                        },
                        {
                            "id": 336,
                            "level": "3",
                            "title": "4.9 ROOM HEATERS",
                            "startPage": "249",
                            "children": [
                                {
                                    "id": 337,
                                    "level": "4",
                                    "title": "4.9.1 General",
                                    "startPage": "249",
                                    "children": [],
                                    "endPage": "250"
                                },
                                {
                                    "id": 338,
                                    "level": "4",
                                    "title": "4.9.2 Isolating switches",
                                    "startPage": "250",
                                    "children": [],
                                    "endPage": "250"
                                },
                                {
                                    "id": 339,
                                    "level": "4",
                                    "title": "4.9.3 Functional switches",
                                    "startPage": "250",
                                    "children": [],
                                    "endPage": "250"
                                }
                            ],
                            "endPage": "250"
                        },
                        {
                            "id": 340,
                            "level": "3",
                            "title": "4.10 ELECTRIC HEATING CABLES FOR FLOORS AND CEILINGS AND TRACE HEATING APPLICATIONS",
                            "startPage": "250",
                            "children": [
                                {
                                    "id": 341,
                                    "level": "4",
                                    "title": "4.10.1 General",
                                    "startPage": "250",
                                    "children": [],
                                    "endPage": "250"
                                },
                                {
                                    "id": 342,
                                    "level": "4",
                                    "title": "4.10.2 Heating cables",
                                    "startPage": "250",
                                    "children": [],
                                    "endPage": "251"
                                },
                                {
                                    "id": 343,
                                    "level": "4",
                                    "title": "4.10.3 Isolating switches",
                                    "startPage": "251",
                                    "children": [],
                                    "endPage": "251"
                                },
                                {
                                    "id": 344,
                                    "level": "4",
                                    "title": "4.10.4 Functional switches",
                                    "startPage": "251",
                                    "children": [],
                                    "endPage": "251"
                                },
                                {
                                    "id": 345,
                                    "level": "4",
                                    "title": "4.10.5 Additional protection",
                                    "startPage": "251",
                                    "children": [],
                                    "endPage": "252"
                                },
                                {
                                    "id": 346,
                                    "level": "4",
                                    "title": "4.10.6 Signs",
                                    "startPage": "252",
                                    "children": [],
                                    "endPage": "252"
                                }
                            ],
                            "endPage": "252"
                        },
                        {
                            "id": 347,
                            "level": "3",
                            "title": "4.11 ELECTRIC DUCT HEATERS",
                            "startPage": "252",
                            "children": [],
                            "endPage": "252"
                        },
                        {
                            "id": 348,
                            "level": "3",
                            "title": "4.12 ELECTRICITY CONVERTERS",
                            "startPage": "252",
                            "children": [
                                {
                                    "id": 349,
                                    "level": "4",
                                    "title": "4.12.1 General",
                                    "startPage": "252",
                                    "children": [],
                                    "endPage": "253"
                                },
                                {
                                    "id": 350,
                                    "level": "4",
                                    "title": "4.12.2 Selection and installation",
                                    "startPage": "253",
                                    "children": [],
                                    "endPage": "253"
                                },
                                {
                                    "id": 351,
                                    "level": "4",
                                    "title": "4.12.3 Control",
                                    "startPage": "253",
                                    "children": [],
                                    "endPage": "253"
                                },
                                {
                                    "id": 352,
                                    "level": "4",
                                    "title": "4.12.4 Isolation",
                                    "startPage": "253",
                                    "children": [],
                                    "endPage": "254"
                                },
                                {
                                    "id": 353,
                                    "level": "4",
                                    "title": "4.12.5 Overcurrent protection",
                                    "startPage": "254",
                                    "children": [],
                                    "endPage": "255"
                                },
                                {
                                    "id": 354,
                                    "level": "4",
                                    "title": "4.12.6 Earthing",
                                    "startPage": "255",
                                    "children": [],
                                    "endPage": "255"
                                },
                                {
                                    "id": 355,
                                    "level": "4",
                                    "title": "4.12.7 Neutral continuity",
                                    "startPage": "255",
                                    "children": [],
                                    "endPage": "255"
                                },
                                {
                                    "id": 356,
                                    "level": "4",
                                    "title": "4.12.8 Electrical equipment connected to output",
                                    "startPage": "255",
                                    "children": [],
                                    "endPage": "256"
                                }
                            ],
                            "endPage": "256"
                        },
                        {
                            "id": 357,
                            "level": "3",
                            "title": "4.13 MOTORS",
                            "startPage": "256",
                            "children": [
                                {
                                    "id": 358,
                                    "level": "4",
                                    "title": "4.13.1 Protection against injury from mechanical movement",
                                    "startPage": "256",
                                    "children": [],
                                    "endPage": "257"
                                },
                                {
                                    "id": 359,
                                    "level": "4",
                                    "title": "4.13.2 Protection against overload",
                                    "startPage": "257",
                                    "children": [],
                                    "endPage": "258"
                                },
                                {
                                    "id": 360,
                                    "level": "4",
                                    "title": "4.13.3 Protection against overtemperature",
                                    "startPage": "258",
                                    "children": [],
                                    "endPage": "259"
                                }
                            ],
                            "endPage": "259"
                        },
                        {
                            "id": 361,
                            "level": "3",
                            "title": "4.14 TRANSFORMERS",
                            "startPage": "259",
                            "children": [
                                {
                                    "id": 362,
                                    "level": "4",
                                    "title": "4.14.1 General",
                                    "startPage": "259",
                                    "children": [],
                                    "endPage": "259"
                                },
                                {
                                    "id": 363,
                                    "level": "4",
                                    "title": "4.14.2 Secondary circuit",
                                    "startPage": "259",
                                    "children": [],
                                    "endPage": "260"
                                },
                                {
                                    "id": 364,
                                    "level": "4",
                                    "title": "4.14.3 Low voltage transformer supply",
                                    "startPage": "260",
                                    "children": [],
                                    "endPage": "260"
                                },
                                {
                                    "id": 365,
                                    "level": "4",
                                    "title": "4.14.4 Autotransformers",
                                    "startPage": "260",
                                    "children": [],
                                    "endPage": "260"
                                },
                                {
                                    "id": 366,
                                    "level": "4",
                                    "title": "4.14.5 Step-up transformers",
                                    "startPage": "260",
                                    "children": [],
                                    "endPage": "260"
                                }
                            ],
                            "endPage": "260"
                        },
                        {
                            "id": 367,
                            "level": "3",
                            "title": "4.15 CAPACITORS",
                            "startPage": "260",
                            "children": [
                                {
                                    "id": 368,
                                    "level": "4",
                                    "title": "4.15.1 General",
                                    "startPage": "260",
                                    "children": [],
                                    "endPage": "260"
                                },
                                {
                                    "id": 369,
                                    "level": "4",
                                    "title": "4.15.2 Electrical equipment",
                                    "startPage": "260",
                                    "children": [],
                                    "endPage": "261"
                                },
                                {
                                    "id": 370,
                                    "level": "4",
                                    "title": "4.15.3 Provision for discharge and control",
                                    "startPage": "261",
                                    "children": [],
                                    "endPage": "263"
                                }
                            ],
                            "endPage": "263"
                        },
                        {
                            "id": 371,
                            "level": "3",
                            "title": "4.16 ELECTRICAL EQUIPMENT CONTAINING LIQUID DIELECTRICS",
                            "startPage": "263",
                            "children": [
                                {
                                    "id": 372,
                                    "level": "4",
                                    "title": "4.16.1 General",
                                    "startPage": "263",
                                    "children": [],
                                    "endPage": "263"
                                },
                                {
                                    "id": 373,
                                    "level": "4",
                                    "title": "4.16.2 Liquid dielectrics having a flashpoint not exceeding 250&#176;C",
                                    "startPage": "263",
                                    "children": [],
                                    "endPage": "263"
                                }
                            ],
                            "endPage": "263"
                        },
                        {
                            "id": 374,
                            "level": "3",
                            "title": "4.17 BATTERIES",
                            "startPage": "263",
                            "children": [],
                            "endPage": "263"
                        },
                        {
                            "id": 375,
                            "level": "3",
                            "title": "4.18 GAS APPLIANCES AND EQUIPMENT",
                            "startPage": "263",
                            "children": [
                                {
                                    "id": 376,
                                    "level": "4",
                                    "title": "4.18.1 Gas appliances",
                                    "startPage": "263",
                                    "children": [],
                                    "endPage": "264"
                                },
                                {
                                    "id": 377,
                                    "level": "4",
                                    "title": "4.18.2 Gas cylinders containing heavier-than-air gases-Outdoors",
                                    "startPage": "264",
                                    "children": [],
                                    "endPage": "265"
                                },
                                {
                                    "id": 378,
                                    "level": "4",
                                    "title": "4.18.3 Gas cylinders-Indoors-New Zealand only",
                                    "startPage": "265",
                                    "children": [],
                                    "endPage": "265"
                                },
                                {
                                    "id": 379,
                                    "level": "4",
                                    "title": "4.18.4 Gas relief vent terminal-Natural gas, LP Gas or Biogas",
                                    "startPage": "265",
                                    "children": [],
                                    "endPage": "266"
                                },
                                {
                                    "id": 380,
                                    "level": "4",
                                    "title": "4.18.5 Reticulated lighter-than-air gas system, metering system and regulators-New Zealand only",
                                    "startPage": "266",
                                    "children": [],
                                    "endPage": "267"
                                }
                            ],
                            "endPage": "267"
                        },
                        {
                            "id": 381,
                            "level": "3",
                            "title": "4.19 AIRCONDITIONING AND HEAT PUMP SYSTEMS",
                            "startPage": "267",
                            "children": [],
                            "endPage": "268"
                        },
                        {
                            "id": 382,
                            "level": "3",
                            "title": "4.20 LIFTS",
                            "startPage": "268",
                            "children": [
                                {
                                    "id": 383,
                                    "level": "4",
                                    "title": "4.20.1 General",
                                    "startPage": "268",
                                    "children": [],
                                    "endPage": "268"
                                },
                                {
                                    "id": 384,
                                    "level": "4",
                                    "title": "4.20.2 Lift supply arrangement",
                                    "startPage": "268",
                                    "children": [],
                                    "endPage": "268"
                                },
                                {
                                    "id": 385,
                                    "level": "4",
                                    "title": "4.20.3 Labelling",
                                    "startPage": "268",
                                    "children": [],
                                    "endPage": "268"
                                },
                                {
                                    "id": 386,
                                    "level": "4",
                                    "title": "4.20.4 Motor-room-less lifts (MRLs)",
                                    "startPage": "268",
                                    "children": [],
                                    "endPage": "270"
                                }
                            ],
                            "endPage": "270"
                        }
                    ],
                    "endPage": "270"
                },
                {
                    "id": 387,
                    "level": "2",
                    "title": "SECTION 5 EARTHING ARRANGEMENTS AND EARTHING CONDUCTORS",
                    "startPage": "270",
                    "children": [
                        {
                            "id": 388,
                            "level": "3",
                            "title": "5.1 GENERAL",
                            "startPage": "270",
                            "children": [
                                {
                                    "id": 389,
                                    "level": "4",
                                    "title": "5.1.1 Application",
                                    "startPage": "270",
                                    "children": [],
                                    "endPage": "270"
                                },
                                {
                                    "id": 390,
                                    "level": "4",
                                    "title": "5.1.2 Selection and installation",
                                    "startPage": "270",
                                    "children": [],
                                    "endPage": "270"
                                },
                                {
                                    "id": 391,
                                    "level": "4",
                                    "title": "5.1.3 MEN earthing system",
                                    "startPage": "270",
                                    "children": [],
                                    "endPage": "273"
                                },
                                {
                                    "id": 392,
                                    "level": "4",
                                    "title": "5.1.4 Other earthing systems",
                                    "startPage": "273",
                                    "children": [],
                                    "endPage": "274"
                                }
                            ],
                            "endPage": "274"
                        },
                        {
                            "id": 393,
                            "level": "3",
                            "title": "5.2 EARTHING FUNCTIONS",
                            "startPage": "274",
                            "children": [
                                {
                                    "id": 394,
                                    "level": "4",
                                    "title": "5.2.1 Protective earthing",
                                    "startPage": "274",
                                    "children": [],
                                    "endPage": "274"
                                },
                                {
                                    "id": 395,
                                    "level": "4",
                                    "title": "5.2.2 Functional earthing (FE)",
                                    "startPage": "274",
                                    "children": [],
                                    "endPage": "275"
                                },
                                {
                                    "id": 396,
                                    "level": "4",
                                    "title": "5.2.3 Earthing for combined protective and functional purposes",
                                    "startPage": "275",
                                    "children": [],
                                    "endPage": "275"
                                }
                            ],
                            "endPage": "275"
                        },
                        {
                            "id": 397,
                            "level": "3",
                            "title": "5.3 EARTHING SYSTEM PARTS",
                            "startPage": "275",
                            "children": [
                                {
                                    "id": 398,
                                    "level": "4",
                                    "title": "5.3.1 General",
                                    "startPage": "275",
                                    "children": [],
                                    "endPage": "276"
                                },
                                {
                                    "id": 399,
                                    "level": "4",
                                    "title": "5.3.2 Earthing conductor material and type",
                                    "startPage": "276",
                                    "children": [],
                                    "endPage": "279"
                                },
                                {
                                    "id": 400,
                                    "level": "4",
                                    "title": "5.3.3 Earthing conductor size (cross-sectional area)",
                                    "startPage": "279",
                                    "children": [],
                                    "endPage": "283"
                                },
                                {
                                    "id": 401,
                                    "level": "4",
                                    "title": "5.3.4 Main earthing terminal\/connection or bar",
                                    "startPage": "283",
                                    "children": [],
                                    "endPage": "284"
                                },
                                {
                                    "id": 402,
                                    "level": "4",
                                    "title": "5.3.5 MEN connection",
                                    "startPage": "284",
                                    "children": [],
                                    "endPage": "285"
                                },
                                {
                                    "id": 403,
                                    "level": "4",
                                    "title": "5.3.6 Earth electrodes",
                                    "startPage": "285",
                                    "children": [],
                                    "endPage": "287"
                                },
                                {
                                    "id": 404,
                                    "level": "4",
                                    "title": "5.3.7 Functional earthing conductors",
                                    "startPage": "287",
                                    "children": [],
                                    "endPage": "287"
                                }
                            ],
                            "endPage": "287"
                        },
                        {
                            "id": 405,
                            "level": "3",
                            "title": "5.4 EARTHING OF EQUIPMENT",
                            "startPage": "287",
                            "children": [
                                {
                                    "id": 406,
                                    "level": "4",
                                    "title": "5.4.1 General",
                                    "startPage": "287",
                                    "children": [],
                                    "endPage": "289"
                                },
                                {
                                    "id": 407,
                                    "level": "4",
                                    "title": "5.4.2 Socket-outlets",
                                    "startPage": "289",
                                    "children": [],
                                    "endPage": "289"
                                },
                                {
                                    "id": 408,
                                    "level": "4",
                                    "title": "5.4.3 Lighting points",
                                    "startPage": "289",
                                    "children": [],
                                    "endPage": "289"
                                },
                                {
                                    "id": 409,
                                    "level": "4",
                                    "title": "5.4.4 Luminaires",
                                    "startPage": "289",
                                    "children": [],
                                    "endPage": "290"
                                },
                                {
                                    "id": 410,
                                    "level": "4",
                                    "title": "5.4.5 Conductive supports for aerial conductors",
                                    "startPage": "290",
                                    "children": [],
                                    "endPage": "290"
                                },
                                {
                                    "id": 411,
                                    "level": "4",
                                    "title": "5.4.6 Structural metalwork including conductive building materials",
                                    "startPage": "290",
                                    "children": [],
                                    "endPage": "291"
                                },
                                {
                                    "id": 412,
                                    "level": "4",
                                    "title": "5.4.7 Submersible pumps",
                                    "startPage": "291",
                                    "children": [],
                                    "endPage": "291"
                                },
                                {
                                    "id": 413,
                                    "level": "4",
                                    "title": "5.4.8 Variable frequency devices",
                                    "startPage": "291",
                                    "children": [],
                                    "endPage": "292"
                                }
                            ],
                            "endPage": "292"
                        },
                        {
                            "id": 414,
                            "level": "3",
                            "title": "5.5 EARTHING ARRANGEMENTS",
                            "startPage": "292",
                            "children": [
                                {
                                    "id": 415,
                                    "level": "4",
                                    "title": "5.5.1 Main earthing conductor",
                                    "startPage": "292",
                                    "children": [],
                                    "endPage": "293"
                                },
                                {
                                    "id": 416,
                                    "level": "4",
                                    "title": "5.5.2 Protective earthing conductors",
                                    "startPage": "293",
                                    "children": [],
                                    "endPage": "295"
                                },
                                {
                                    "id": 417,
                                    "level": "4",
                                    "title": "5.5.3 Particular methods of earthing",
                                    "startPage": "295",
                                    "children": [],
                                    "endPage": "303"
                                },
                                {
                                    "id": 418,
                                    "level": "4",
                                    "title": "5.5.4 Continuity",
                                    "startPage": "303",
                                    "children": [],
                                    "endPage": "304"
                                },
                                {
                                    "id": 419,
                                    "level": "4",
                                    "title": "5.5.5 Installation",
                                    "startPage": "304",
                                    "children": [],
                                    "endPage": "305"
                                },
                                {
                                    "id": 420,
                                    "level": "4",
                                    "title": "5.5.6 Connections",
                                    "startPage": "305",
                                    "children": [],
                                    "endPage": "306"
                                }
                            ],
                            "endPage": "306"
                        },
                        {
                            "id": 421,
                            "level": "3",
                            "title": "5.6 EQUIPOTENTIAL BONDING",
                            "startPage": "306",
                            "children": [
                                {
                                    "id": 422,
                                    "level": "4",
                                    "title": "5.6.1 General",
                                    "startPage": "306",
                                    "children": [],
                                    "endPage": "307"
                                },
                                {
                                    "id": 423,
                                    "level": "4",
                                    "title": "5.6.2 Arrangement",
                                    "startPage": "307",
                                    "children": [],
                                    "endPage": "314"
                                },
                                {
                                    "id": 424,
                                    "level": "4",
                                    "title": "5.6.3 Bonding conductors",
                                    "startPage": "314",
                                    "children": [],
                                    "endPage": "315"
                                }
                            ],
                            "endPage": "315"
                        },
                        {
                            "id": 425,
                            "level": "3",
                            "title": "5.7 EARTH FAULT-LOOP IMPEDANCE",
                            "startPage": "315",
                            "children": [
                                {
                                    "id": 426,
                                    "level": "4",
                                    "title": "5.7.1 General",
                                    "startPage": "315",
                                    "children": [],
                                    "endPage": "316"
                                },
                                {
                                    "id": 427,
                                    "level": "4",
                                    "title": "5.7.2 Disconnection times",
                                    "startPage": "316",
                                    "children": [],
                                    "endPage": "316"
                                },
                                {
                                    "id": 428,
                                    "level": "4",
                                    "title": "5.7.3 Earth fault-loop",
                                    "startPage": "316",
                                    "children": [],
                                    "endPage": "317"
                                },
                                {
                                    "id": 429,
                                    "level": "4",
                                    "title": "5.7.4 Impedance",
                                    "startPage": "317",
                                    "children": [],
                                    "endPage": "317"
                                },
                                {
                                    "id": 430,
                                    "level": "4",
                                    "title": "5.7.5 Supplementary equipotential bonding",
                                    "startPage": "317",
                                    "children": [],
                                    "endPage": "318"
                                }
                            ],
                            "endPage": "318"
                        },
                        {
                            "id": 431,
                            "level": "3",
                            "title": "5.8 OTHER EARTHING ARRANGEMENTS",
                            "startPage": "318",
                            "children": [],
                            "endPage": "319"
                        }
                    ],
                    "endPage": "319"
                },
                {
                    "id": 432,
                    "level": "2",
                    "title": "SECTION 6 DAMP SITUATIONS",
                    "startPage": "319",
                    "children": [
                        {
                            "id": 433,
                            "level": "3",
                            "title": "6.1 GENERAL",
                            "startPage": "319",
                            "children": [
                                {
                                    "id": 434,
                                    "level": "4",
                                    "title": "6.1.1 Application",
                                    "startPage": "319",
                                    "children": [],
                                    "endPage": "319"
                                },
                                {
                                    "id": 435,
                                    "level": "4",
                                    "title": "6.1.2 Selection and installation",
                                    "startPage": "319",
                                    "children": [],
                                    "endPage": "320"
                                }
                            ],
                            "endPage": "320"
                        },
                        {
                            "id": 436,
                            "level": "3",
                            "title": "6.2 BATHS, SHOWERS AND OTHER FIXED WATER CONTAINERS",
                            "startPage": "320",
                            "children": [
                                {
                                    "id": 437,
                                    "level": "4",
                                    "title": "6.2.1 Scope",
                                    "startPage": "320",
                                    "children": [],
                                    "endPage": "320"
                                },
                                {
                                    "id": 438,
                                    "level": "4",
                                    "title": "6.2.2 Classification of zones",
                                    "startPage": "320",
                                    "children": [],
                                    "endPage": "323"
                                },
                                {
                                    "id": 439,
                                    "level": "4",
                                    "title": "6.2.3 Protection against electric shock-Prohibited measures",
                                    "startPage": "323",
                                    "children": [],
                                    "endPage": "323"
                                },
                                {
                                    "id": 440,
                                    "level": "4",
                                    "title": "6.2.4 Selection and installation of electrical equipment",
                                    "startPage": "323",
                                    "children": [],
                                    "endPage": "339"
                                }
                            ],
                            "endPage": "339"
                        },
                        {
                            "id": 441,
                            "level": "3",
                            "title": "6.3 SWIMMING POOLS, PADDLING POOLS AND SPA POOLS OR TUBS",
                            "startPage": "339",
                            "children": [
                                {
                                    "id": 442,
                                    "level": "4",
                                    "title": "6.3.1 Scope",
                                    "startPage": "339",
                                    "children": [],
                                    "endPage": "340"
                                },
                                {
                                    "id": 443,
                                    "level": "4",
                                    "title": "6.3.2 Classification of zones",
                                    "startPage": "340",
                                    "children": [],
                                    "endPage": "341"
                                },
                                {
                                    "id": 444,
                                    "level": "4",
                                    "title": "6.3.3 Protection against electric shock",
                                    "startPage": "341",
                                    "children": [],
                                    "endPage": "342"
                                },
                                {
                                    "id": 445,
                                    "level": "4",
                                    "title": "6.3.4 Selection and installation of electrical equipment",
                                    "startPage": "342",
                                    "children": [],
                                    "endPage": "351"
                                }
                            ],
                            "endPage": "351"
                        },
                        {
                            "id": 446,
                            "level": "3",
                            "title": "6.4 FOUNTAINS AND WATER FEATURES",
                            "startPage": "351",
                            "children": [
                                {
                                    "id": 447,
                                    "level": "4",
                                    "title": "6.4.1 Scope",
                                    "startPage": "351",
                                    "children": [],
                                    "endPage": "352"
                                },
                                {
                                    "id": 448,
                                    "level": "4",
                                    "title": "6.4.2 Classification of zones",
                                    "startPage": "352",
                                    "children": [],
                                    "endPage": "352"
                                },
                                {
                                    "id": 449,
                                    "level": "4",
                                    "title": "6.4.3 Protection against electric shock",
                                    "startPage": "352",
                                    "children": [],
                                    "endPage": "353"
                                },
                                {
                                    "id": 450,
                                    "level": "4",
                                    "title": "6.4.4 Selection and installation of electrical equipment",
                                    "startPage": "353",
                                    "children": [],
                                    "endPage": "357"
                                }
                            ],
                            "endPage": "357"
                        },
                        {
                            "id": 451,
                            "level": "3",
                            "title": "6.5 SAUNAS",
                            "startPage": "357",
                            "children": [
                                {
                                    "id": 452,
                                    "level": "4",
                                    "title": "6.5.1 Scope",
                                    "startPage": "357",
                                    "children": [],
                                    "endPage": "357"
                                },
                                {
                                    "id": 453,
                                    "level": "4",
                                    "title": "6.5.2 Classification of zones",
                                    "startPage": "357",
                                    "children": [],
                                    "endPage": "358"
                                },
                                {
                                    "id": 454,
                                    "level": "4",
                                    "title": "6.5.3 Protection against electric shock",
                                    "startPage": "358",
                                    "children": [],
                                    "endPage": "358"
                                },
                                {
                                    "id": 455,
                                    "level": "4",
                                    "title": "6.5.4 Selection and installation of electrical equipment",
                                    "startPage": "358",
                                    "children": [],
                                    "endPage": "361"
                                }
                            ],
                            "endPage": "361"
                        },
                        {
                            "id": 456,
                            "level": "3",
                            "title": "6.6 REFRIGERATION ROOMS",
                            "startPage": "361",
                            "children": [
                                {
                                    "id": 457,
                                    "level": "4",
                                    "title": "6.6.1 Scope",
                                    "startPage": "361",
                                    "children": [],
                                    "endPage": "361"
                                },
                                {
                                    "id": 458,
                                    "level": "4",
                                    "title": "6.6.2 Protection against electric shock",
                                    "startPage": "361",
                                    "children": [],
                                    "endPage": "361"
                                },
                                {
                                    "id": 459,
                                    "level": "4",
                                    "title": "6.6.3 Selection and installation of electrical equipment",
                                    "startPage": "361",
                                    "children": [],
                                    "endPage": "363"
                                }
                            ],
                            "endPage": "363"
                        },
                        {
                            "id": 460,
                            "level": "3",
                            "title": "6.7 SANITIZATION AND GENERAL HOSING-DOWN OPERATIONS",
                            "startPage": "363",
                            "children": [
                                {
                                    "id": 461,
                                    "level": "4",
                                    "title": "6.7.1 Scope",
                                    "startPage": "363",
                                    "children": [],
                                    "endPage": "363"
                                },
                                {
                                    "id": 462,
                                    "level": "4",
                                    "title": "6.7.2 Classification of zone",
                                    "startPage": "363",
                                    "children": [],
                                    "endPage": "364"
                                },
                                {
                                    "id": 463,
                                    "level": "4",
                                    "title": "6.7.3 Protection against electric shock",
                                    "startPage": "364",
                                    "children": [],
                                    "endPage": "364"
                                },
                                {
                                    "id": 464,
                                    "level": "4",
                                    "title": "6.7.4 Selection and installation of electrical equipment",
                                    "startPage": "364",
                                    "children": [],
                                    "endPage": "365"
                                }
                            ],
                            "endPage": "365"
                        }
                    ],
                    "endPage": "365"
                },
                {
                    "id": 465,
                    "level": "2",
                    "title": "SECTION 7 SPECIAL ELECTRICAL INSTALLATIONS",
                    "startPage": "365",
                    "children": [
                        {
                            "id": 466,
                            "level": "3",
                            "title": "7.1 GENERAL",
                            "startPage": "365",
                            "children": [
                                {
                                    "id": 467,
                                    "level": "4",
                                    "title": "7.1.1 Application",
                                    "startPage": "365",
                                    "children": [],
                                    "endPage": "365"
                                },
                                {
                                    "id": 468,
                                    "level": "4",
                                    "title": "7.1.2 Selection and installation",
                                    "startPage": "365",
                                    "children": [],
                                    "endPage": "366"
                                }
                            ],
                            "endPage": "366"
                        },
                        {
                            "id": 469,
                            "level": "3",
                            "title": "7.2 SAFETY SERVICES",
                            "startPage": "366",
                            "children": [
                                {
                                    "id": 470,
                                    "level": "4",
                                    "title": "7.2.1 Scope and general",
                                    "startPage": "366",
                                    "children": [],
                                    "endPage": "367"
                                },
                                {
                                    "id": 471,
                                    "level": "4",
                                    "title": "7.2.2 Supply systems",
                                    "startPage": "367",
                                    "children": [],
                                    "endPage": "370"
                                },
                                {
                                    "id": 472,
                                    "level": "4",
                                    "title": "7.2.3 Main switchboard and switchgear",
                                    "startPage": "370",
                                    "children": [],
                                    "endPage": "374"
                                },
                                {
                                    "id": 473,
                                    "level": "4",
                                    "title": "7.2.4 Main switches",
                                    "startPage": "374",
                                    "children": [],
                                    "endPage": "376"
                                },
                                {
                                    "id": 474,
                                    "level": "4",
                                    "title": "7.2.5 Fire pumps and fire pump control equipment",
                                    "startPage": "376",
                                    "children": [],
                                    "endPage": "380"
                                },
                                {
                                    "id": 475,
                                    "level": "4",
                                    "title": "7.2.6 Fire and smoke detection equipment and fire alarm systems",
                                    "startPage": "380",
                                    "children": [],
                                    "endPage": "381"
                                },
                                {
                                    "id": 476,
                                    "level": "4",
                                    "title": "7.2.7 Air-handling systems",
                                    "startPage": "381",
                                    "children": [],
                                    "endPage": "382"
                                },
                                {
                                    "id": 477,
                                    "level": "4",
                                    "title": "7.2.8 Evacuation equipment",
                                    "startPage": "382",
                                    "children": [],
                                    "endPage": "383"
                                },
                                {
                                    "id": 478,
                                    "level": "4",
                                    "title": "7.2.9 Emergency lifts",
                                    "startPage": "383",
                                    "children": [],
                                    "endPage": "385"
                                },
                                {
                                    "id": 479,
                                    "level": "4",
                                    "title": "7.2.10 Emergency motor-room-less lifts",
                                    "startPage": "385",
                                    "children": [],
                                    "endPage": "387"
                                }
                            ],
                            "endPage": "387"
                        },
                        {
                            "id": 480,
                            "level": "3",
                            "title": "7.3 ELECTRICITY GENERATION SYSTEMS",
                            "startPage": "387",
                            "children": [
                                {
                                    "id": 481,
                                    "level": "4",
                                    "title": "7.3.1 General",
                                    "startPage": "387",
                                    "children": [],
                                    "endPage": "388"
                                },
                                {
                                    "id": 482,
                                    "level": "4",
                                    "title": "7.3.2 Selection and installation of system",
                                    "startPage": "388",
                                    "children": [],
                                    "endPage": "388"
                                },
                                {
                                    "id": 483,
                                    "level": "4",
                                    "title": "7.3.3 Control",
                                    "startPage": "388",
                                    "children": [],
                                    "endPage": "389"
                                },
                                {
                                    "id": 484,
                                    "level": "4",
                                    "title": "7.3.4 Isolation",
                                    "startPage": "389",
                                    "children": [],
                                    "endPage": "390"
                                },
                                {
                                    "id": 485,
                                    "level": "4",
                                    "title": "7.3.5 Overcurrent protection",
                                    "startPage": "390",
                                    "children": [],
                                    "endPage": "391"
                                },
                                {
                                    "id": 486,
                                    "level": "4",
                                    "title": "7.3.6 Earthing",
                                    "startPage": "391",
                                    "children": [],
                                    "endPage": "391"
                                },
                                {
                                    "id": 487,
                                    "level": "4",
                                    "title": "7.3.7 Connected electrical equipment",
                                    "startPage": "391",
                                    "children": [],
                                    "endPage": "392"
                                },
                                {
                                    "id": 488,
                                    "level": "4",
                                    "title": "7.3.8 Connection to electrical installation",
                                    "startPage": "392",
                                    "children": [],
                                    "endPage": "400"
                                }
                            ],
                            "endPage": "400"
                        },
                        {
                            "id": 489,
                            "level": "3",
                            "title": "7.4 PROTECTION BY ELECTRICAL SEPARATION (ISOLATED SUPPLY)",
                            "startPage": "400",
                            "children": [
                                {
                                    "id": 490,
                                    "level": "4",
                                    "title": "7.4.1 General",
                                    "startPage": "400",
                                    "children": [],
                                    "endPage": "400"
                                },
                                {
                                    "id": 491,
                                    "level": "4",
                                    "title": "7.4.2 Source of supply",
                                    "startPage": "400",
                                    "children": [],
                                    "endPage": "401"
                                },
                                {
                                    "id": 492,
                                    "level": "4",
                                    "title": "7.4.3 Arrangement of circuits",
                                    "startPage": "401",
                                    "children": [],
                                    "endPage": "401"
                                },
                                {
                                    "id": 493,
                                    "level": "4",
                                    "title": "7.4.4 Switching devices",
                                    "startPage": "401",
                                    "children": [],
                                    "endPage": "401"
                                },
                                {
                                    "id": 494,
                                    "level": "4",
                                    "title": "7.4.5 Supply to single item of electrical equipment",
                                    "startPage": "401",
                                    "children": [],
                                    "endPage": "401"
                                },
                                {
                                    "id": 495,
                                    "level": "4",
                                    "title": "7.4.6 Supply to multiple items of electrical equipment",
                                    "startPage": "401",
                                    "children": [],
                                    "endPage": "402"
                                },
                                {
                                    "id": 496,
                                    "level": "4",
                                    "title": "7.4.7 Variable speed drive (VSD) EMI filters",
                                    "startPage": "402",
                                    "children": [],
                                    "endPage": "402"
                                },
                                {
                                    "id": 497,
                                    "level": "4",
                                    "title": "7.4.8 Testing",
                                    "startPage": "402",
                                    "children": [],
                                    "endPage": "405"
                                }
                            ],
                            "endPage": "405"
                        },
                        {
                            "id": 498,
                            "level": "3",
                            "title": "7.5 EXTRA-LOW VOLTAGE ELECTRICAL INSTALLATIONS",
                            "startPage": "405",
                            "children": [
                                {
                                    "id": 499,
                                    "level": "4",
                                    "title": "7.5.1 Scope",
                                    "startPage": "405",
                                    "children": [],
                                    "endPage": "405"
                                },
                                {
                                    "id": 500,
                                    "level": "4",
                                    "title": "7.5.2 Application",
                                    "startPage": "405",
                                    "children": [],
                                    "endPage": "405"
                                },
                                {
                                    "id": 501,
                                    "level": "4",
                                    "title": "7.5.3 Sources of supply to SELV and PELV systems",
                                    "startPage": "405",
                                    "children": [],
                                    "endPage": "406"
                                },
                                {
                                    "id": 502,
                                    "level": "4",
                                    "title": "7.5.4 Separation requirements for SELV and PELV circuits",
                                    "startPage": "406",
                                    "children": [],
                                    "endPage": "406"
                                },
                                {
                                    "id": 503,
                                    "level": "4",
                                    "title": "7.5.5 Arrangement of SELV circuits",
                                    "startPage": "406",
                                    "children": [],
                                    "endPage": "407"
                                },
                                {
                                    "id": 504,
                                    "level": "4",
                                    "title": "7.5.6 Arrangement of PELV circuits",
                                    "startPage": "407",
                                    "children": [],
                                    "endPage": "407"
                                },
                                {
                                    "id": 505,
                                    "level": "4",
                                    "title": "7.5.7 Voltage drop in conductors",
                                    "startPage": "407",
                                    "children": [],
                                    "endPage": "408"
                                },
                                {
                                    "id": 506,
                                    "level": "4",
                                    "title": "7.5.8 Control of an electrical installation",
                                    "startPage": "408",
                                    "children": [],
                                    "endPage": "408"
                                },
                                {
                                    "id": 507,
                                    "level": "4",
                                    "title": "7.5.9 Overcurrent protection",
                                    "startPage": "408",
                                    "children": [],
                                    "endPage": "409"
                                },
                                {
                                    "id": 508,
                                    "level": "4",
                                    "title": "7.5.10 Connecting devices",
                                    "startPage": "409",
                                    "children": [],
                                    "endPage": "409"
                                },
                                {
                                    "id": 509,
                                    "level": "4",
                                    "title": "7.5.11 Wiring systems",
                                    "startPage": "409",
                                    "children": [],
                                    "endPage": "409"
                                },
                                {
                                    "id": 510,
                                    "level": "4",
                                    "title": "7.5.12 Testing",
                                    "startPage": "409",
                                    "children": [],
                                    "endPage": "410"
                                }
                            ],
                            "endPage": "410"
                        },
                        {
                            "id": 511,
                            "level": "3",
                            "title": "7.6 HIGH VOLTAGE ELECTRICAL INSTALLATIONS",
                            "startPage": "410",
                            "children": [
                                {
                                    "id": 512,
                                    "level": "4",
                                    "title": "7.6.1 Scope",
                                    "startPage": "410",
                                    "children": [],
                                    "endPage": "410"
                                },
                                {
                                    "id": 513,
                                    "level": "4",
                                    "title": "7.6.2 Application",
                                    "startPage": "410",
                                    "children": [],
                                    "endPage": "411"
                                },
                                {
                                    "id": 514,
                                    "level": "4",
                                    "title": "7.6.3 Issues relevant to high voltage installations",
                                    "startPage": "411",
                                    "children": [],
                                    "endPage": "411"
                                }
                            ],
                            "endPage": "411"
                        },
                        {
                            "id": 515,
                            "level": "3",
                            "title": "7.7 HAZARDOUS AREAS (EXPLOSIVE GAS OR COMBUSTIBLE DUSTS)",
                            "startPage": "411",
                            "children": [
                                {
                                    "id": 516,
                                    "level": "4",
                                    "title": "7.7.1 Scope",
                                    "startPage": "411",
                                    "children": [],
                                    "endPage": "411"
                                },
                                {
                                    "id": 517,
                                    "level": "4",
                                    "title": "7.7.2 Classification of hazardous areas",
                                    "startPage": "411",
                                    "children": [],
                                    "endPage": "412"
                                }
                            ],
                            "endPage": "412"
                        },
                        {
                            "id": 518,
                            "level": "3",
                            "title": "7.8 STANDARDS FOR SPECIFIC ELECTRICAL INSTALLATIONS",
                            "startPage": "412",
                            "children": [
                                {
                                    "id": 519,
                                    "level": "4",
                                    "title": "7.8.1 Scope",
                                    "startPage": "412",
                                    "children": [],
                                    "endPage": "413"
                                },
                                {
                                    "id": 520,
                                    "level": "4",
                                    "title": "7.8.2 Standards containing requirements that are additional to, replace, or modify the general requirements of this Standard",
                                    "startPage": "413",
                                    "children": [],
                                    "endPage": "415"
                                },
                                {
                                    "id": 521,
                                    "level": "4",
                                    "title": "7.8.3 Standards containing guidance",
                                    "startPage": "415",
                                    "children": [],
                                    "endPage": "416"
                                }
                            ],
                            "endPage": "416"
                        },
                        {
                            "id": 522,
                            "level": "3",
                            "title": "7.9 SUPPLIES FOR ELECTRIC VEHICLES (NZ ONLY)",
                            "startPage": "416",
                            "children": [
                                {
                                    "id": 523,
                                    "level": "4",
                                    "title": "7.9.1 Scope",
                                    "startPage": "416",
                                    "children": [],
                                    "endPage": "416"
                                },
                                {
                                    "id": 524,
                                    "level": "4",
                                    "title": "7.9.2 Supply (NZ only)",
                                    "startPage": "416",
                                    "children": [],
                                    "endPage": "417"
                                },
                                {
                                    "id": 525,
                                    "level": "4",
                                    "title": "7.9.3 Domestic electrical installations (NZ only)",
                                    "startPage": "417",
                                    "children": [],
                                    "endPage": "418"
                                },
                                {
                                    "id": 526,
                                    "level": "4",
                                    "title": "7.9.4 Other electrical installations (NZ only)",
                                    "startPage": "418",
                                    "children": [],
                                    "endPage": "419"
                                }
                            ],
                            "endPage": "419"
                        }
                    ],
                    "endPage": "419"
                },
                {
                    "id": 527,
                    "level": "2",
                    "title": "SECTION 8 VERIFICATION",
                    "startPage": "419",
                    "children": [
                        {
                            "id": 528,
                            "level": "3",
                            "title": "8.1 GENERAL",
                            "startPage": "419",
                            "children": [
                                {
                                    "id": 529,
                                    "level": "4",
                                    "title": "8.1.1 Application",
                                    "startPage": "419",
                                    "children": [],
                                    "endPage": "419"
                                },
                                {
                                    "id": 530,
                                    "level": "4",
                                    "title": "8.1.2 General requirements",
                                    "startPage": "419",
                                    "children": [],
                                    "endPage": "419"
                                },
                                {
                                    "id": 531,
                                    "level": "4",
                                    "title": "8.1.3 Periodic inspection and testing",
                                    "startPage": "419",
                                    "children": [],
                                    "endPage": "420"
                                }
                            ],
                            "endPage": "420"
                        },
                        {
                            "id": 532,
                            "level": "3",
                            "title": "8.2 VISUAL INSPECTION",
                            "startPage": "420",
                            "children": [
                                {
                                    "id": 533,
                                    "level": "4",
                                    "title": "8.2.1 General",
                                    "startPage": "420",
                                    "children": [],
                                    "endPage": "420"
                                },
                                {
                                    "id": 534,
                                    "level": "4",
                                    "title": "8.2.2 Checklist",
                                    "startPage": "420",
                                    "children": [],
                                    "endPage": "422"
                                }
                            ],
                            "endPage": "422"
                        },
                        {
                            "id": 535,
                            "level": "3",
                            "title": "8.3 TESTING",
                            "startPage": "422",
                            "children": [
                                {
                                    "id": 536,
                                    "level": "4",
                                    "title": "8.3.1 General",
                                    "startPage": "422",
                                    "children": [],
                                    "endPage": "422"
                                },
                                {
                                    "id": 537,
                                    "level": "4",
                                    "title": "8.3.2 Test methods",
                                    "startPage": "422",
                                    "children": [],
                                    "endPage": "423"
                                },
                                {
                                    "id": 538,
                                    "level": "4",
                                    "title": "8.3.3 Mandatory tests",
                                    "startPage": "423",
                                    "children": [],
                                    "endPage": "423"
                                },
                                {
                                    "id": 539,
                                    "level": "4",
                                    "title": "8.3.4 Sequence of tests",
                                    "startPage": "423",
                                    "children": [],
                                    "endPage": "424"
                                },
                                {
                                    "id": 540,
                                    "level": "4",
                                    "title": "8.3.5 Continuity of the earthing system",
                                    "startPage": "424",
                                    "children": [],
                                    "endPage": "425"
                                },
                                {
                                    "id": 541,
                                    "level": "4",
                                    "title": "8.3.6 Insulation resistance",
                                    "startPage": "425",
                                    "children": [],
                                    "endPage": "426"
                                },
                                {
                                    "id": 542,
                                    "level": "4",
                                    "title": "8.3.7 Polarity and correct circuit connections",
                                    "startPage": "426",
                                    "children": [],
                                    "endPage": "427"
                                },
                                {
                                    "id": 543,
                                    "level": "4",
                                    "title": "8.3.8 'Text deleted'",
                                    "startPage": "427",
                                    "children": [],
                                    "endPage": "428"
                                },
                                {
                                    "id": 544,
                                    "level": "4",
                                    "title": "8.3.9 Verification of earth fault-loop impedance (EFLI)",
                                    "startPage": "428",
                                    "children": [],
                                    "endPage": "431"
                                },
                                {
                                    "id": 545,
                                    "level": "4",
                                    "title": "8.3.10 Operation of RCDs",
                                    "startPage": "431",
                                    "children": [],
                                    "endPage": "432"
                                }
                            ],
                            "endPage": "432"
                        },
                        {
                            "id": 546,
                            "level": "3",
                            "title": "8.4 VERIFICATION RECORDS",
                            "startPage": "432",
                            "children": [],
                            "endPage": "433"
                        }
                    ],
                    "endPage": "433"
                },
                {
                    "id": 547,
                    "level": "2",
                    "title": "APPENDIX A - REFERENCED DOCUMENTS",
                    "startPage": "433",
                    "children": [],
                    "endPage": "442"
                },
                {
                    "id": 548,
                    "level": "2",
                    "title": "APPENDIX B - CIRCUIT PROTECTION GUIDE",
                    "startPage": "442",
                    "children": [
                        {
                            "id": 549,
                            "level": "3",
                            "title": "B1 SCOPE",
                            "startPage": "442",
                            "children": [],
                            "endPage": "442"
                        },
                        {
                            "id": 550,
                            "level": "3",
                            "title": "B2 CIRCUIT ARRANGEMENTS",
                            "startPage": "442",
                            "children": [],
                            "endPage": "444"
                        },
                        {
                            "id": 551,
                            "level": "3",
                            "title": "B3 PROTECTION AGAINST OVERCURRENT",
                            "startPage": "444",
                            "children": [
                                {
                                    "id": 552,
                                    "level": "4",
                                    "title": "B3.1 General",
                                    "startPage": "444",
                                    "children": [],
                                    "endPage": "444"
                                },
                                {
                                    "id": 553,
                                    "level": "4",
                                    "title": "B3.2 Coordination between conductors and overload protective devices",
                                    "startPage": "444",
                                    "children": [],
                                    "endPage": "446"
                                }
                            ],
                            "endPage": "446"
                        },
                        {
                            "id": 554,
                            "level": "3",
                            "title": "B4 PROTECTION BY AUTOMATIC DISCONNECTION OF SUPPLY",
                            "startPage": "446",
                            "children": [
                                {
                                    "id": 555,
                                    "level": "4",
                                    "title": "B4.1 Application",
                                    "startPage": "446",
                                    "children": [],
                                    "endPage": "446"
                                },
                                {
                                    "id": 556,
                                    "level": "4",
                                    "title": "B4.2 Principle",
                                    "startPage": "446",
                                    "children": [],
                                    "endPage": "447"
                                },
                                {
                                    "id": 557,
                                    "level": "4",
                                    "title": "B4.3 Disconnection times",
                                    "startPage": "447",
                                    "children": [],
                                    "endPage": "450"
                                },
                                {
                                    "id": 558,
                                    "level": "4",
                                    "title": "B4.4 The earth fault-loop",
                                    "startPage": "450",
                                    "children": [],
                                    "endPage": "452"
                                },
                                {
                                    "id": 559,
                                    "level": "4",
                                    "title": "B4.5 Calculation of earth fault-loop impedance",
                                    "startPage": "452",
                                    "children": [],
                                    "endPage": "453"
                                },
                                {
                                    "id": 560,
                                    "level": "4",
                                    "title": "B4.6 Earth fault-loop impedance measurement",
                                    "startPage": "453",
                                    "children": [],
                                    "endPage": "453"
                                }
                            ],
                            "endPage": "453"
                        },
                        {
                            "id": 561,
                            "level": "3",
                            "title": "B5 MAXIMUM CIRCUIT LENGTHS",
                            "startPage": "453",
                            "children": [
                                {
                                    "id": 562,
                                    "level": "4",
                                    "title": "B5.1 General",
                                    "startPage": "453",
                                    "children": [],
                                    "endPage": "453"
                                },
                                {
                                    "id": 563,
                                    "level": "4",
                                    "title": "B5.2 Calculation of maximum length of circuit",
                                    "startPage": "453",
                                    "children": [],
                                    "endPage": "459"
                                }
                            ],
                            "endPage": "459"
                        }
                    ],
                    "endPage": "459"
                },
                {
                    "id": 564,
                    "level": "2",
                    "title": "APPENDIX C - CIRCUIT ARRANGEMENTS",
                    "startPage": "459",
                    "children": [
                        {
                            "id": 565,
                            "level": "3",
                            "title": "C1 SCOPE",
                            "startPage": "459",
                            "children": [],
                            "endPage": "459"
                        },
                        {
                            "id": 566,
                            "level": "3",
                            "title": "C2 MAXIMUM DEMAND",
                            "startPage": "459",
                            "children": [
                                {
                                    "id": 567,
                                    "level": "4",
                                    "title": "C2.1 After diversity maximum demand",
                                    "startPage": "459",
                                    "children": [],
                                    "endPage": "460"
                                },
                                {
                                    "id": 568,
                                    "level": "4",
                                    "title": "C2.2 Calculation of maximum demand in consumer mains and submains",
                                    "startPage": "460",
                                    "children": [],
                                    "endPage": "460"
                                },
                                {
                                    "id": 569,
                                    "level": "4",
                                    "title": "C2.3 Domestic installations",
                                    "startPage": "460",
                                    "children": [],
                                    "endPage": "469"
                                },
                                {
                                    "id": 570,
                                    "level": "4",
                                    "title": "C2.4 Non-domestic installations",
                                    "startPage": "469",
                                    "children": [],
                                    "endPage": "478"
                                },
                                {
                                    "id": 571,
                                    "level": "4",
                                    "title": "C2.5 Maximum demand in final subcircuits",
                                    "startPage": "478",
                                    "children": [],
                                    "endPage": "480"
                                }
                            ],
                            "endPage": "480"
                        },
                        {
                            "id": 572,
                            "level": "3",
                            "title": "C3 SIMPLIFIED PROTECTIVE DEVICE SELECTION",
                            "startPage": "480",
                            "children": [],
                            "endPage": "482"
                        },
                        {
                            "id": 573,
                            "level": "3",
                            "title": "C4 SIMPLIFIED VOLTAGE DROP",
                            "startPage": "482",
                            "children": [
                                {
                                    "id": 574,
                                    "level": "4",
                                    "title": "C4.1 Background",
                                    "startPage": "482",
                                    "children": [],
                                    "endPage": "484"
                                },
                                {
                                    "id": 575,
                                    "level": "4",
                                    "title": "C4.2 Examples",
                                    "startPage": "484",
                                    "children": [],
                                    "endPage": "485"
                                }
                            ],
                            "endPage": "485"
                        },
                        {
                            "id": 576,
                            "level": "3",
                            "title": "C5 NUMBER OF POINTS CONNECTED TO CIRCUITS",
                            "startPage": "485",
                            "children": [
                                {
                                    "id": 577,
                                    "level": "4",
                                    "title": "C5.1 Number of circuits",
                                    "startPage": "485",
                                    "children": [],
                                    "endPage": "485"
                                },
                                {
                                    "id": 578,
                                    "level": "4",
                                    "title": "C5.2 Final subcircuits",
                                    "startPage": "485",
                                    "children": [],
                                    "endPage": "489"
                                }
                            ],
                            "endPage": "489"
                        },
                        {
                            "id": 579,
                            "level": "3",
                            "title": "C6 GUIDE TO MAXIMUM NUMBER OF CABLES INSTALLED IN CONDUITS",
                            "startPage": "489",
                            "children": [
                                {
                                    "id": 580,
                                    "level": "4",
                                    "title": "C6.1 General",
                                    "startPage": "489",
                                    "children": [],
                                    "endPage": "489"
                                },
                                {
                                    "id": 581,
                                    "level": "4",
                                    "title": "C6.2 Basis of calculations",
                                    "startPage": "489",
                                    "children": [],
                                    "endPage": "489"
                                },
                                {
                                    "id": 582,
                                    "level": "4",
                                    "title": "C6.3 Application notes",
                                    "startPage": "489",
                                    "children": [],
                                    "endPage": "494"
                                }
                            ],
                            "endPage": "494"
                        }
                    ],
                    "endPage": "494"
                },
                {
                    "id": 583,
                    "level": "2",
                    "title": "APPENDIX D - MINIMUM SIZES OF POSTS, POLES AND STRUTS FOR AERIAL LINE CONDUCTORS",
                    "startPage": "494",
                    "children": [
                        {
                            "id": 584,
                            "level": "3",
                            "title": "D1 APPLICATION",
                            "startPage": "494",
                            "children": [
                                {
                                    "id": 585,
                                    "level": "4",
                                    "title": "D1.1 General",
                                    "startPage": "494",
                                    "children": [],
                                    "endPage": "494"
                                },
                                {
                                    "id": 586,
                                    "level": "4",
                                    "title": "D1.2 Using the tables in this Appendix",
                                    "startPage": "494",
                                    "children": [],
                                    "endPage": "495"
                                }
                            ],
                            "endPage": "495"
                        },
                        {
                            "id": 587,
                            "level": "3",
                            "title": "D2 GENERAL REQUIREMENTS FOR POSTS AND POLES",
                            "startPage": "495",
                            "children": [
                                {
                                    "id": 588,
                                    "level": "4",
                                    "title": "D2.1 Sinking of posts\/poles in ground",
                                    "startPage": "495",
                                    "children": [],
                                    "endPage": "496"
                                },
                                {
                                    "id": 589,
                                    "level": "4",
                                    "title": "D2.2 Timber posts\/poles",
                                    "startPage": "496",
                                    "children": [],
                                    "endPage": "496"
                                },
                                {
                                    "id": 590,
                                    "level": "4",
                                    "title": "D2.3 Steel-pipe posts\/poles",
                                    "startPage": "496",
                                    "children": [],
                                    "endPage": "497"
                                }
                            ],
                            "endPage": "497"
                        },
                        {
                            "id": 591,
                            "level": "3",
                            "title": "D3 ATTACHMENTS TO BUILDINGS OR STRUCTURES",
                            "startPage": "497",
                            "children": [
                                {
                                    "id": 592,
                                    "level": "4",
                                    "title": "D3.1 Responsibility for design",
                                    "startPage": "497",
                                    "children": [],
                                    "endPage": "497"
                                },
                                {
                                    "id": 593,
                                    "level": "4",
                                    "title": "D3.2 Horizontal and vertical struts",
                                    "startPage": "497",
                                    "children": [],
                                    "endPage": "498"
                                }
                            ],
                            "endPage": "498"
                        },
                        {
                            "id": 594,
                            "level": "3",
                            "title": "D4 EXAMPLE USING THE TABLES TO DETERMINE POLE\/ POST OR STRUT SIZE",
                            "startPage": "498",
                            "children": [
                                {
                                    "id": 595,
                                    "level": "4",
                                    "title": "D4.1 Problem",
                                    "startPage": "498",
                                    "children": [],
                                    "endPage": "498"
                                },
                                {
                                    "id": 596,
                                    "level": "4",
                                    "title": "D4.2 Solution",
                                    "startPage": "498",
                                    "children": [],
                                    "endPage": "516"
                                }
                            ],
                            "endPage": "516"
                        }
                    ],
                    "endPage": "516"
                },
                {
                    "id": 597,
                    "level": "2",
                    "title": "APPENDIX E - ELECTRICAL INSTALLATION REQUIREMENTS IN NATIONAL CONSTRUCTION CODES",
                    "startPage": "516",
                    "children": [
                        {
                            "id": 598,
                            "level": "3",
                            "title": "E1 SCOPE",
                            "startPage": "516",
                            "children": [],
                            "endPage": "516"
                        },
                        {
                            "id": 599,
                            "level": "3",
                            "title": "E2 AUSTRALIA",
                            "startPage": "516",
                            "children": [
                                {
                                    "id": 600,
                                    "level": "4",
                                    "title": "E2.1 General",
                                    "startPage": "516",
                                    "children": [],
                                    "endPage": "517"
                                },
                                {
                                    "id": 601,
                                    "level": "4",
                                    "title": "E2.2 Principles of classification",
                                    "startPage": "517",
                                    "children": [],
                                    "endPage": "519"
                                },
                                {
                                    "id": 602,
                                    "level": "4",
                                    "title": "E2.3 Provisions in the NCC",
                                    "startPage": "519",
                                    "children": [],
                                    "endPage": "521"
                                }
                            ],
                            "endPage": "521"
                        },
                        {
                            "id": 603,
                            "level": "3",
                            "title": "E3 NEW ZEALAND",
                            "startPage": "521",
                            "children": [
                                {
                                    "id": 604,
                                    "level": "4",
                                    "title": "E3.1 General",
                                    "startPage": "521",
                                    "children": [],
                                    "endPage": "522"
                                },
                                {
                                    "id": 605,
                                    "level": "4",
                                    "title": "E3.2 Extent of electrical installations in the NZBC",
                                    "startPage": "522",
                                    "children": [],
                                    "endPage": "523"
                                },
                                {
                                    "id": 606,
                                    "level": "4",
                                    "title": "E3.3 Building consents",
                                    "startPage": "523",
                                    "children": [],
                                    "endPage": "524"
                                }
                            ],
                            "endPage": "524"
                        }
                    ],
                    "endPage": "524"
                },
                {
                    "id": 607,
                    "level": "2",
                    "title": "APPENDIX F - SURGE PROTECTION DEVICES",
                    "startPage": "524",
                    "children": [
                        {
                            "id": 608,
                            "level": "3",
                            "title": "F1 SURGE PROTECTIVE DEVICES (SPDs)",
                            "startPage": "524",
                            "children": [
                                {
                                    "id": 609,
                                    "level": "4",
                                    "title": "F1.1 General",
                                    "startPage": "524",
                                    "children": [],
                                    "endPage": "524"
                                },
                                {
                                    "id": 610,
                                    "level": "4",
                                    "title": "F1.2 Selection and installation of SPDs",
                                    "startPage": "524",
                                    "children": [],
                                    "endPage": "528"
                                }
                            ],
                            "endPage": "528"
                        },
                        {
                            "id": 611,
                            "level": "3",
                            "title": "F2 SURGE PROTECTIVE EARTHING AND BONDING",
                            "startPage": "528",
                            "children": [],
                            "endPage": "530"
                        }
                    ],
                    "endPage": "530"
                },
                {
                    "id": 612,
                    "level": "2",
                    "title": "APPENDIX G - DEGREES OF PROTECTION OF ENCLOSED EQUIPMENT",
                    "startPage": "530",
                    "children": [],
                    "endPage": "535"
                },
                {
                    "id": 613,
                    "level": "2",
                    "title": "APPENDIX H - WS CLASSIFICATION OF WIRING SYSTEMS",
                    "startPage": "535",
                    "children": [
                        {
                            "id": 614,
                            "level": "3",
                            "title": "H1 GENERAL",
                            "startPage": "535",
                            "children": [
                                {
                                    "id": 615,
                                    "level": "4",
                                    "title": "H1.1 Classification",
                                    "startPage": "535",
                                    "children": [],
                                    "endPage": "535"
                                },
                                {
                                    "id": 616,
                                    "level": "4",
                                    "title": "H1.2 Designation",
                                    "startPage": "535",
                                    "children": [],
                                    "endPage": "536"
                                },
                                {
                                    "id": 617,
                                    "level": "4",
                                    "title": "H1.3 First characteristic numeral",
                                    "startPage": "536",
                                    "children": [],
                                    "endPage": "536"
                                },
                                {
                                    "id": 618,
                                    "level": "4",
                                    "title": "H1.4 Second characteristic numeral",
                                    "startPage": "536",
                                    "children": [],
                                    "endPage": "537"
                                },
                                {
                                    "id": 619,
                                    "level": "4",
                                    "title": "H1.5 Supplementary letter W",
                                    "startPage": "537",
                                    "children": [],
                                    "endPage": "537"
                                },
                                {
                                    "id": 620,
                                    "level": "4",
                                    "title": "H1.6 Supply and installation",
                                    "startPage": "537",
                                    "children": [],
                                    "endPage": "537"
                                }
                            ],
                            "endPage": "537"
                        },
                        {
                            "id": 621,
                            "level": "3",
                            "title": "H2 PARTICULAR WIRING SYSTEM APPLICATIONS",
                            "startPage": "537",
                            "children": [
                                {
                                    "id": 622,
                                    "level": "4",
                                    "title": "H2.1 Building Code",
                                    "startPage": "537",
                                    "children": [],
                                    "endPage": "538"
                                },
                                {
                                    "id": 623,
                                    "level": "4",
                                    "title": "H2.2 Equipment installation Standards",
                                    "startPage": "538",
                                    "children": [],
                                    "endPage": "539"
                                }
                            ],
                            "endPage": "539"
                        },
                        {
                            "id": 624,
                            "level": "3",
                            "title": "H3 FIRE-PROTECTED WIRING SYSTEMS",
                            "startPage": "539",
                            "children": [],
                            "endPage": "539"
                        },
                        {
                            "id": 625,
                            "level": "3",
                            "title": "H4 MECHANICALLY PROTECTED WIRING SYSTEMS",
                            "startPage": "539",
                            "children": [
                                {
                                    "id": 626,
                                    "level": "4",
                                    "title": "H4.1 General",
                                    "startPage": "539",
                                    "children": [],
                                    "endPage": "539"
                                },
                                {
                                    "id": 627,
                                    "level": "4",
                                    "title": "H4.2 Application of wiring systems",
                                    "startPage": "539",
                                    "children": [],
                                    "endPage": "540"
                                },
                                {
                                    "id": 628,
                                    "level": "4",
                                    "title": "H4.3 Unenclosed wiring systems",
                                    "startPage": "540",
                                    "children": [],
                                    "endPage": "540"
                                },
                                {
                                    "id": 629,
                                    "level": "4",
                                    "title": "H4.4 Enclosed wiring systems",
                                    "startPage": "540",
                                    "children": [],
                                    "endPage": "541"
                                }
                            ],
                            "endPage": "541"
                        },
                        {
                            "id": 630,
                            "level": "3",
                            "title": "H5 GUIDE TO MECHANICAL PROTECTION CLASSIFICATION",
                            "startPage": "541",
                            "children": [
                                {
                                    "id": 631,
                                    "level": "4",
                                    "title": "H5.1 General",
                                    "startPage": "541",
                                    "children": [],
                                    "endPage": "541"
                                },
                                {
                                    "id": 632,
                                    "level": "4",
                                    "title": "H5.2 WSX1 mechanical protection systems",
                                    "startPage": "541",
                                    "children": [],
                                    "endPage": "541"
                                },
                                {
                                    "id": 633,
                                    "level": "4",
                                    "title": "H5.3 WSX2 mechanical protection systems",
                                    "startPage": "541",
                                    "children": [],
                                    "endPage": "542"
                                },
                                {
                                    "id": 634,
                                    "level": "4",
                                    "title": "H5.4 WSX3 mechanical protection systems",
                                    "startPage": "542",
                                    "children": [],
                                    "endPage": "543"
                                }
                            ],
                            "endPage": "543"
                        }
                    ],
                    "endPage": "543"
                },
                {
                    "id": 635,
                    "level": "2",
                    "title": "APPENDIX I - PROTECTIVE DEVICE RATINGS AND METRIC EQUIVALENT SIZES FOR IMPERIAL CABLES USED IN ALTERATIONS AND REPAIRS",
                    "startPage": "543",
                    "children": [
                        {
                            "id": 636,
                            "level": "3",
                            "title": "I1 SCOPE",
                            "startPage": "543",
                            "children": [],
                            "endPage": "543"
                        },
                        {
                            "id": 637,
                            "level": "3",
                            "title": "I2 PROTECTIVE DEVICE RATINGS",
                            "startPage": "543",
                            "children": [],
                            "endPage": "545"
                        },
                        {
                            "id": 638,
                            "level": "3",
                            "title": "I3 METRIC EQUIVALENT SIZES FOR IMPERIAL CONDUCTORS",
                            "startPage": "545",
                            "children": [],
                            "endPage": "546"
                        }
                    ],
                    "endPage": "546"
                },
                {
                    "id": 639,
                    "level": "2",
                    "title": "APPENDIX J - SYMBOLS USED IN THIS STANDARD",
                    "startPage": "546",
                    "children": [],
                    "endPage": "548"
                },
                {
                    "id": 640,
                    "level": "2",
                    "title": "APPENDIX K - SWITCHBOARD REQUIREMENT SUMMARY",
                    "startPage": "548",
                    "children": [
                        {
                            "id": 641,
                            "level": "3",
                            "title": "K1 GENERAL",
                            "startPage": "548",
                            "children": [
                                {
                                    "id": 642,
                                    "level": "4",
                                    "title": "K1.1 Introduction",
                                    "startPage": "548",
                                    "children": [],
                                    "endPage": "548"
                                },
                                {
                                    "id": 643,
                                    "level": "4",
                                    "title": "K1.2 Deemed to comply",
                                    "startPage": "548",
                                    "children": [],
                                    "endPage": "548"
                                }
                            ],
                            "endPage": "548"
                        },
                        {
                            "id": 644,
                            "level": "3",
                            "title": "K2 SWITCHBOARD STANDARDS",
                            "startPage": "548",
                            "children": [
                                {
                                    "id": 645,
                                    "level": "4",
                                    "title": "K2.1 General",
                                    "startPage": "548",
                                    "children": [],
                                    "endPage": "549"
                                },
                                {
                                    "id": 646,
                                    "level": "4",
                                    "title": "K2.2 Withdrawal date of the AS\/NZS 3439 series",
                                    "startPage": "549",
                                    "children": [],
                                    "endPage": "549"
                                },
                                {
                                    "id": 647,
                                    "level": "4",
                                    "title": "K2.3 Testing",
                                    "startPage": "549",
                                    "children": [],
                                    "endPage": "549"
                                }
                            ],
                            "endPage": "549"
                        },
                        {
                            "id": 648,
                            "level": "3",
                            "title": "K3 APPLICATION OF THE AS\/NZS 61439 SERIES",
                            "startPage": "549",
                            "children": [
                                {
                                    "id": 649,
                                    "level": "4",
                                    "title": "K3.1 General rules",
                                    "startPage": "549",
                                    "children": [],
                                    "endPage": "549"
                                },
                                {
                                    "id": 650,
                                    "level": "4",
                                    "title": "K3.2 Power switchgear and controlgear (PSC)",
                                    "startPage": "549",
                                    "children": [],
                                    "endPage": "549"
                                },
                                {
                                    "id": 651,
                                    "level": "4",
                                    "title": "K3.3 Distribution boards intended to be operated by ordinary persons (DBO)",
                                    "startPage": "549",
                                    "children": [],
                                    "endPage": "549"
                                }
                            ],
                            "endPage": "549"
                        },
                        {
                            "id": 652,
                            "level": "3",
                            "title": "K4 GUIDELINES FOR THE ASSESSMENT OF SWITCHBOARDS",
                            "startPage": "549",
                            "children": [
                                {
                                    "id": 653,
                                    "level": "4",
                                    "title": "K4.1 User template",
                                    "startPage": "549",
                                    "children": [],
                                    "endPage": "549"
                                },
                                {
                                    "id": 654,
                                    "level": "4",
                                    "title": "K4.2 Items subject to agreement",
                                    "startPage": "549",
                                    "children": [],
                                    "endPage": "550"
                                },
                                {
                                    "id": 655,
                                    "level": "4",
                                    "title": "K4.3 Design verification",
                                    "startPage": "550",
                                    "children": [],
                                    "endPage": "550"
                                }
                            ],
                            "endPage": "550"
                        },
                        {
                            "id": 656,
                            "level": "3",
                            "title": "K5 CORRESPONDING PROVISIONS OF THIS STANDARD AND THE AS\/NZS 61439 SERIES",
                            "startPage": "550",
                            "children": [],
                            "endPage": "551"
                        },
                        {
                            "id": 657,
                            "level": "3",
                            "title": "K6 PARTICULAR CLAUSES RELEVANT TO THIS STANDARD",
                            "startPage": "551",
                            "children": [
                                {
                                    "id": 658,
                                    "level": "4",
                                    "title": "K6.1 Degree of protection of ASSEMBLIES",
                                    "startPage": "551",
                                    "children": [],
                                    "endPage": "551"
                                },
                                {
                                    "id": 659,
                                    "level": "4",
                                    "title": "K6.2 Form of internal separation",
                                    "startPage": "551",
                                    "children": [],
                                    "endPage": "554"
                                },
                                {
                                    "id": 660,
                                    "level": "4",
                                    "title": "K6.3 Clearance",
                                    "startPage": "554",
                                    "children": [],
                                    "endPage": "554"
                                },
                                {
                                    "id": 661,
                                    "level": "4",
                                    "title": "K6.4 Overvoltage category",
                                    "startPage": "554",
                                    "children": [],
                                    "endPage": "554"
                                },
                                {
                                    "id": 662,
                                    "level": "4",
                                    "title": "K6.5 Creepage",
                                    "startPage": "554",
                                    "children": [],
                                    "endPage": "554"
                                },
                                {
                                    "id": 663,
                                    "level": "4",
                                    "title": "K6.6 Earthing (protective earth circuit)",
                                    "startPage": "554",
                                    "children": [],
                                    "endPage": "554"
                                },
                                {
                                    "id": 664,
                                    "level": "4",
                                    "title": "K6.7 Dielectric tests for protection against electric shock",
                                    "startPage": "554",
                                    "children": [],
                                    "endPage": "554"
                                },
                                {
                                    "id": 665,
                                    "level": "4",
                                    "title": "K6.8 Current rating by testing and temperature limits",
                                    "startPage": "554",
                                    "children": [],
                                    "endPage": "556"
                                },
                                {
                                    "id": 666,
                                    "level": "4",
                                    "title": "K6.9 Derivation of ratings for similar variants to AS\/NZS 61439.1",
                                    "startPage": "556",
                                    "children": [],
                                    "endPage": "556"
                                },
                                {
                                    "id": 667,
                                    "level": "4",
                                    "title": "K6.10 Verification assessment to AS\/NZS 61439.1",
                                    "startPage": "556",
                                    "children": [],
                                    "endPage": "556"
                                }
                            ],
                            "endPage": "556"
                        },
                        {
                            "id": 668,
                            "level": "3",
                            "title": "K7 SHORT-CIRCUIT WITHSTAND STRENGTH",
                            "startPage": "556",
                            "children": [
                                {
                                    "id": 669,
                                    "level": "4",
                                    "title": "K7.1 General",
                                    "startPage": "556",
                                    "children": [],
                                    "endPage": "556"
                                },
                                {
                                    "id": 670,
                                    "level": "4",
                                    "title": "K7.2 Ratings",
                                    "startPage": "556",
                                    "children": [],
                                    "endPage": "557"
                                },
                                {
                                    "id": 671,
                                    "level": "4",
                                    "title": "K7.3 Short-circuit withstand strength by comparison to a reference design utilizing a check list",
                                    "startPage": "557",
                                    "children": [],
                                    "endPage": "557"
                                }
                            ],
                            "endPage": "557"
                        },
                        {
                            "id": 672,
                            "level": "3",
                            "title": "K8 MARKING",
                            "startPage": "557",
                            "children": [
                                {
                                    "id": 673,
                                    "level": "4",
                                    "title": "K8.1 PSC and DBO switchboards",
                                    "startPage": "557",
                                    "children": [],
                                    "endPage": "557"
                                },
                                {
                                    "id": 674,
                                    "level": "4",
                                    "title": "K8.2 Additional marking for DBO",
                                    "startPage": "557",
                                    "children": [],
                                    "endPage": "558"
                                }
                            ],
                            "endPage": "558"
                        },
                        {
                            "id": 675,
                            "level": "3",
                            "title": "K9 OTHER RELEVANT CHARACTERISTICS TO BE DECLARED BY THE ASSEMBLY MANUFACTURER",
                            "startPage": "558",
                            "children": [],
                            "endPage": "558"
                        },
                        {
                            "id": 676,
                            "level": "3",
                            "title": "K10 DEVICE SUBSTITUTION",
                            "startPage": "558",
                            "children": [
                                {
                                    "id": 677,
                                    "level": "4",
                                    "title": "K10.1 General",
                                    "startPage": "558",
                                    "children": [],
                                    "endPage": "559"
                                },
                                {
                                    "id": 678,
                                    "level": "4",
                                    "title": "K10.2 Device substitution questions",
                                    "startPage": "559",
                                    "children": [],
                                    "endPage": "559"
                                }
                            ],
                            "endPage": "559"
                        },
                        {
                            "id": 679,
                            "level": "3",
                            "title": "K11 REPAIRS TO EXISTING SWITCHBOARDS",
                            "startPage": "559",
                            "children": [],
                            "endPage": "560"
                        },
                        {
                            "id": 680,
                            "level": "3",
                            "title": "K12 ROUTINE VERIFICATION BY THE SWITCHBOARD MANUFACTURER",
                            "startPage": "560",
                            "children": [],
                            "endPage": "561"
                        }
                    ],
                    "endPage": "561"
                },
                {
                    "id": 681,
                    "level": "2",
                    "title": "APPENDIX L - (Deleted)",
                    "startPage": "561",
                    "children": [],
                    "endPage": "562"
                },
                {
                    "id": 682,
                    "level": "2",
                    "title": "APPENDIX M - REDUCING THE IMPACT OF POWER SUPPLY OUTAGES-CONTINUITY OF SUPPLY FOR ACTIVE ASSISTED LIVING AND HOMECARE...",
                    "startPage": "562",
                    "children": [
                        {
                            "id": 683,
                            "level": "3",
                            "title": "M1 GENERAL",
                            "startPage": "562",
                            "children": [
                                {
                                    "id": 684,
                                    "level": "4",
                                    "title": "M1.1 Scope",
                                    "startPage": "562",
                                    "children": [],
                                    "endPage": "562"
                                },
                                {
                                    "id": 685,
                                    "level": "4",
                                    "title": "M1.2 Active assisted living",
                                    "startPage": "562",
                                    "children": [],
                                    "endPage": "562"
                                },
                                {
                                    "id": 686,
                                    "level": "4",
                                    "title": "M1.3 Homecare medical",
                                    "startPage": "562",
                                    "children": [],
                                    "endPage": "562"
                                }
                            ],
                            "endPage": "562"
                        },
                        {
                            "id": 687,
                            "level": "3",
                            "title": "M2 ARRANGEMENTS",
                            "startPage": "562",
                            "children": [],
                            "endPage": "564"
                        }
                    ],
                    "endPage": "564"
                },
                {
                    "id": 688,
                    "level": "2",
                    "title": "APPENDIX N - ELECTRICAL CONDUITS",
                    "startPage": "564",
                    "children": [
                        {
                            "id": 689,
                            "level": "3",
                            "title": "N1 GENERAL",
                            "startPage": "564",
                            "children": [],
                            "endPage": "564"
                        },
                        {
                            "id": 690,
                            "level": "3",
                            "title": "N2 MARKING OF AS\/NZS 2053 SERIES AND AS\/NZS 61386 SERIES CONDUITS",
                            "startPage": "564",
                            "children": [],
                            "endPage": "565"
                        },
                        {
                            "id": 691,
                            "level": "3",
                            "title": "N3 CLASSIFICATIONS",
                            "startPage": "565",
                            "children": [],
                            "endPage": "568"
                        }
                    ],
                    "endPage": "568"
                },
                {
                    "id": 692,
                    "level": "2",
                    "title": "APPENDIX O - INSTALLATION OF ARC FAULT DETECTION DEVICES (AFDDs)",
                    "startPage": "568",
                    "children": [
                        {
                            "id": 693,
                            "level": "3",
                            "title": "O1 GENERAL",
                            "startPage": "568",
                            "children": [],
                            "endPage": "569"
                        },
                        {
                            "id": 694,
                            "level": "3",
                            "title": "O2 CHARACTERISTICS OF AFDDs",
                            "startPage": "569",
                            "children": [],
                            "endPage": "570"
                        },
                        {
                            "id": 695,
                            "level": "3",
                            "title": "O3 GENERAL REQUIREMENTS FOR AFDDs",
                            "startPage": "570",
                            "children": [],
                            "endPage": "570"
                        },
                        {
                            "id": 696,
                            "level": "3",
                            "title": "O4 LOCATION, SELECTION AND INSTALLATION OF AFDDs",
                            "startPage": "570",
                            "children": [
                                {
                                    "id": 697,
                                    "level": "4",
                                    "title": "O4.1 Location",
                                    "startPage": "570",
                                    "children": [],
                                    "endPage": "570"
                                },
                                {
                                    "id": 698,
                                    "level": "4",
                                    "title": "O4.2 Selection of AFDDs",
                                    "startPage": "570",
                                    "children": [],
                                    "endPage": "570"
                                },
                                {
                                    "id": 699,
                                    "level": "4",
                                    "title": "O4.3 Installation",
                                    "startPage": "570",
                                    "children": [],
                                    "endPage": "570"
                                },
                                {
                                    "id": 700,
                                    "level": "4",
                                    "title": "O4.4 Overvoltage protection",
                                    "startPage": "570",
                                    "children": [],
                                    "endPage": "571"
                                }
                            ],
                            "endPage": "571"
                        }
                    ],
                    "endPage": "571"
                },
                {
                    "id": 701,
                    "level": "2",
                    "title": "APPENDIX P - GUIDANCE FOR INSTALLATION AND LOCATION OF ELECTRICAL VEHICLE SOCKET-OUTLETS AND CHARGING STATIONS",
                    "startPage": "571",
                    "children": [
                        {
                            "id": 702,
                            "level": "3",
                            "title": "P1 SCOPE AND GENERAL",
                            "startPage": "571",
                            "children": [
                                {
                                    "id": 703,
                                    "level": "4",
                                    "title": "P1.1 Scope",
                                    "startPage": "571",
                                    "children": [],
                                    "endPage": "571"
                                },
                                {
                                    "id": 704,
                                    "level": "4",
                                    "title": "P1.2 Applications",
                                    "startPage": "571",
                                    "children": [],
                                    "endPage": "571"
                                },
                                {
                                    "id": 705,
                                    "level": "4",
                                    "title": "P1.3 Additional requirements and tests",
                                    "startPage": "571",
                                    "children": [],
                                    "endPage": "572"
                                },
                                {
                                    "id": 706,
                                    "level": "4",
                                    "title": "P1.4 Exclusions",
                                    "startPage": "572",
                                    "children": [],
                                    "endPage": "572"
                                },
                                {
                                    "id": 707,
                                    "level": "4",
                                    "title": "P1.5 Definitions",
                                    "startPage": "572",
                                    "children": [],
                                    "endPage": "573"
                                }
                            ],
                            "endPage": "573"
                        },
                        {
                            "id": 708,
                            "level": "3",
                            "title": "P2 MAXIMUM DEMAND",
                            "startPage": "573",
                            "children": [],
                            "endPage": "573"
                        },
                        {
                            "id": 709,
                            "level": "3",
                            "title": "P3 INSTALLATION",
                            "startPage": "573",
                            "children": [
                                {
                                    "id": 710,
                                    "level": "4",
                                    "title": "P3.1 General",
                                    "startPage": "573",
                                    "children": [],
                                    "endPage": "573"
                                },
                                {
                                    "id": 711,
                                    "level": "4",
                                    "title": "P3.2 External Influences",
                                    "startPage": "573",
                                    "children": [],
                                    "endPage": "574"
                                }
                            ],
                            "endPage": "574"
                        },
                        {
                            "id": 712,
                            "level": "3",
                            "title": "P4 DEVICES FOR PROTECTION AGAINST INDIRECT CONTACT BY AUTOMATIC DISCONNECTION OF SUPPLY",
                            "startPage": "574",
                            "children": [
                                {
                                    "id": 713,
                                    "level": "4",
                                    "title": "P4.1 RCDs",
                                    "startPage": "574",
                                    "children": [],
                                    "endPage": "574"
                                },
                                {
                                    "id": 714,
                                    "level": "4",
                                    "title": "P4.2 Devices for protection against overcurrent",
                                    "startPage": "574",
                                    "children": [],
                                    "endPage": "574"
                                },
                                {
                                    "id": 715,
                                    "level": "4",
                                    "title": "P4.3 Protective conductors",
                                    "startPage": "574",
                                    "children": [],
                                    "endPage": "574"
                                }
                            ],
                            "endPage": "574"
                        },
                        {
                            "id": 716,
                            "level": "3",
                            "title": "P5 OTHER EQUIPMENT",
                            "startPage": "574",
                            "children": [
                                {
                                    "id": 717,
                                    "level": "4",
                                    "title": "P5.1 Socket-outlets and vehicle connectors",
                                    "startPage": "574",
                                    "children": [],
                                    "endPage": "575"
                                },
                                {
                                    "id": 718,
                                    "level": "4",
                                    "title": "P5.2 Ratings of vehicle couplers, socket-outlets and plugs",
                                    "startPage": "575",
                                    "children": [],
                                    "endPage": "575"
                                },
                                {
                                    "id": 719,
                                    "level": "4",
                                    "title": "P5.3 Permitted socket-outlets or vehicle connectors",
                                    "startPage": "575",
                                    "children": [],
                                    "endPage": "575"
                                },
                                {
                                    "id": 720,
                                    "level": "4",
                                    "title": "P5.4 Types of connection",
                                    "startPage": "575",
                                    "children": [],
                                    "endPage": "577"
                                }
                            ],
                            "endPage": "577"
                        },
                        {
                            "id": 721,
                            "level": "3",
                            "title": "P6 CHARGING STATIONS AND CABLES",
                            "startPage": "577",
                            "children": [],
                            "endPage": "577"
                        },
                        {
                            "id": 722,
                            "level": "3",
                            "title": "P7 PERIODIC VERIFICATION",
                            "startPage": "577",
                            "children": [],
                            "endPage": "578"
                        }
                    ],
                    "endPage": "578"
                },
                {
                    "id": 723,
                    "level": "2",
                    "title": "APPENDIX Q - D.C. CIRCUIT PROTECTION APPLICATION GUIDE",
                    "startPage": "578",
                    "children": [
                        {
                            "id": 724,
                            "level": "3",
                            "title": "Q1 SCOPE",
                            "startPage": "578",
                            "children": [],
                            "endPage": "578"
                        },
                        {
                            "id": 725,
                            "level": "3",
                            "title": "Q2 GENERAL",
                            "startPage": "578",
                            "children": [],
                            "endPage": "578"
                        },
                        {
                            "id": 726,
                            "level": "3",
                            "title": "Q3 ARC SUPPRESSION",
                            "startPage": "578",
                            "children": [],
                            "endPage": "578"
                        },
                        {
                            "id": 727,
                            "level": "3",
                            "title": "Q4 SWITCHGEAR TYPES",
                            "startPage": "578",
                            "children": [
                                {
                                    "id": 728,
                                    "level": "4",
                                    "title": "Q4.1 Polarized",
                                    "startPage": "578",
                                    "children": [],
                                    "endPage": "579"
                                },
                                {
                                    "id": 729,
                                    "level": "4",
                                    "title": "Q4.2 Non-polarized type",
                                    "startPage": "579",
                                    "children": [],
                                    "endPage": "579"
                                }
                            ],
                            "endPage": "579"
                        },
                        {
                            "id": 730,
                            "level": "3",
                            "title": "Q5 D.C. RATINGS",
                            "startPage": "579",
                            "children": [
                                {
                                    "id": 731,
                                    "level": "4",
                                    "title": "Q5.1 General",
                                    "startPage": "579",
                                    "children": [],
                                    "endPage": "579"
                                },
                                {
                                    "id": 732,
                                    "level": "4",
                                    "title": "Q5.2 Earthed D.C. supply",
                                    "startPage": "579",
                                    "children": [],
                                    "endPage": "579"
                                }
                            ],
                            "endPage": "579"
                        },
                        {
                            "id": 733,
                            "level": "3",
                            "title": "Q6 PROVISION OF ISOLATION AND OVERCURRENT PROTECTION",
                            "startPage": "579",
                            "children": [],
                            "endPage": "579"
                        },
                        {
                            "id": 734,
                            "level": "3",
                            "title": "Q7 SWITCHBOARD LOCATIONS",
                            "startPage": "579",
                            "children": [],
                            "endPage": "580"
                        },
                        {
                            "id": 735,
                            "level": "3",
                            "title": "Q8 FINAL SUBCIRCUIT WIRING AND FITTINGS",
                            "startPage": "580",
                            "children": [],
                            "endPage": "580"
                        },
                        {
                            "id": 736,
                            "level": "3",
                            "title": "Q9 INVERTERS",
                            "startPage": "580",
                            "children": [],
                            "endPage": "581"
                        }
                    ],
                    "endPage": "581"
                },
                {
                    "id": 737,
                    "level": "2",
                    "title": "INDEX",
                    "startPage": "581",
                    "children": [],
                    "endPage": "612"
                }
            ],
            "endPage": "612"
        }
    ]
};
export default () => ({
    toc1: toc1,
    toc2: toc2,
    promptOptions: [],
    selectedPromptId: 0,
    diffSummary: false,
    diffSummaryId: 0,
    selectedRating: -1,
    updateRatingInProgress: false,
    itemId1: '',
    itemId2: '',
    itemName1: '',
    itemName2: '',
    language: '',
    docName: '',
    docId: '',
    loading: false,
    compareVersionsError: false,
    showCompareWindow: false,
    toc1Selection: {},
    toc2Selection: {},
    abortController: {},
    processAborted: false,
    queryLimitExceeded: false,
    activeTab: '',
    tabPage: '',
    promptOptionsLoading: false,
    quotaUsed: 0,
    quotaAvailable: 0,
    prevActiveTabs: []
});

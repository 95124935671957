export default {
    getItem: state => field => {
        const keys =  field.split(".");
        let currentLevel = state;
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            
            // If the key doesn't exist at the current level, create an empty object
            if (!Object.prototype.hasOwnProperty.call(currentLevel, key)) {
                return '';
            }
            
            // If it's the last key, set the value
            if (i === keys.length - 1) {
                return currentLevel[key];
            }
            
            // Move the current level deeper into the nested structure
            currentLevel = currentLevel[key];
        }
        return '';
    }
}
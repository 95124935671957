import axios from "@/plugins/axios";

export const apiSendPromptToAI = async (params) => {
    try {
        let options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
        let apiParams = new URLSearchParams();
        Object.keys(params).forEach(key => {
            if (params[key] !== undefined && params[key] !== null && params[key] !== '') {
                apiParams.append(key, params[key]);
            }
        });
        const apiEndPoint = 'query';
        const response = await axios.post({
            endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
            params: apiParams,
            options: options

        });
        if (response.data) {
            if (response.data.status === 'ok') {
                let aiResponse = response.data;
                return aiResponse.data;
            } else {
                console.log('There has been a problem with your fetch operation.');
            }
        }
    } catch (e) {
        console.log('There has been a problem with your fetch operation:' + e.message);
    }
    return false;
}


export const apiLoadChatHistory = async (params) => {
    try {
        let options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
        let apiParams = new URLSearchParams();

        Object.keys(params).forEach(key => {
            if (params[key] !== undefined && params[key] !== null && params[key] !== '') {
                apiParams.append(key, params[key]);
            }
        });
        const apiEndPoint = 'getChatHistory';
        const response = await axios.post({
            endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
            params: apiParams,
            options: options
        });
        
        if (response.data) {
            if (response.data.status === 'ok') {
                let aiResponse = response.data;
                return aiResponse.data;
            } else {
                console.log('There has been a problem with your fetch operation.');
            }
        }
    } catch (e) {
        console.log('There has been a problem with your fetch operation:' + e.message);
    }
    return [];
}

export const apiDeleteChatHistory = async (chatId) => {
    try {
        let options = {};
        let params = {};
        options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
        params = new URLSearchParams();
        params.append('chatId', chatId);
        const apiEndPoint = 'deleteChatHistory';
        const response = await axios.post({
            endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
            params: params,
            options: options

        });
        
        if (response.data) {
            if (response.data.status === 'ok') {
                // return  response.data.data;
                return true;
            } else {
                console.log('There has been a problem with your delete operation.');
            }
        }
    } catch (e) {
        console.log('There has been a problem with your delete operation:' + e.message);
    }
    return false;
}

export const apiGenerateChatTitle = async (chatId) => {
    try {
        let options = {};
        let params = {};
        options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
        params = new URLSearchParams();
        params.append('chatId', chatId);
        const apiEndPoint = 'generateChatTitle';
        const response = await axios.post({
            endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
            params: params,
            options: options
        });
        if (response.data) {
            if (response.data.status === 'ok') {
                let aiResponse = response.data;
                return aiResponse.data;
            } else {
                console.log('There has been a problem with your delete operation.');
            }
        }
    } catch (e) {
        console.log('There has been a problem with your delete operation:' + e.message);
    }
    return false;
}

export const apiRateChatMessage = async (chatId, messageId, rating) => {
    try {
        let options = {};
        let params = {};
        options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
        params = new URLSearchParams();
        params.append('chatId', chatId);
        params.append('messageId', messageId);
        params.append('rating', rating);
        const apiEndPoint = 'rateChatMessage';
        const response = await axios.post({
            endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
            params: params,
            options: options

        });
        
        if (response.data) {
            if (response.data.status === 'ok') {

                return true;
            } else {
                console.log('There has been a problem with your rating chat message operation.');
            }
        }
    } catch (e) {
        console.log('There has been a problem with your rating chat message operation:' + e.message);
    }
    return false;
}

export const apiLoadChatMessages = async (chatId, offset, limit) => { 
    try {
        let options = {};
        let params = {};
        options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
        params = new URLSearchParams();
        params.append('chatId', chatId);
        params.append('offset', offset);
        params.append('limit', limit);
        const apiEndPoint = 'loadChatMessages';
        const response = await axios.post({
            endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
            params: params,
            options: options

        });
        if (response.data) {
            if (response.data.status === 'ok') {
                let aiResponse = response.data;
                return aiResponse.data;
            } else {
                console.log('There has been a problem with your load more messages operation.');
            }
        }
    } catch (e) {
        console.log('There has been a problem with your load more messages operation:' + e.message);
    }
    return [];
}

export const apiUpdateChatTitle = async (chatId, title) => {
    try {
        let options = {};
        let params = {};
        options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
        params = new URLSearchParams();
        params.append('chatId', chatId);
        params.append('chatTitle', title);
        const apiEndPoint = 'updateChat';
        const response = await axios.post({
            endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
            params: params,
            options: options

        });
        
        if (response.data) {
            if (response.data.status === 'ok') {
                // return  response.data.data;
                return true;
            } else {
                console.log('There has been a problem with your delete operation.');
            }
        }
    } catch (e) {
        console.log('There has been a problem with your delete operation:' + e.message);
    }
    return false;
}

export const apiGetChatQueryCount = async () => {
    try {
        let options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
        const apiEndPoint = 'getChatQueryCountBySubId';
        const response = await axios.post({
            endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
            options: options
        });
        
        if (response.data) {
            if (response.data.status === 'ok') {
                let aiResponse = response.data;
                return aiResponse.data;
            } else {
                console.log('There has been a problem with your fetch operation.');
            }
        }
    } catch (e) {
        console.log('There has been a problem with your fetch operation:' + e.message);
    }
    return [];
}

export const apiGetAllFaqForDoc = async (docId) => {
    try {
        let options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
        let apiParams = new URLSearchParams();
        apiParams.append('docId', docId);
        const apiEndPoint = 'getAllFaqForDoc';
        const response = await axios.post({
            endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
            params: apiParams,
            options: options
        });
        
        if (response.data) {
            if (response.data.status === 'ok') {
                let aiResponse = response.data;
                return aiResponse.data;
            } else {
                console.log('There has been a problem with your fetch operation.');
            }
        }
    } catch (e) {
        console.log('There has been a problem with your fetch operation:' + e.message);
    }
    return false;
}


export const apiSearchChatHistory = async (searchTerm, searchHistoryAbortController) => {
    try {
        let options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}, signal: searchHistoryAbortController.signal};
        let apiParams = new URLSearchParams();
        apiParams.append('searchTerm', searchTerm);
        const apiEndPoint = 'searchChatHistory';
        const response = await axios.post({
            endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
            params: apiParams,
            options: options
        });
        
        if (response.data) {
            if (response.data.status === 'ok') {
                let aiResponse = response.data;
                return { data: aiResponse.data, canceled: false };
            } else {
                console.log('There has been a problem with your fetch operation.');
            }
        }
    } catch (e) {
        if (e.message === 'canceled') {
            console.log('Request was canceled:', e.message);
            return { data: [], canceled: true };
               
        }
        console.log('There has been a problem with your fetch operation:' + e.message);
    }
    return { data: [], canceled: false };
}